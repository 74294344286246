export default function getValidWhatsPhoneNumber(phone: string) {
  if (!phone) {
    return '';
  }

  if (phone.length < 10) {
    return phone;
  }

  if (phone.length <= 11) {
    return `55${phone}`;
  }

  return phone;
}
