export interface IUserToken {
  id: number;
  email: string;
  name: string;
  exp: number;
  roles: string[];
  isBetaUser: boolean;
  isClubeBlack: boolean;
  belt: IEliteBelt;
  photo?: string;
  isSupport?: boolean;
  supportId?: number;
  hash: string;
}

export interface IEliteBelt {
  id: number;
  name: Belt;
}

export enum Belt {
  orange = 'Orange Belt',
  red = 'Red Belt',
  black = 'Black Belt',
  green = 'Green Belt',
  white = 'White Belt',
  golden = 'Golden Belt'
}

export enum enRoleAction {
  filterByProducer = 'telescope:dashboard-checkout-sun:filter-by-producer',
  viewLoadTime = 'telescope:dashboard-checkout-sun:view-load-time',
  viewUptime = 'telescope:dashboard-checkout-sun:view-uptime'
}

export const ELITE_BELTS_ID = [100, 101, 102, 103, 104, 109, 110, 111, 112, 113, 114, 115, 116];
