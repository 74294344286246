/* eslint-disable react/jsx-no-bind */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React, { memo } from 'react';

interface ICardSimple {
  id?: string;
  className?: string;
  elevation?: number;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

const CardSimple: React.FC<ICardSimple> = memo(({ children, elevation = 2, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper elevation={elevation} className={classes.root} {...rest}>
      {children}
    </Paper>
  );
});

export default CardSimple;
