import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '@keyframes next': {
    '0%': {
      opacity: 0,
      transform: 'translateX(200%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  '@keyframes previous': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-200%)'
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  previous: {
    animation: '$previous .2s linear forwards'
  },
  next: {
    animation: '$next .2s linear forwards'
  },
  tableTitle: {
    marginBlock: '16px'
  },
  disabled: {
    opacity: 0.6
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down(960)]: {
      paddingInline: '16px'
    }
  },
  goBackButton: {
    width: '24%',
    maxWidth: '76px',
    padding: '4px',
    borderRadius: '6px',
    border: 'none',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 800,
    color: theme.palette.secondary.main
  },
  downloadButton: {
    width: '33%',
    maxWidth: '140px',
    border: `2px solid ${theme.palette.secondary.main}`,
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'space-around',
    fontWeight: 600,
    color: theme.palette.secondary.main
  },
  table: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down(960)]: {
      paddingInline: '15px'
    }
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  cell: {
    border: 'none',
    padding: '6px',
    verticalAlign: 'bottom',
    maxWidth: '120px',
    overflowX: 'unset',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(600)]: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover, &:focus': {
        overflow: 'inherit',
        zIndex: 2
      }
    }
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBlock: '8px'
  },
  viewMoreButton: {
    background: 'none',
    border: 'none',
    width: '18px',
    height: '18px',
    marginLeft: '8px',
    verticalAlign: 'middle',
    padding: 0,
    cursor: 'pointer',
    borderRadius: '50%',
    transition: 'all 0.3s ease-out',

    '&:hover': {
      background: theme.palette.secondary.main,
      '& > svg': {
        fill: '#fff'
      }
    },

    '& > svg': {
      fill: theme.palette.secondary.main,
      width: '100%',
      height: '100%'
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '16px',
    gap: '26px'
  },
  tooltip: {
    [theme.breakpoints.down(600)]: {
      transition: 'all .2s linear',
      position: 'relative',

      '&:focus:after, &:hover:after': {
        content: 'attr(data-text)',
        fontSize: '0.85rem',
        background: '#fff',
        position: 'absolute',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        padding: '0 .5rem',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,.28)',
        left: '-12px',
        top: '6px'
      }
    }
  },
  backButton: {
    width: '35px',
    height: '35px'
  },
  forwardButton: {
    width: '35px',
    height: '35px'
  }
}));
export default useStyles;
