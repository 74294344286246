import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { clubeBlackTheme, theme } from 'assets/theme';
import ToastContextProvider from 'components/contexts/ToastContext';
import AppRouter from 'components/Router';
import Alert from 'components/Shared/Alert';
import FacebookPixel from 'components/Shared/FacebookPixel';
import GoogleAnalytics from 'components/Shared/GoogleAnalytics';
import Loader from 'components/Shared/Loader';
import React from 'react';
import rxjsOperators, { setup } from 'rxjs-operators';
import authService from 'services/auth';
import 'services/login';

interface IState {
  isClubeBlack: boolean;
}

class App extends React.PureComponent<{}, IState> {
  loader: Loader;

  constructor(prop: {}) {
    super(prop);
    this.state = {
      isClubeBlack: false
    };
  }

  componentDidMount() {
    setup(this.loader);
    authService
      .getUser()
      .pipe(rxjsOperators.filter(user => !!user))
      .subscribe(user => {
        this.setState({
          isClubeBlack: user.isClubeBlack
        });
      });
  }

  loaderRef = (ref: Loader) => {
    return (this.loader = ref);
  };

  render() {
    const { isClubeBlack } = this.state;
    return (
      <MuiThemeProvider theme={!isClubeBlack ? theme : clubeBlackTheme}>
        <CssBaseline />
        <GoogleAnalytics />

        <Loader ref={this.loaderRef} />

        <Alert.Global />

        <FacebookPixel />
        <ToastContextProvider>
          <AppRouter />
        </ToastContextProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
