import React, { useEffect, useState } from 'react';
import tokenService from 'services/token';

const RouterProtected: React.FC = ({ children }) => {
  const [userToken, setUserToken] = useState<string | null>(null);

  useEffect(() => {
    tokenService.getToken().subscribe(token => setUserToken(token));
  }, []);

  return <>{userToken && children}</>;
};

export default RouterProtected;
