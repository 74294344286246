import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down(960)]: {
      gap: `${theme.spacing(4)}px`
    }
  },
  title: {
    color: '#546E7A',
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '150%'
  },
  fieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    gap: `${theme.spacing(2)}px`,

    [theme.breakpoints.down(960)]: {
      flexDirection: 'column'
    }
  },
  field: {
    width: '100%'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingTop: `${theme.spacing(3)}px`,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 0
    }
  },
  actions: {
    [theme.breakpoints.down(600)]: {
      paddingTop: `${theme.spacing(3)}px`,
      borderTop: `1px solid ${theme.palette.divider}`,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex'
    }
  },
  saveFilters: {
    [theme.breakpoints.down(600)]: {
      paddingBottom: `${theme.spacing(2)}px`
    }
  },
  button: {
    backgroundColor: theme.variables.colors['primaryPurple'],
    color: '#FFF',
    width: 'auto',
    minWidth: 100,
    padding: '10px 0',
    borderRadius: 4,
    margin: '0 8px',
    textTransform: 'capitalize',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  buttonDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  switchesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(720)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(600)]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  },
  listFiltersButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['text'],
    width: 'auto',
    minWidth: 100,
    padding: 10,
    borderRadius: 4,
    margin: '0 8px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    },
    '& label': {
      color: theme.variables.colors['text']
    }
  },
  buttonIcon: {
    color: theme.variables.colors['primaryPurple']
  },
  clearFilterButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['primaryPurple'],
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['primaryPurple'],
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  saveFilterButton: {
    padding: 10,
    color: theme.variables.colors['text'],
    '&:hover': {
      color: theme.variables.colors['primaryPurple']
    }
  },
  inputContainer: {
    marginTop: 16
  },
  openProducts: {
    cursor: 'pointer',
    color: theme.variables.colors['text'],
    backgroundColor: 'transparent',
    outline: 'none',
    padding: '17px 10px 17px 10px',
    border: `1px solid ${theme.variables.colors['border']}`,
    borderRadius: 4,
    font: 'inherit',
    textAlign: 'left'
  },
  header: {
    gap: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
