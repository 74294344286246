import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import variables from './variables';

const defaultTheme = createMuiTheme();

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '*': {
        fontFamily: '"Open Sans", sans-serif'
      },
      body: {
        fontFamily: '"Open Sans", sans-serif',
        overflow: 'hidden',
        letterSpacing: 0,
        lineHeight: 1.3,
        position: 'relative'
      }
    }
  },
  MuiInput: {
    root: {
      borderRadius: 4,
      backgroundColor: defaultTheme.palette.common.white,
      border: `1px solid ${variables.colors['border']}`,
      color: variables.colors['text'],
      fontSize: 14,
      padding: '12px 17px',
      transition: defaultTheme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      },
      '&:before': {
        display: 'none'
      }
    }
  },
  MuiDialog: {
    paperWidthSm: {
      backgroundColor: 'transparent',
      maxWidth: '90%'
    }
  },
  MuiDialogTitle: {
    root: {
      backgroundColor: variables.colors['primaryPurple'],
      color: '#FFF'
    }
  },
  MuiTypography: {
    h2: {
      color: variables.colors['title'],
      fontFamily: 'Lato',
      fontSize: 18
    },
    subtitle1: {
      color: variables.colors['title'],
      fontFamily: 'Open Sans',
      fontSize: '0.875rem',
      lineHeight: '150%',
      fontWeight: 600
    },
    body1: {
      color: variables.colors['text'],
      fontSize: '0.875rem',
      fontFamily: 'Open Sans'
    },
    body2: {
      color: variables.colors['text'],
      fontFamily: 'Open Sans'
    }
  },
  MuiTableRow: {
    root: {
      height: 35
    }
  },
  MuiSelect: {
    icon: {
      right: 10,
      color: variables.colors['text']
    }
  },
  MuiTableCell: {
    root: {
      padding: '3px 0 3px 0',
      fontFamily: 'Lato',
      '&:last-child': {
        paddingRight: 0
      }
    },
    body: {
      fontSize: 14
    }
  },
  MuiTablePagination: {
    input: {
      padding: 0,
      marginLeft: 7,
      marginRight: 32
    },
    selectRoot: {
      marginLeft: 0,
      marginRight: 0
    },
    select: {
      paddingRight: 20
    }
  },
  MuiPaper: {
    elevation2: {
      boxShadow: 'none',
      border: '1px solid #ECEFF1'
    }
  },
  MuiTab: {
    root: {
      minWidth: '0 !important',
      padding: 0
    },
    wrapper: {
      fontSize: '0.8rem',
      // eslint-disable-next-line
      ['@media screen and (max-width: 960px)']: {
        fontSize: '0.7rem'
      }
    },
    textColorInherit: {
      color: '#565662'
    }
  },
  MuiTabs: {
    flexContainer: {
      justifyContent: 'space-around',
      padding: '0 10px'
    },
    indicator: {
      height: '3px !important'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
      backgroundColor: '#282645'
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  MuiFormLabel: {
    root: {
      color: `${variables.colors['text']} !important`
    }
  },
  MuiSwitch: {
    root: {
      height: 45
    },
    switchBase: {
      top: 5.5,
      left: 5,
      '&.Mui-checked': {
        transform: 'translateX(13px) !important'
      }
    },
    track: {
      opacity: '1 !important',
      backgroundColor: variables.colors['buttonDisabledBackground'],
      borderRadius: 50
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
      backgroundColor: '#FFFFFF'
    }
  },
  MuiBadge: {
    badge: {
      left: 0,
      backgroundColor: variables.colors['positive'],
      color: '#FFF',
      borderRadius: 4,
      padding: '4px 12px',
      fontWeight: 700,
      fontFamily: 'Open Sans',
      fontSize: '0.625rem'
    }
  }
};

export default overrides;
