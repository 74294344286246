import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const NoLimitInstallments = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.noLimitInstallments);

    return () => {
      dashboardService.removeComponent(enComponent.noLimitInstallments);
    };
  }, []);

  const [noLimitInstallments] = useObservable<any>(() => {
    return socketService.listen(enComponent.noLimitInstallments).pipe(
      rxjsOperators.filter(cancellations => !!cancellations),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map((c: any) => {
        return {
          value: c.value,
          performance: c.performance
        };
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Quanto tenho a receber de Parcelamento sem limites?',
        value: noLimitInstallments?.value,
        dataType: enDataType.currency
      }}
      oldIndicatorDataValue={0}
      description='Valor bruto referente ao quanto ainda há para receber de PSL, de acordo com o período aplicado no filtro, sem contabilizar desconto de taxas e afins'
    >
      <MetricPerformance performance={noLimitInstallments?.performance || 0} />
    </IndicatorPanel>
  );
};

export default NoLimitInstallments;
