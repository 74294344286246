/* eslint-disable react/jsx-no-bind */
import { makeStyles } from '@material-ui/core/styles';
import { theme } from 'assets/theme';
import React, { memo } from 'react';
import { Bar, BarChart as BarChartRecharts, Cell, LabelList, ResponsiveContainer, XAxis } from 'recharts';

export type IBarChartData = {
  name: string;
  count: number;
  value: string | number;
};

type ITickPayload = {
  index: number;
  value: IBarChartData['value'];
};

interface ITick {
  x: number;
  y: number;
  textAnchor: string;
  verticalAnchor: string;
  payload: ITickPayload;
}

interface IBarChartProps {
  id?: string;
  className?: string;
  height?: number;
  /**
   * The legend on the X axis?
   */
  legendX?: boolean;
  /**
   * Data rendered in chart.
   */
  data: IBarChartData[];
  /**
   * Indicator over each bar?
   */
  indicator?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 8,

    '&.--has-legend': {
      paddingBottom: 16
    }
  },

  legendX: {
    fontSize: 18,
    fill: '#000',
    fontWeight: 500
  },

  barChart: {
    '& > svg': {
      overflow: 'visible'
    }
  }
}));

const BarChart: React.FC<IBarChartProps> = memo(
  ({ legendX = true, data, height = 350, indicator = false, ...rest }) => {
    const classes = useStyles();

    const XTickLegend = ({ payload, y, x }: ITick) => {
      const { value } = payload;

      return (
        <text x={x} y={y + 24} className={classes.legendX}>
          {value}
        </text>
      );
    };

    return (
      <div className={`${classes.root} ${legendX ? '--has-legend' : ''}`}>
        <ResponsiveContainer {...rest} width='100%' height={height}>
          <BarChartRecharts
            className={indicator && classes.barChart}
            margin={{ top: 0, left: 0, bottom: 2, right: 0 }}
            data={data}
          >
            <Bar dataKey='value'>
              {indicator && <LabelList dataKey='count' position='top' />}
              {data.map((_, index) => (
                <Cell key={`bar-cell-${index}`} fill={theme.variables.colors[`bar${index + 1}`]} />
              ))}
            </Bar>

            {legendX && <XAxis stroke='none' tick={XTickLegend} dataKey='name' />}
          </BarChartRecharts>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default BarChart;
