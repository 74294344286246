import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import React, { useState } from 'react';

interface IProps {
  onSaveFilter: (name: string) => void;
  isOpen: boolean;
  closeDialog: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    backgroundColor: theme.variables.colors['primaryPurple'],
    color: '#FFF',
    width: 'auto',
    minWidth: 100,
    padding: '10px 0',
    borderRadius: 4,
    margin: '0 8px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['primaryPurple'],
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  saveFilterButton: {
    padding: 10,
    color: theme.variables.colors['text'],
    '&:hover': {
      color: theme.variables.colors['primaryPurple']
    }
  },
  buttonDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  filterNameInput: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px 0`
  },
  buttonIcon: {
    color: theme.variables.colors['primaryPurple']
  }
}));

const SaveFilters: React.FC<IProps> = ({ onSaveFilter, closeDialog, isOpen }) => {
  const [filterName, setFilterName] = useState<string>('');
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth='xs' scroll='paper' disableBackdropClick>
      <DialogTitle>Salvar filtro</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          onChange={e => setFilterName(e.target.value)}
          fullWidth
          type='text'
          margin='dense'
          label='Nome do filtro'
          className={classes.filterNameInput}
          inputProps={{ maxLength: 30 }}
        />
      </DialogContent>
      <DialogActions>
        <Button className={`${classes.clearButton} ${classes.button}`} onClick={closeDialog}>
          Cancelar
        </Button>
        <Button
          onClick={() => onSaveFilter(filterName)}
          className={`${classes.button} ${filterName.length < 2 && classes.buttonDisabled}`}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFilters;
