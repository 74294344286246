/* eslint-disable */
import { GA_MEASUREMENT_ID } from 'settings';

export interface IEvent {
  event_category: string;
  event_label?: string;
  value?: number;
}

class GoogleAnalyticsService {
  public sendPageView(page_title: string, page_path: string) {
    gtag('config', GA_MEASUREMENT_ID, {
      page_title,
      page_path
    });
  }

  public sendEvent = (action: string, event: IEvent) => {
    gtag('event', action, {
      ...event
    });
  };
}

const googleAnalytics = new GoogleAnalyticsService();
export default googleAnalytics;
