import { Button } from '@material-ui/core';
import axios from 'axios';
import { SvgIcon } from 'components/Shared/SvgIcon';
import React from 'react';
import { API_ENDPOINT } from 'settings';
import { TCustomerData, TFilter } from '.';
import useStyles from './styles';

interface IProps {
  onClickGoBack: () => void;
  hasSelectedCountry: boolean;
  customer: TCustomerData;
  filter: TFilter;
  isAdmin: boolean;
}

const Header: React.FC<IProps> = ({ onClickGoBack, hasSelectedCountry, customer, filter, isAdmin }) => {
  const classes = useStyles();
  const handleDownload = async () => {
    const { id, token } = customer;
    const { viewAs, from, to, productIds } = filter;

    const isAdminMode = isAdmin && !id;
    if (!token || isAdminMode) return;

    const getParam = {
      coproducer: { productIds },
      producer: { customerId: id },
      affiliate: { customerId: id }
    };

    const { data } = await axios.get('reports/aggregated-sales', {
      baseURL: API_ENDPOINT,
      params: {
        ...getParam[viewAs],
        viewAs,
        from,
        to
      },
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    });

    const url = window.URL || window.webkitURL;
    const jsonUrl = url.createObjectURL(data);

    const link = document.createElement('a');
    link.href = jsonUrl;
    link.download = `sales-report-${from}-${to}.csv`;
    link.click();
  };

  return (
    <div className={classes.tableHeader}>
      <Button
        className={`${classes.downloadButton} ${isAdmin && !customer.id ? classes.disabled : ''}`}
        onClick={handleDownload}
      >
        <SvgIcon name='download' />
        Exportar
      </Button>
      {hasSelectedCountry && (
        <Button onClick={onClickGoBack} className={classes.goBackButton}>
          <SvgIcon name='arrowBack' />
          Voltar
        </Button>
      )}
    </div>
  );
};

export default Header;
