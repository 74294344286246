import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { SvgIcon } from 'components/Shared/SvgIcon';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 30,
      height: '100%',
      '& svg': {
        width: '100%',
        maxWidth: 100,
        margin: '0 0 16px 0'
      }
    }
  })
);

const BlockedData: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon name='blockedData' />
      <Typography variant='subtitle1' align='center'>
        Você não tem acesso à essa informação
      </Typography>
    </div>
  );
};

export default BlockedData;
