import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem'
    }
  },
  seeTourBtn: {
    color: theme.variables.colors['lightText'],
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      color: theme.variables.colors['primaryPurple']
    }
  },
  link: {
    color: theme.variables.colors['lightText'],
    textDecoration: 'none',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.variables.colors['primaryPurple'],
      '& svg': {
        fill: theme.variables.colors['primaryPurple']
      }
    },
    '& svg': {
      width: 24,
      marginLeft: 8,
      fill: theme.variables.colors['lightText'],
      [theme.breakpoints.down('xs')]: {
        width: 20
      }
    }
  }
}));

export default useStyles;
