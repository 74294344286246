import { enViewAs, IFilterModel } from 'interfaces/filter';
import { enRoleAction } from 'interfaces/userToken';
import moment from 'moment';
import * as rxjs from 'rxjs';
import rxjsOperators from 'rxjs-operators';
import authService from 'services/auth';

export interface IFilterRange {
  range: string;
  comparisonRange: string;
}
export class FilterService {
  private showLoadingFilter$: rxjs.BehaviorSubject<boolean>;
  private range$: rxjs.BehaviorSubject<IFilterRange>;
  private filter$: rxjs.BehaviorSubject<IFilterModel>;
  private clearFilter$: rxjs.BehaviorSubject<boolean>;
  public initialFilter: IFilterModel;

  constructor() {
    this.showLoadingFilter$ = new rxjs.BehaviorSubject(false);
    this.clearFilter$ = new rxjs.BehaviorSubject(false);
    authService
      .canAccess(enRoleAction.filterByProducer)
      .pipe(rxjsOperators.combineLatest(authService.getUser()), rxjsOperators.logError())
      .subscribe(([canAccess, user]: any) => {
        if (!user) return;
        this.initialFilter = {
          customerId: canAccess ? null : user.id,
          productId: null,
          viewAs: enViewAs.producer,
          startDate: moment()
            .utcOffset(-3)
            .hour(0)
            .minute(0)
            .seconds(0)
            .subtract(canAccess ? 3 : 7, 'days')
            .toDate(),
          endDate: moment()
            .utcOffset(-3)
            .hour(23)
            .minute(59)
            .seconds(59)
            .toDate(),
          rangeTime: 0,
          productIds: [],
          ignoreBankslip: false,
          ignoreFreeContents: false,
          ignoreLaunches: false
        };
        this.filter$ = new rxjs.BehaviorSubject(this.initialFilter);
        this.range$ = new rxjs.BehaviorSubject({
          range: 'Últimos 7 dias + Hoje',
          comparisonRange: `Variação comparada ao período de ${moment()
            .subtract(canAccess ? 7 : 15, 'days')
            .format('DD/MM/YYYY')} a ${moment()
            .subtract(canAccess ? 4 : 7, 'days')
            .format('DD/MM/YYYY')}`
        });
      });
  }

  public shouldShowLoadingFilter(): rxjs.Observable<boolean> {
    return this.showLoadingFilter$.asObservable();
  }

  public showLoadingFilter(showLoading: boolean): void {
    this.showLoadingFilter$.next(showLoading);
  }

  public setFilterRange(ranges: IFilterRange): void {
    this.range$.next(ranges);
  }

  public getFilterRange(): rxjs.Observable<IFilterRange> {
    return this.range$.asObservable();
  }

  public getFilterRangeText(model: IFilterModel): IFilterRange {
    if (moment(model.startDate).isSame(moment(), 'days')) {
      return {
        range: 'Hoje',
        comparisonRange: `Variação comparada ao dia ${moment()
          .subtract(1, 'days')
          .format('DD/MM/YYYY')}`
      };
    }

    if (moment(model.startDate).isSame(moment(model.endDate), 'day')) {
      return {
        range: `Dia ${moment(model.startDate).format('DD/MM/YYYY')}`,
        comparisonRange: `Variação comparada ao dia ${moment(model.startDate)
          .subtract(1, 'days')
          .format('DD/MM/YYYY')}`
      };
    }

    const endPerformanceDate = moment(model.startDate)
      .subtract(1, 'days')
      .format('DD/MM/YYYY');

    const rangeTime = moment(model.endDate).diff(moment(model.startDate), 'days');

    const startPerformanceDate = moment(model.startDate)
      .subtract(rangeTime + 1, 'days')
      .format('DD/MM/YYYY');

    return {
      range: `Entre ${moment(model.startDate).format('DD/MM/YYYY')} e ${moment(model.endDate).format('DD/MM/YYYY')}`,
      comparisonRange: `Variação comparada ao período de ${startPerformanceDate} a ${endPerformanceDate}`
    };
  }

  public setFilter(filter: IFilterModel): void {
    this.filter$.next(filter);
    this.range$.next(this.getFilterRangeText(filter));
  }

  public getFilter(): rxjs.Observable<IFilterModel> {
    return this.filter$.asObservable();
  }

  public shouldClearFilter(): rxjs.Observable<boolean> {
    return this.clearFilter$.asObservable();
  }

  public clearFilter(): void {
    this.clearFilter$.next(true);

    setTimeout(() => {
      this.clearFilter$.next(false);
    }, 500);
  }
}

const filterService = new FilterService();
export default filterService;
