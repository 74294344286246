import { WithStyles } from 'decorators/withStyles';
import { IChartData } from 'interfaces/chart';
import { IConfirmedSales, IConfirmedSalesData } from 'interfaces/confirmedSales';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';
import Chart from '../../Shared/AreaChart';

export interface IState {
  data: IChartData[];
  total: number;
}

interface IProps {
  classes?: any;
}

@WithStyles(() => ({
  overrides: {
    backgroundColor: 'transparent',
    border: 'none'
  }
}))
export default class TotalSales extends PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      total: 0,
      data: []
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.confirmedSales)
      .pipe(
        rxjsOperators.filter((confirmedSales: IConfirmedSales) => !!confirmedSales),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(confirmedSales => {
        const data: IChartData[] = [];

        confirmedSales.confirmedSalesByDay.forEach((confirmedSales: IConfirmedSalesData) => {
          data.push({
            date: confirmedSales.date,
            total: confirmedSales.total
          });
        });

        this.setState({
          total: confirmedSales.total,
          data
        });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.confirmedSales);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.confirmedSales);
  }

  render() {
    const { data, total } = this.state;
    const { classes } = this.props;

    return (
      <Chart
        chartData={{
          title: 'Vendas Confirmadas',
          description: 'Processos de compra completado pelo cliente',
          value: total,
          key: 'total',
          dataType: enDataType.numeric,
          data
        }}
        disableDecimal
        className={classes.overrides}
      />
    );
  }
}
