import { IndicatorPieChart } from 'components/Shared/PieChart';
import { roundNumber } from 'helpers/formatNumber';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart } from 'interfaces/indicator';
import { ISalesByPaymentMethod } from 'interfaces/salesByPaymentMethod';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import socketService from 'services/socket';

const labels = {
  bankslip: 'Boleto Bancário',
  creditCard: 'Cartão de Crédito',
  paypal: 'Paypal',
  free: 'Gratuitos',
  pix: 'Pix',
  others: 'Outros'
};

const SalesByPaymentMethods = () => {
  const [salesByPaymentMethods, setSalesByPaymentMethods] = useState<IIndicatorChart[] | null>(null);

  useEffect(() => {
    dashboardService.addComponent(enComponent.salesByPaymentMethods);

    return () => {
      dashboardService.removeComponent(enComponent.salesByPaymentMethods);
    };
  }, []);

  const initialData: IIndicatorChart[] = [{ label: '', value: null, color: '' }];

  useEffect(() => {
    socketService
      .listen<ISalesByPaymentMethod>(enComponent.salesByPaymentMethods)
      .pipe(
        rxjsOperators.filter(spm => !!spm),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(spm => {
        const keys = Object.keys(spm);
        const indicatorsPieChart: IIndicatorChart[] = [];

        keys.forEach(pm => {
          if (spm[pm] && !!roundNumber(spm[pm], 0)) {
            indicatorsPieChart.push({
              label: labels[pm],
              value: spm[pm]
            });
          }
        });

        setSalesByPaymentMethods(indicatorsPieChart);
      });
  }, []);

  return (
    <IndicatorPieChart
      chartData={{
        title: 'Formas de Pagamento',
        dataType: enDataType.percent,
        indicators: salesByPaymentMethods?.length > 0 ? salesByPaymentMethods : initialData
      }}
      elevation={2}
    />
  );
};

export default SalesByPaymentMethods;
