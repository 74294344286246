import React from 'react';
import useStyles from './styles';

type Props = {
  handleOpen: () => void;
};

const ScreenActions = ({ handleOpen }: Props) => {
  const classes = useStyles();

  return (
    <div style={{textAlign: 'right'}}>
      <span className={classes.seeTourBtn} role='button' onClick={handleOpen}>
        Tour de novidades
      </span>
    </div>
  );
};

export default ScreenActions;
