export enum enRefusalReasons {
  ENOUGH_BALANCE = 'ENOUGH_BALANCE',
  GENERIC = 'GENERIC',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  FRAUD = 'FRAUD',
  REJECTED_BAD_FILLED = 'REJECTED_BAD_FILLED',
  INVALID_CARD = 'INVALID_CARD',
  REJECTED_BAD_FILLED_SECURITY_CODE = 'REJECTED_BAD_FILLED_SECURITY_CODE',
  REJECTED_BAD_FILLED_CARD_NUMBER = 'REJECTED_BAD_FILLED_CARD_NUMBER',
  EMPTY_TOKEN = 'EMPTY_TOKEN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  REJECTED_BAD_FILLED_DATE = 'REJECTED_BAD_FILLED_DATE'
}

export enum enLabels {
  ENOUGH_BALANCE = 'Saldo insuficiente',
  FRAUD = 'Operadora recusou a compra por suspeita de fraude',
  GENERIC = 'O banco emissor do cartão recusou a compra',
  INVALID_CARD = 'Cartão inválido',
  INVALID_PASSWORD = 'Cartão de débito (não disponível)',
  NOT_AUTHORIZED = 'Operadora recusou a compra',
  REJECTED_BAD_FILLED = 'Dados digitados errados',
  REJECTED_BAD_FILLED_CARD_NUMBER = 'Dados digitados errados',
  REJECTED_BAD_FILLED_SECURITY_CODE = 'Código de segurança inválido',
  REJECTED_BAD_FILLED_DATE = 'Data de vencimento inválida',
  EMPTY_TOKEN = 'Token inválido',
  BLOCKED_CARD = 'Cartão bloqueado'
}

export type TRefusal = {
  reason: keyof typeof enRefusalReasons;
  percent: number;
  count: number;
};

export type TMappedRefusal = {
  prefix: string;
  name: string;
  value: number;
  count: number;
};
