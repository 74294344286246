import { Paper } from '@material-ui/core';
import React, { Fragment, PureComponent } from 'react';

import Form from './Form';
import MobileFilter from './Mobile';

interface IState {
  screenWidth: number;
}

interface IProps {
  elevation?: number;
  hideTitle?: boolean;
  noPadding?: boolean;
}

export default class Filter extends PureComponent<IProps, IState> {
  constructor(props: {}) {
    super(props);

    this.state = { screenWidth: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  render() {
    const { screenWidth } = this.state;
    const { elevation } = this.props;

    return (
      <Fragment>
        {screenWidth >= 600 ? (
          <Paper elevation={elevation ?? 2}>
            <Form />
          </Paper>
        ) : (
          <MobileFilter />
        )}
      </Fragment>
    );
  }
}
