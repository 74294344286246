export interface IUsersOnline {
  total: number;
  producers: any;
  contents: any;
}

export enum enComponent {
  conversionFunnel = 'conversionFunnelComponent',
  creditCardApprovalFee = 'creditCardApprovalFeeComponent',
  internalCreditCardApprovalRate = 'internalCreditCardApprovalRateComponent',
  onlineUsers = 'onlineUsersComponent',
  affiliateSales = 'affiliateSalesComponent',
  devices = 'devicesComponent',
  checkoutGeneralConversion = 'checkoutGeneralConversionComponent',
  confirmedSales = 'confirmedSalesComponent',
  pageLoading = 'pageLoadingComponent',
  uptime = 'uptimeComponent',
  transactionsTable = 'transactionsTableComponent',
  bankslipCreditCardSales = 'bankslipCreditCardSalesComponent',
  conversionTracking = 'conversionTrackingComponent',
  creditCardInstallments = 'creditCardInstallmentsComponent',
  regionality = 'regionalityComponent',
  topSallingProducts = 'topSallingProductsComponent',
  recurringNewCustomers = 'recurringNewCustomersComponent',
  cancellations = 'cancellationsComponent',
  salesNotification = 'salesNotificationComponent',
  orderBumpSales = 'orderBumpSalesComponent',
  subscriptions = 'subscriptionsComponent',
  customSalesTemplates = 'customSalesTemplatesComponent',
  salesByPaymentMethods = 'salesByPaymentMethodsComponent',
  bankslipConversion = 'bankslipConversionComponent',
  grossBilling = 'grossBillingComponent',
  orderBumpBilling = 'orderBumpBillingComponent',
  noLimitInstallments = 'noLimitInstallmentsComponent',
  pendingSales = 'pendingSalesComponent',
  cardRefusalsCount = 'cardRefusalsCountComponent',
  installmentCount = 'installmentCountComponent',
  chargebackRates = 'chargebackRatesComponent'
}
