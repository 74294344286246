import { ThemeVariables } from '@material-ui/core/styles/createMuiTheme';

const variables: ThemeVariables = {
  drawerWidth: 300,
  headerHeight: 56,
  headerHeightUpSm: 64,
  tabbarHeight: 48,
  contentPadding: 32,
  contentPaddingUpSm: 24,
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  funnelConversionHeight: 285,
  funnelConversionFunnel: 285 / 2.5,
  colors: {
    positive: '#4CAF50',
    negative: '#F44336',
    background: 'linear-gradient(118deg, rgba(255, 255, 255, .22) 0%, rgba(151,144,224, .22) 100%)',
    backgroundMobile: '#fff',
    lightPrimaryPurple: '#D2A7DD',
    primaryPurple: '#8E24AA',
    success: '#009358',
    successHover: '#017446',
    error: '#dc3f53',
    title: '#546E7A',
    text: '#546E7A',
    lightText: '#78909C',
    label: '#596375',
    chartStroke: '#8E24AA',
    chartFill: '#F9FAFB',
    customizerAppBarBackground: '#192542',
    link: '#F59211',
    customizerTitle: '#192542',
    inputBorder: '#D9D9D9',
    inputColorHover: '#192542',
    funnelAccess: '#8E24AA',
    funnelInvoices: '#8E24AA',
    funnelConversion: '#8E24AA',
    tabsIndicator: '#FABB0A',
    tableHeadBackground: '#837ED3',
    tableFillSvgIcons: '#339DCD',
    newRowAnimationBackground: '#cdcbf4',
    disabledSwitch: '#FFF',
    activeSwitch: '#9A3AB3',
    disabledSwitchSvgFill: '#55307E',
    switchBorder: '#ECEFF1',
    activeSwitchSvgFill: '#FFFFFF',
    indicatorChartBackground: '#F9FAFB',
    indicatorChartSeparator: '#ECEFF1',
    uptimeLabel: '#339DCD',
    tooltipBackground: '#DDDDDD',
    disable: '#DDDDDD',
    menuBackground: '#363636',
    menuActive: '#474747',
    avatar: '#ffcc31',
    loader: '#07B990',
    buttonDisabledBackground: '#B0BEC5',
    buttonDisabledColor: '#546E7A',
    tableTextColor: '#37474F',
    chart1: '#00A526',
    chart2: '#8E24AA',
    chart3: '#FFCA28',
    chart4: '#039BE5',
    chart5: '#D81B60',
    chart6: '#FF8C00',
    chart7: '#F44336',
    tooltipIconFill: '#B0BEC5',
    horizontalBarChartPrimaryColor: '#D2A7DD',
    horizontalBarChartSecondaryColor: '#ECEFF1',
    horizontalBarChartBackground: '#F9FAFB',
    horizontalBarChartText: '#37474F',
    border: '#B0BEC5',
    rangeTime: '#B0BEC5',
    toastMessageGreen: '#4CAF50'
  }
};

export default variables;
