import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import React from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import filterService from 'services/filter';
import { SvgIcon } from '../SvgIcon';

interface IProps {
  performance: number;
  reverse?: boolean;
}

const arrowClass = (performance: number, reverse: boolean) => {
  if (performance > 0 && !reverse) {
    return 'positive';
  }

  if (performance < 0 && !reverse) {
    return 'negavite';
  }

  if (performance > 0 && reverse) {
    return 'arrowUpNegative';
  }

  if (performance < 0 && reverse) {
    return 'arrowDownPositive';
  }

  return 'neutral';
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'inherit',
    fontSize: '0.875rem',
    fontWeight: 400,
    '& svg': {
      marginRight: 8,
      width: 10
    }
  },
  positive: {
    color: theme.variables.colors['positive'],
    '& svg': {
      fill: theme.variables.colors['positive']
    }
  },
  negavite: {
    color: theme.variables.colors['negative'],
    '& svg': {
      transform: 'rotate(180deg)',
      fill: theme.variables.colors['negative']
    }
  },
  arrowUpNegative: {
    color: theme.variables.colors['negative'],
    '& svg': {
      fill: theme.variables.colors['negative']
    }
  },
  arrowDownPositive: {
    color: theme.variables.colors['positive'],
    '& svg': {
      transform: 'rotate(180deg)',
      fill: theme.variables.colors['positive']
    }
  },
  neutral: {}
}));
const MetricPerformance: React.FC<IProps> = ({ performance, reverse }) => {
  const classes = useStyles();

  const [performanceText] = useObservable(() => {
    return filterService.getFilterRange().pipe(
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(filterRange => {
        return filterRange.comparisonRange;
      })
    );
  }, []);

  return (
    <Tooltip title={performanceText} placement='bottom'>
      <span className={`${classes.container} ${classes[arrowClass(performance, reverse)]}`}>
        {performance !== 0 && (
          <>
            <SvgIcon name='arrow' />
            <span>{Math.abs(performance).toLocaleString('pt-BR')}%</span>
          </>
        )}

        {performance === 0 && <span>--</span>}
      </span>
    </Tooltip>
  );
};

export default MetricPerformance;
