import { darken, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      minWidth: 0
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '80vh'
    }
  },
  paperWidthSm: {
    margin: 0
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '1.43px'
  },
  list: {
    overflow: 'auto',
    margin: '16px 0',
    height: 400,
    maxHeight: 'calc(50vh - 60px)',
    [theme.breakpoints.down('md')]: {
      height: '50vh'
    }
  },
  product: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&#children-product': {
      paddingLeft: 32,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 12
      }
    }
  },
  productContainer: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    margin: '4px 0',
    minWidth: 700
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      justifyContent: 'center'
    }
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  redCounter: {
    fontWeight: 'bold',
    color: theme.palette.error.main
  },
  button: {
    padding: '8px 68px',
    margin: '0 8px',
    '&#confirm-button': {
      backgroundColor: theme.variables.colors['success'],
      color: '#FFF',
      marginRight: 0,
      '&:hover': {
        backgroundColor: darken(theme.variables.colors['success'], 0.3)
      }
    }
  },
  productLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem'
    }
  },
  modalFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down(600)]: {
      flexDirection: 'column'
    }
  }
}));

export default useStyles;
