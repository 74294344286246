import { Paper, Slide, Theme } from '@material-ui/core';
import Form from 'components/Dashboard/Filters/Form';
import FabButton from 'components/Shared/FabButton';
import { WithStyles } from 'decorators/withStyles';
import React, { Fragment, PureComponent } from 'react';
import googleAnalytics from 'services/googleAnalytics';

interface IProps {
  classes?: any;
}

interface IState {
  showFilter: boolean;
}

@WithStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  filterWrapper: {
    overflowY: 'auto',
    width: '100%',
    background: '#fff',
    height: '100%',
    pointerEvents: 'initial',
    padding: '0 0 100px 0',
    position: 'fixed',
    zIndex: 5,
    left: 0,
    top: 40
  }
}))
export default class MobileFilter extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showFilter: false
    };
  }

  showFilters = () => {
    const showFilter = !this.state.showFilter;
    this.setState({ showFilter });

    if (showFilter) {
      googleAnalytics.sendEvent('show_filter', {
        // eslint-disable-next-line
        event_category: 'filter'
      });
    }

    if (!showFilter) {
      googleAnalytics.sendEvent('hide_filter', {
        // eslint-disable-next-line
        event_category: 'filter'
      });
    }
  };

  handleSubmit = () => {
    this.setState({ showFilter: false });
  };

  render() {
    const { classes } = this.props;
    const { showFilter } = this.state;
    const action = {
      icon: !showFilter ? 'filter' : 'close',
      tooltip: 'Filtro de Pesquisa',
      onClick: this.showFilters
    };

    return (
      <Fragment>
        <FabButton action={action} />

        <Slide direction='left' in={showFilter}>
          <Paper className={classes.filterWrapper} id='filter-container' elevation={0}>
            <Form responsiveSubmit={this.handleSubmit} />
          </Paper>
        </Slide>
      </Fragment>
    );
  }
}
