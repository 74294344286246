import React, { useReducer } from 'react';

export type ShowcaseState = {
  /**
   * Starts at zero
   */
  currentStep: number;
  isOpen: boolean;
};

export enum ShowcaseActionType {
  HANDLE_NEXT = 'HANDLE_NEXT',
  HANDLE_PREV = 'HANDLE_PREV',
  HANDLE_CLOSE = 'HANDLE_CLOSE',
  HANDLE_OPEN = 'HANDLE_OPEN'
}

export type ShowcaseAction = {
  type: ShowcaseActionType;
  payload?: Partial<ShowcaseState>;
};

export type ShowcaseReducer = (state: ShowcaseState, action: ShowcaseAction) => ShowcaseState;

function showcaseReducer(state: ShowcaseState, { type }: ShowcaseAction) {
  switch (type) {
    case ShowcaseActionType.HANDLE_NEXT:
      return {
        ...state,
        currentStep: state.currentStep + 1
      };
    case ShowcaseActionType.HANDLE_PREV:
      return {
        ...state,
        currentStep: state.currentStep - 1
      };
    case ShowcaseActionType.HANDLE_CLOSE:
      return {
        ...state,
        isOpen: false,
        currentStep: 0
      };
    case ShowcaseActionType.HANDLE_OPEN:
      return {
        ...state,
        isOpen: true
      };
    default:
      throw new Error('Action type not found');
  }
}

export type UseShowcaseProps = {
  reducer?: ShowcaseReducer;
  initialOpen?: boolean;
  initialStep?: number;
};

function useShowcase(
  { reducer = showcaseReducer, initialOpen = false, initialStep = 0 }: UseShowcaseProps = {
    reducer: showcaseReducer,
    initialOpen: false,
    initialStep: 0
  }
) {
  const { current: initialState } = React.useRef<ShowcaseState>({ currentStep: initialStep, isOpen: initialOpen });
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentStep, isOpen } = state;

  function handleClose() {
    dispatch({ type: ShowcaseActionType.HANDLE_CLOSE });
  }

  function handleOpen() {
    dispatch({ type: ShowcaseActionType.HANDLE_OPEN });
  }

  function handleNextStep() {
    dispatch({ type: ShowcaseActionType.HANDLE_NEXT });
  }

  function handlePreviousStep() {
    dispatch({ type: ShowcaseActionType.HANDLE_PREV });
  }

  return {
    currentStep,
    isOpen,
    handleClose,
    handleOpen,
    handleNextStep,
    handlePreviousStep
  };
}

export { useShowcase, showcaseReducer };
