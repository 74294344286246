import { useCallback, useEffect, useRef } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import socketService from 'services/socket';

const useInactivateSocket = () => {
  const timeoutId = useRef<NodeJS.Timer>(null);

  const [isActive] = useObservable(() => {
    return socketService.socketIsActive().pipe(
      rxjsOperators.tap((isActive: boolean) => {
        if (!isActive && document.hasFocus()) handleActivateSocket();
      }),
      rxjsOperators.map(isActive => isActive)
    );
  }, []);

  const handleActivateSocket = useCallback(() => {
    socketService.activate();
  }, []);

  const handleInactivateSocket = useCallback(() => {
    socketService.inactivate();
  }, []);

  const handleVisibilityChange = useCallback(() => {
    const time = document.hasFocus() ? 10 : 60;
    clearTimeout(timeoutId.current);
    const isSmartTv = JSON.parse(localStorage.getItem('telescope_enabledSmartTvMode'));

    timeoutId.current = setTimeout(() => {
      if (isActive && !document.hasFocus() && !isSmartTv) {
        handleInactivateSocket();
        return;
      }
      if (!isActive && (document.hasFocus() || !isSmartTv)) {
        handleActivateSocket();
      }
    }, time * 1000);
  }, [handleActivateSocket, handleInactivateSocket, isActive, timeoutId]);

  useEffect(() => {
    window.addEventListener('blur', handleVisibilityChange);
    window.addEventListener('focus', handleVisibilityChange);

    return () => {
      window.removeEventListener('blur', handleVisibilityChange);
      window.removeEventListener('focus', handleVisibilityChange);
      if (timeoutId?.current) clearTimeout(timeoutId.current);
    };
  }, [handleVisibilityChange, timeoutId]);

  useEffect(() => {
    socketService.init();
    socketService.startWatchDashboard();
    return () => {
      socketService.clearWatchDashboardSubscription();
    };
  }, []);
};

export default useInactivateSocket;
