import { IData } from './metrics';

export interface ITopSallingProductsByContents {
  contentId: number;
  title: string;
  value: IData;
}

export interface ITopSallingProductsByType {
  type: enContentType;
  value: IData;
}

export interface ITopSallingProducts {
  topContents: ITopSallingProductsByContents[];
  topProductsType: ITopSallingProductsByType[];
}

export enum enContentType {
  DIGITAL = 1,
  JOB = 2,
  PHYSICAL = 3,
  TICKET = 4,
  PACKAGE = 6,
  PROJECT = 8
}
