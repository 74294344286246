import { Grid, Paper, Theme } from '@material-ui/core';
import HorizontalBarChart from 'components/Shared/HorizontalBarChart';
import { IndicatorPieChart } from 'components/Shared/PieChart';
import { WithStyles } from 'decorators/withStyles';
import { roundNumber } from 'helpers/formatNumber';
import { enDataType } from 'interfaces/dashboard';
import { IDevice } from 'interfaces/device';
import { IIndicatorChart, IIndicatorHorizontalBarChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import React, { Component } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';
import { IAccessByBrowser } from 'interfaces/device';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
  indicatorsBarChart: IIndicatorHorizontalBarChart[];
}

const filterBrowsers = (browsers: IAccessByBrowser[]) => {
  const validBrowsers = new Map([
    ['Edge', 1],
    ['Opera', 1],
    ['Firefox', 1],
    ['Chrome', 1],
    ['Safari', 1],
    ['Apple WebKit', 1],
  ]);
  const filtered = browsers.filter(item => {
    return validBrowsers.has(item.browser);
  });

  const total = filtered.reduce((carry, item) => {
    return carry + item.percentage;
  }, 0);

  filtered.push({
    browser: 'Outros',
    percentage: 100 - total
  });

  return filtered;
}

@WithStyles((theme: Theme) => ({
  container: {
    height: '100%'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column'
    },
    height: '100%'
  },
  divider: {
    width: 1,
    borderLeft: 'solid 1px',
    color: theme.variables.colors['indicatorChartSeparator'],
    '@media screen and (max-width: 960px)': {
      width: '100%',
      height: 1,
      borderTop: 'solid 1px'
    }
  }
}))
export default class Devices extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }],
      indicatorsBarChart: [{ data: [] }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.devices)
      .pipe(
        rxjsOperators.filter((devices: IDevice) => !!devices),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(devices => {
        this.setAccessByDevices(devices);
        this.setAccessByBrowsers(devices);
      });
  }

  setAccessByBrowsers = (devices: IDevice) => {
    const accessByBrowser = filterBrowsers(devices.accessByBrowser);
    const indicatorsBarChart: IIndicatorHorizontalBarChart[] = [
      {
        data: []
      }
    ];
    accessByBrowser.forEach(b => {
      if (!!roundNumber(b.percentage, 1)) {
        indicatorsBarChart[0].data.push({
          label: b.browser,
          percentage: b.percentage
        });
      }
    });

    this.setState({ indicatorsBarChart });
  };

  setAccessByDevices = (devices: IDevice) => {
    const accessByDevices = Object.keys(devices.accessByDevice);
    const indicatorsPieChart: IIndicatorChart[] = [];

    accessByDevices.shift();

    const labels = {
      mobile: 'Mobile',
      desktop: 'Desktop',
      other: 'Outros'
    };

    accessByDevices.forEach((d: string) => {
      if (!!roundNumber(devices.accessByDevice[d].percentage, 0)) {
        indicatorsPieChart.push({
          label: labels[d],
          value: devices.accessByDevice[d].percentage
        });
      }
    });

    this.setState({ indicatorsPieChart });
  };

  componentWillMount() {
    dashboardService.addComponent(enComponent.devices);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.devices);
  }

  render() {
    const { classes } = this.props;
    const { indicatorsPieChart, indicatorsBarChart } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={2}>
          <Grid item xs={12} md={12} lg={6}>
            <IndicatorPieChart
              chartData={{
                title: 'Dispositivos',
                dataType: enDataType.percent,
                indicators: indicatorsPieChart
              }}
              elevation={0}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item xs={12} md={12} lg={6}>
            <HorizontalBarChart
              chartData={{
                title: 'Navegadores Utilizados',
                dataType: enDataType.numeric,
                indicators: indicatorsBarChart
              }}
              elevation={0}
              showOptions={false}
              defaultDataType={enDataType.percent}
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}
