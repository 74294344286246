import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import useStyles from './styles';

export default function Loader() {
  const classes = useStyles();

  return (
    <Modal open className={classes.root}>
      <CircularProgress className={classes.loader} size='large' />
    </Modal>
  );
}
