import { Button, InputLabel, MuiThemeProvider } from '@material-ui/core';
import { theme } from 'assets/theme';
import { isInclusivelyAfterDay } from 'helpers/isInclusivelyDay';
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { IDateChangeEvent } from '../Form';
import useStyles from './styles';

interface IProps {
  handleDatesChange: (filterTime: IDateChangeEvent) => void;
  startDate?: Moment;
  endDate?: Moment;
}

const Calendar: React.FC<IProps> = ({ handleDatesChange, startDate, endDate }) => {
  const classes = useStyles();

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | boolean | null>(null);
  const options = [7, 15, 30];
  const numberOfMonths = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 960 ? 1 : 2;

  const scrollIntoView = () => {
    const dateRangePickerElement = document.getElementById('date-range-picker');
    const filterElement = document.getElementById('filter-container');

    if (dateRangePickerElement && filterElement) {
      setTimeout(() => {
        filterElement.scrollTop = filterElement.scrollHeight;
      }, 100);
    }
  };

  const onDatesChange = (e: IDateChangeEvent) => {
    handleDatesChange(e);
  };

  const onFocusChange = (input: FocusedInputShape) => {
    setFocusedInput(input);
    scrollIntoView();
  };

  const isOutsideRange = (day: Moment): boolean => {
    return !isInclusivelyAfterDay(day, moment('2019-10-01')) || isInclusivelyAfterDay(day, moment().add(1, 'day'));
  };

  const onRangeChange = (days: number) => () => {
    if (days === 1) days = 0;

    onDatesChange({ startDate: moment().subtract(days, 'days'), endDate: moment() });
    setFocusedInput(false);
  };

  const getInitialVisibleMonth = () => {
    if (focusedInput === 'startDate' || !endDate) return startDate;

    return endDate;
  };

  const renderRangeButtons = () => {
    return (
      <div className={classes.calendarInfo}>
        <Button className={classes.button} onClick={onRangeChange(1)}>
          Hoje
        </Button>
        {options.map((day, index) => (
          <Button onClick={onRangeChange(day)} className={classes.button} key={index}>
            Últimos {day} dias
          </Button>
        ))}
      </div>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.calendar} id='date-range-picker' onClick={scrollIntoView}>
        <InputLabel htmlFor='range-time' shrink={true}>
          Período
        </InputLabel>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          startDateId='start_date'
          endDateId='end_date'
          onDatesChange={onDatesChange}
          displayFormat={'DD/MM/YYYY'}
          startDatePlaceholderText='Data inicial'
          endDatePlaceholderText='Data final'
          focusedInput={focusedInput as FocusedInputShape}
          onFocusChange={onFocusChange}
          isOutsideRange={isOutsideRange}
          initialVisibleMonth={getInitialVisibleMonth}
          showDefaultInputIcon
          numberOfMonths={numberOfMonths}
          minimumNights={0}
          openDirection='down'
          readOnly
          small
          renderCalendarInfo={renderRangeButtons}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default Calendar;
