import { Grid, Paper, Theme } from '@material-ui/core';
import HorizontalBarChart from 'components/Shared/HorizontalBarChart';
import { IndicatorPieChart } from 'components/Shared/PieChart';
import { WithStyles } from 'decorators/withStyles';
import { IConversionTracking } from 'interfaces/conversionTracking';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart, IIndicatorHorizontalBarChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import React, { Component } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
  indicatorsBarChart: IIndicatorHorizontalBarChart[];
}

@WithStyles((theme: Theme) => ({
  container: {
    height: '100%'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column'
    }
  },
  divider: {
    width: 1,
    borderLeft: 'solid 1px',
    color: theme.variables.colors['indicatorChartSeparator'],
    '@media screen and (max-width: 960px)': {
      width: '100%',
      height: 1,
      borderTop: 'solid 1px'
    }
  }
}))
export default class ConversionTracking extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }],
      indicatorsBarChart: [{ data: [] }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.conversionTracking)
      .pipe(
        rxjsOperators.filter((conversionTracking: IConversionTracking) => !!conversionTracking),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(conversionTracking => {
        this.setConversionTracking(conversionTracking);
        this.setConversionByUTM(conversionTracking);
      });
  }

  setConversionByUTM = (conversionTracking: IConversionTracking) => {
    const indicatorsBarChart: IIndicatorHorizontalBarChart[] = [
      {
        optionName: 'Source',
        data: []
      },
      {
        optionName: 'Medium',
        data: []
      },
      {
        optionName: 'Campaign',
        data: []
      },
      {
        optionName: 'Content',
        data: []
      }
    ];

    conversionTracking.conversionByUTM.utmSource.forEach(us => {
      indicatorsBarChart[0].data.push({
        label: us.utm,
        total: us.total
      });
    });

    conversionTracking.conversionByUTM.utmMedium.forEach(um => {
      indicatorsBarChart[1].data.push({
        label: um.utm,
        total: um.total
      });
    });

    conversionTracking.conversionByUTM.utmCampaign.forEach(uc => {
      indicatorsBarChart[2].data.push({
        label: uc.utm,
        total: uc.total
      });
    });

    conversionTracking.conversionByUTM.utmContent.forEach(uc => {
      indicatorsBarChart[3].data.push({
        label: uc.utm,
        total: uc.total
      });
    });

    this.setState({ indicatorsBarChart });
  };

  setConversionTracking = (conversionTracking: IConversionTracking) => {
    const conversionTraceable = Object.keys(conversionTracking.conversionTracking);
    const indicatorsPieChart: IIndicatorChart[] = [];

    const labels = {
      traceable: 'Rastreável',
      untraceable: 'Não rastreável'
    };

    conversionTraceable.forEach((ct: string) => {
      if (!!conversionTracking.conversionTracking[ct].percentage) {
        indicatorsPieChart.push({
          label: labels[ct],
          value: conversionTracking.conversionTracking[ct].percentage
        });
      }
    });

    this.setState({ indicatorsPieChart });
  };

  componentWillMount() {
    dashboardService.addComponent(enComponent.conversionTracking);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.conversionTracking);
  }

  render() {
    const { classes } = this.props;
    const { indicatorsPieChart, indicatorsBarChart } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={2}>
          <Grid item xs={12} md={12} lg={6}>
            <IndicatorPieChart
              chartData={{
                title: 'Rastreamento de Conversão',
                dataType: enDataType.percent,
                indicators: indicatorsPieChart
              }}
              elevation={0}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item xs={12} md={12} lg={6}>
            <HorizontalBarChart
              chartData={{
                title: 'Volume por UTMs',
                dataType: enDataType.numeric,
                indicators: indicatorsBarChart
              }}
              description='As UTMs mais utilizadas em suas vendas'
              elevation={0}
              showOptions
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}
