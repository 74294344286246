import IndicatorPanel from 'components/Shared/IndicatorPanel';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const CheckoutCurrentUsers: React.FC = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.onlineUsers);
    return () => dashboardService.removeComponent(enComponent.onlineUsers);
  }, []);

  const [previous, setPrevious] = useState<number>(0);

  const [onlineUsers] = useObservable<any>(() => {
    return socketService.listen(enComponent.onlineUsers).pipe(
      rxjsOperators.distinctUntilChanged((a: number, b: number) => {
        setPrevious(a);
        return a === b;
      }),
      rxjsOperators.map((onlineUsers: number) => {
        return onlineUsers;
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Pessoas online no checkout',
        value: onlineUsers || 0,
        dataType: enDataType.numeric
      }}
      hideTime
      oldIndicatorDataValue={previous}
      customRangeTime='Este dado é atualizado em tempo real'
      description='Quantidade de pessoas online no checkout, de acordo com os produtos buscados no filtro. Período não se aplica nesse resultado.'
    ></IndicatorPanel>
  );
};

export default CheckoutCurrentUsers;
