import React, { Fragment, useCallback, useEffect } from 'react';
import { IS_DEVELOPMENT } from 'settings';

const FacebookPixel: React.FC = () => {
  const getFacebookPixelScript = useCallback((id: string) => {
    return `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${id}');
      fbq('track', 'PageView');
      fbq('track', 'ViewContent');
    `;
  }, []);

  const getFacebookPixelNoScript = useCallback((id: string) => {
    return `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1" />`;
  }, []);

  useEffect(() => {
    if (IS_DEVELOPMENT) {
      return;
    }

    const facebookScript = document.createElement('script');
    const facebookNoScript = document.createElement('noscript');
    const id = '559574654803115';

    facebookScript.innerHTML = getFacebookPixelScript(id);
    facebookNoScript.innerHTML = getFacebookPixelNoScript(id);

    document.head.appendChild(facebookScript);
    document.head.appendChild(facebookNoScript);

    // Add Facebook Pixel Backup

    const facebookScriptBkp = document.createElement('script');
    const facebookNoScriptBkp = document.createElement('noscript');
    const backupId = '1495693920670285';

    facebookScriptBkp.innerHTML = getFacebookPixelScript(backupId);
    facebookNoScriptBkp.innerHTML = getFacebookPixelNoScript(backupId);

    document.head.appendChild(facebookScriptBkp);
    document.head.appendChild(facebookNoScriptBkp);
  }, [getFacebookPixelScript, getFacebookPixelNoScript]);

  return <Fragment />;
};

export default FacebookPixel;
