import { Paper } from '@material-ui/core';
import HorizontalBarChart from 'components/Shared/HorizontalBarChart';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorHorizontalBarChart } from 'interfaces/indicator';
import { IDocInstallments } from 'interfaces/installments';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import socketService from 'services/socket';

const Installments = () => {
  const [chartData, setChartData] = useState<IIndicatorHorizontalBarChart[]>([{ data: [] }]);

  useEffect(() => {
    dashboardService.addComponent(enComponent.installmentCount);
    return () => {
      dashboardService.removeComponent(enComponent.installmentCount);
    };
  }, []);

  useEffect(() => {
    socketService
      .listen(enComponent.installmentCount)
      .pipe(
        rxjsOperators.map((data: IDocInstallments[]) => {
          const total = data.reduce((carry: number, item) => carry + item.count, 0);
          return data
            .map(item => {
              return { label: `${item.installments}x`, total: item.count, percentage: (item.count / total) * 100 };
            })
            .sort((a, b) => b.total - a.total);
        })
      )
      .subscribe(installments => setChartData([{ data: installments.slice(0, 10) }]));
  }, []);

  return (
    <Paper elevation={2} style={{ height: '100%' }}>
      <HorizontalBarChart
        chartData={{
          title: 'Parcelamentos',
          dataType: enDataType.numeric,
          indicators: chartData
        }}
        elevation={0}
        showOptions={false}
        enableSwitch
      />
    </Paper>
  );
};

export default Installments;
