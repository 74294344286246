import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  calendar: {
    width: '100%',
    zIndex: 3,
    '& .DateRangePickerInput': {
      height: 55,
      border: `1px solid ${theme.variables.colors['border']}`,
      borderRadius: 4,
      width: '100%',
      margin: '1px 8px 0 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    '& .DateRangePicker': {
      width: '100%'
    },
    '& .DateRangePicker_picker': {
      zIndex: 3
    },
    '& .CalendarDay__selected_span': {
      backgroundColor: theme.variables.colors['lightPrimaryPurple'],
      borderColor: '#b893c1'
    },
    '& .CalendarDay__hovered_span, & .CalendarDay__hovered_span:hover': {
      color: theme.variables.colors['primaryPurple'],
      backgroundColor: theme.variables.colors['lightPrimaryPurple'],
      borderColor: '#b893c1'
    },
    '& .CalendarDay__selected': {
      backgroundColor: theme.variables.colors['primaryPurple'],
      borderColor: theme.variables.colors['primaryPurple']
    },
    '& .DayPickerKeyboardShortcuts_show__bottomRight:hover::before, & .DayPickerKeyboardShortcuts_show__bottomRight::before': {
      borderRight: `33px solid ${theme.variables.colors['primaryPurple']}`
    }
  },
  button: {
    border: `1px solid ${theme.variables.colors['funnelConversion']}`,
    color: theme.variables.colors['funnelConversion'],
    '&:hover': {
      backgroundColor: theme.variables.colors['funnelConversion'],
      color: '#FFF'
    },
    '@media screen and (max-width: 960px)': {
      width: '100%',
      margin: '8px 0'
    }
  },
  calendarInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px 23px 23px',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column',
      padding: '8px 23px 180px'
    }
  }
}));

export default useStyles;
