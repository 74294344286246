import Toast from 'components/Shared/Toast';
import React, { useCallback, useState } from 'react';
import ToastContext, { enAlertSeverity } from './context';

const ToastContextProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<enAlertSeverity | null>(null);

  const showAlert = useCallback((toastMessage: string, toastSeverity: enAlertSeverity) => {
    setMessage(toastMessage);
    setSeverity(toastSeverity);
    setOpened(true);

    setTimeout(() => {
      setOpened(false);
    }, 3000);
  }, []);

  return (
    <ToastContext.Provider value={{ showAlert }}>
      {children}
      <Toast message={message} opened={opened} severity={severity} />
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
