export default function formatNumber(value: number, symbol?: string, decimal = true) {
  let exp,
    suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];

  if (isNaN(value)) {
    return null;
  }

  if (value < 999999) {
    return value.toLocaleString('pt-BR');
  }

  value = Math.trunc(value);

  exp = Math.floor(Math.log(value) / Math.log(1000));

  return symbol ? symbol + ' ' : '' + truncateNumber(value / Math.pow(1000, exp), decimal ? 2 : 0) + suffixes[exp - 1];
}

export function truncateNumber(number: number, fixed: number) {
  // eslint-disable-next-line
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return number.toString().match(re)[0];
}

export function roundNumber(number: number, decimalPlaces: number) {
  return Number(number.toFixed(decimalPlaces));
}
