import { useState } from 'react';
import { useObservable } from 'react-use-observable';
import { merge } from 'rxjs';
import rxjsOperators from 'rxjs-operators';
import socketService from 'services/socket';

const useUnavailableSocket = () => {
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(true);

  useObservable(() => {
    const falseObservable = socketService.socketIsConnected().pipe(
      rxjsOperators.filter(isConnected => !isConnected),
      rxjsOperators.delay(5000)
    );
    const trueObservable = socketService.socketIsConnected().pipe(rxjsOperators.filter(isConnected => isConnected));
    const delayed = merge(falseObservable, trueObservable);
    return socketService.socketIsConnected().pipe(
      rxjsOperators.audit(() => delayed),
      rxjsOperators.tap(isConnected => setIsSocketConnected(isConnected))
    );
  }, []);

  return isSocketConnected;
};

export default useUnavailableSocket;
