import { Button, Hidden, Theme, Typography } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { WithStyles } from 'decorators/withStyles';
import React, { PureComponent } from 'react';

interface IProps {
  title: string;
  text: string;
  button?: string;
  url?: string;
  hiddenSm?: boolean;
  classes?: any;
}

interface IState {}

@WithStyles((theme: Theme) => ({
  title: {
    margin: '0 0 10px 0',
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    textTransform: 'unset',
    margin: `${theme.spacing(2)}px 0 0 0`,
    boxShadow: 'none',
    backgroundColor: theme.variables.colors['primaryPurple'],
    color: '#FFF',
    borderRadius: 50,
    fontSize: '0.8rem',
    padding: '10px 14px',
    '&:hover': {
      backgroundColor: darken(theme.variables.colors['primaryPurple'], 0.1)
    }
  },
  text: {
    fontSize: '0.75rem'
  }
}))
export default class Recommendation extends PureComponent<IProps, IState> {
  render() {
    const { classes, title, text, url, hiddenSm, button } = this.props;

    return (
      <React.Fragment>
        <Typography variant='subtitle1' className={classes.title}>
          {title}
        </Typography>
        <Typography variant='body2' align='center' className={classes.text}>
          {text}
        </Typography>
        {button && url && !hiddenSm && (
          <a href={url} target='_blank' className={classes.link} rel='noopener noreferrer'>
            <Button className={classes.button} variant='contained'>
              {button}
            </Button>
          </a>
        )}

        {button && url && hiddenSm && (
          <Hidden smDown>
            <a href={url} target='_blank' className={classes.link} rel='noopener noreferrer'>
              <Button className={classes.button} variant='contained'>
                {button}
              </Button>
            </a>
          </Hidden>
        )}
      </React.Fragment>
    );
  }
}
