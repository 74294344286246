export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const SOCKET_SERVER = process.env.REACT_APP_SOCKET_ENDPOINT;
export const EDUZZ_ACCOUNTS_PARTNER = process.env.REACT_APP_ACCOUNTS_PARTNER;
export const EDUZZ_ACCOUNTS_ENV = process.env.REACT_APP_ACCOUNTS_ENV;
export const NPS_EDUZZ = process.env.REACT_APP_NPS_URL;
export const ENV = (process.env.REACT_APP_ENV || 'production').trim();
export const IS_DEVELOPMENT = ENV !== 'production';
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
export const SNACKBAR_DEFAULT_TIMEOUT = 3000;
export const TOAST_DEFAULT_TIMEOUT = 3000;
export const TOAST_ERROR_TIMEOUT = 15000;
export const MIN_DATE_RANGE = '2019-02-05 00:00:00';
export const HOTJAR_ID = Number(process.env.REACT_APP_HOTJAR_ID);
export const HOTJAR_SNIPPET_VERSION = 6;
export const HOTJAR_SURVEY_EXPIRATION_IN_DAYS = 90;
export const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidGVsZXNjb3BlLWVkdXp6IiwiYSI6ImNrN3l3eGN4bjAwY3gzbWxidzVlb2NndGIifQ.eqaiP9cYXklCYwjs2W7_jA';

export const MAP_INITIAL_STATE_VIEW = {
  longitude: -54.0682202,
  latitude: -15.6230726,
  zoom: 2.3,
  pitch: 0,
  bearing: 0
};
