import { Theme } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import DrawerContext from 'components/contexts/DrawerContext/context';
import React, { useContext } from 'react';
import DrawerList from './DrawerList';
import DrawerOptions from './DrawerOptions';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    backgroundColor: `${theme.variables.colors['menuBackground']} !important`,
    width: 300,
    position: 'fixed',
    height: '100vh',
    marginTop: 35,
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      // safari browser fix
      height: '-webkit-fill-available'
    }
  },
  swipeableDrawer: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  desktopDrawer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const NavigationDrawer: React.FC = ({ children }) => {
  const classes = useStyles();

  const { isOpen: open, toggleDrawer } = useContext(DrawerContext);

  return (
    <nav>
      <Drawer
        variant='permanent'
        open={open}
        className={classes.desktopDrawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <DrawerList>{children}</DrawerList>
        <DrawerOptions />
      </Drawer>
      <SwipeableDrawer
        anchor={'left'}
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        className={classes.swipeableDrawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <DrawerList>{children}</DrawerList>
        <DrawerOptions />
      </SwipeableDrawer>
    </nav>
  );
};

export default NavigationDrawer;
