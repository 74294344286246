import { Divider, Theme, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper/Paper';
import { theme } from 'assets/theme';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { WithStyles } from 'decorators/withStyles';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorPieChart } from 'interfaces/indicator';
import React, { PureComponent } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import InsufficientData from '../InsufficientData';
import TooltipDescription from '../TooltipDescription';

interface IProps {
  classes?: any;
  minHeight?: number;
  elevation?: number;
  chartData?: IIndicatorPieChart;
  description?: string;
  recommendation?: React.ReactNode;
  insight?: React.ReactNode;
}

interface IState {
  showInsight: boolean;
}

@WithStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  rootInsufficientData: {
    justifyContent: 'flexStart'
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  chartWrapper: {
    height: 300
  },
  chartWrapperInsufficientData: {
    height: '100%'
  },
  title: {
    padding: '16px 16px 0 16px',
    display: 'flex',
    alignItems: 'center'
  },
  hasTooltip: {
    paddingTop: 0
  },
  datas: {
    padding: theme.spacing(1),
    background: theme.variables.colors['indicatorChartBackground'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0 0 4px 4px',
    flexWrap: 'wrap'
  },
  dataIndicator: {
    display: 'flex',
    padding: '0 10px',
    flex: 1,
    width: '100%',
    '& span:first-child': {
      fontSize: '0.875rem',
      fontWeight: 400,
      marginTop: 6,
      color: theme.variables.colors['text']
    },
    '& span:nth-child(2)': {
      display: 'flex',
      flex: 1,
      marginTop: 6,
      fontSize: '0.875rem',
      fontWeight: 400,
      color: theme.variables.colors['text']
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px'
    }
  },
  dataIndicatorPercentage: {
    display: 'flex',
    flex: 0,
    alignItems: 'center',
    '& span:first-child': {
      fontSize: '1.5rem',
      fontWeight: 600,
      margin: 0
    }
  },
  insightIcon: {
    cursor: 'pointer',
    textAlign: 'right',
    padding: `0 ${theme.spacing(1)}px`,
    '& svg': {
      fill: theme.variables.colors['tooltipIconFill'],
      width: 25
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '8px 8px 0 0'
  },
  insight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    height: '100%',
    '& svg': {
      width: '100%',
      maxWidth: 75,
      margin: '0 0 16px 0',
      fill: theme.variables.colors['tooltipIconFill']
    }
  },
  dividerContainer: {
    width: '100%',
    padding: 8
  },
  divider: {
    width: '100%',
    backgroundColor: '#ECEFF1'
  },
  pie: {
    fontSize: '1rem',
    fontWeight: 600
  }
}))
export class IndicatorPieChart extends PureComponent<IProps, IState> {
  private RADIAN = Math.PI / 180;

  constructor(props: IProps) {
    super(props);

    this.state = {
      showInsight: false
    };
  }

  checkInsufficientData = () => {
    const { chartData } = this.props;

    let total = 0;

    chartData.indicators.map(i => (total += i.value || 0));

    return total;
  };

  showHideInsight = () => {
    this.setState({ showInsight: !this.state.showInsight });
  };

  renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
    const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
    const y = cy + radius * Math.sin(-midAngle * this.RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.variables.colors['text']}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {String.fromCharCode(65 + index)}
      </text>
    );
  };

  render() {
    const { classes, minHeight, chartData, description, elevation, recommendation, insight } = this.props;
    const { showInsight } = this.state;

    return (
      <Paper className={classes.container} style={{ minHeight }} elevation={elevation || 0}>
        <div className={`${classes.root} ${this.checkInsufficientData() === 0 ? classes.rootInsufficientData : ''}`}>
          <div className={classes.header}>
            {description && (
              <div className={classes.tooltipContainer}>
                <TooltipDescription text={description} />
              </div>
            )}
            <Typography variant='subtitle1' className={`${classes.title} ${description && classes.hasTooltip}`}>
              {chartData.title}
            </Typography>
            {insight && this.checkInsufficientData() !== 0 ? (
              <div
                onClick={this.showHideInsight}
                className={classes.insightIcon}
                title={showInsight ? 'Fechar insight e voltar para o gráfico' : 'Ver mais'}
              >
                {showInsight ? <Typography variant='body2'>voltar</Typography> : <SvgIcon name='insight' />}
              </div>
            ) : null}
          </div>

          {showInsight && (
            <div className={classes.insight}>
              <SvgIcon name='insight' />
              {insight}
            </div>
          )}

          {!showInsight && (
            <React.Fragment>
              <div
                className={`${classes.chartWrapper} ${
                  this.checkInsufficientData() === 0 ? classes.chartWrapperInsufficientData : ''
                }`}
              >
                {this.checkInsufficientData() === 0 ? (
                  <InsufficientData recommendation={recommendation} />
                ) : (
                  <ResponsiveContainer width='100%'>
                    <PieChart>
                      <Pie
                        dataKey='value'
                        innerRadius={50}
                        outerRadius={75}
                        labelLine={false}
                        className={classes.pie}
                        label={this.renderLabel}
                        data={chartData.indicators}
                      >
                        {chartData.indicators.map((indicator, index) => {
                          return (
                            <Cell fill={indicator.color || theme.variables.colors[`chart${index + 1}`]} key={index} />
                          );
                        })}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </div>
              {this.checkInsufficientData() !== 0 && (
                <div className={`${classes.datas}`}>
                  {chartData.indicators &&
                    chartData.indicators.map((indicator, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={`${classes.dataIndicator}`}>
                            <span>{String.fromCharCode(65 + index)} -&nbsp;</span>
                            <span>{indicator.label}</span>
                            <div className={classes.dataIndicatorPercentage}>
                              <span style={{ color: indicator.color || theme.variables.colors[`chart${index + 1}`] }}>
                                {indicator.value ? indicator.value.toFixed(0) : 0}
                                {chartData.dataType === enDataType.percent && '%'}
                              </span>
                            </div>
                          </div>
                          {index < chartData.indicators.length - 1 && (
                            <div className={classes.dividerContainer}>
                              <Divider className={classes.divider} />
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </Paper>
    );
  }
}
