import { MapView } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
import { Typography } from '@material-ui/core';
import InsufficientData from 'components/Shared/InsufficientData';
import TooltipDescription from 'components/Shared/TooltipDescription';
import useWindowSize from 'hooks/useWindowSize';
import { ISalesByRegionality } from 'interfaces/regionality';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect } from 'react';
import { StaticMap } from 'react-map-gl';
import { MAPBOX_ACCESS_TOKEN, MAP_INITIAL_STATE_VIEW } from 'settings';
import iconAtlas from '../data/location-icon-atlas-purple.png';
import { default as iconMapping } from '../data/location-icon-mapping.json';
import useStyles from './styles';

const MAP_VIEW = new MapView({ repeat: true });

interface IProps {
  sales: ISalesByRegionality[];
}

const Map: React.FC<IProps> = ({ sales }) => {
  const classes = useStyles();
  const { width } = useWindowSize();

  const mapStyle = 'mapbox://styles/mapbox/light-v9';

  const layerProps = {
    data: sales,
    pickable: true,
    getPosition: (d: any) => d.coordinates,
    iconAtlas,
    iconMapping
  };

  const layer = new IconLayer({
    ...layerProps,
    id: 'icon',
    getIcon: () => 'marker',
    sizeScale: 10,
    sizeUnit: 'centimeters',
    sizeMinPixels: 6
  });

  const deckGLStyle = {
    position: 'relative',
    height: 'calc(100% - 37px)',
    width: width > 960 ? '100%' : 'calc(100% - 32px)',
    margin: width > 960 ? '0' : '0 auto'
  };

  const addStyles = () => {
    const style = document.createElement('style');

    style.innerHTML = `
      #view-MapView {
        position: relative !important;
      }`;

    document.head.appendChild(style);
  };

  useEffect(() => {
    addStyles();
  }, []);

  return (
    <>
      <Typography variant='subtitle1' className={classes.mapTitle}>
        Mapa de Região de Compra
        <TooltipDescription text={'Pontos de vendas realizadas, baseados no endereço de IP de seus clientes'} />
      </Typography>

      {sales.length === 0 ? (
        <InsufficientData />
      ) : (
        <DeckGL
          layers={[layer]}
          views={MAP_VIEW}
          initialViewState={MAP_INITIAL_STATE_VIEW}
          controller={{ dragRotate: false }}
          style={deckGLStyle}
        >
          <StaticMap
            reuseMaps
            mapStyle={mapStyle}
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing={true}
            width={'100%'}
            height={'100%'}
            style={{ position: 'relative' }}
          />
        </DeckGL>
      )}
    </>
  );
};

export default Map;
