import { TableCell, TableRow } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { enCountries, ITableData } from 'interfaces/regionality';
import React from 'react';
import useStyles from './styles';

interface IProps {
  arr: ITableData[];
  data: ITableData;
  onClickCountry: (name: string) => void;
  hasStates: (countryName: enCountries) => boolean;
}

const Row: React.FC<IProps> = ({ data, arr, onClickCountry, hasStates }) => {
  const classes = useStyles();

  const removeIndex = (str: string) => str.split('. ')[1];

  return (
    <TableRow className={classes.tableRow}>
      <TableCell
        component='th'
        scope='row'
        className={`${classes.cell} ${classes.tooltip}`}
        style={{
          color: hasStates(removeIndex(data.name) as enCountries) ? '#8E24AA' : 'inherit',
          cursor: hasStates(removeIndex(data.name) as enCountries) ? 'pointer' : 'default',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '8px',
          border: 'none'
        }}
        onClick={() => onClickCountry(removeIndex(data.name))}
        data-text={data.name}
      >
        {data.name}
        {hasStates(removeIndex(data.name) as enCountries) && (
          <button className={classes.viewMoreButton}>
            <AddCircleOutline />
          </button>
        )}
      </TableCell>
      <TableCell align='right' className={classes.cell} data-text={data.total}>
        {data.total}
      </TableCell>
      {!arr.some(d => d.percentage > 100) && (
        <TableCell align='right' className={classes.cell} data-text={data.percentage}>
          {data.percentage}
        </TableCell>
      )}
    </TableRow>
  );
};

export default Row;
