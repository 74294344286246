import { IUserToken } from 'interfaces/userToken';
import { createContext } from 'react';

interface IUserContext {
  user: IUserToken | null;
}

const UserContext = createContext<IUserContext>(null);

export default UserContext;
