import { enRoleAction, IUserToken } from 'interfaces/userToken';
import React, { useEffect, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import authService from 'services/auth';
import socketService from 'services/socket';
import UserContext from './context';

const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUserToken | null>(null);

  useEffect(() => {
    authService
      .getUser()
      .pipe(rxjsOperators.withLatestFrom(authService.canAccess(enRoleAction.viewUptime)), rxjsOperators.logError())
      .subscribe(([result, isAdmin]: [IUserToken, boolean]) => {
        if (!result) {
          return;
        }
        socketService.init();
        setUser({ ...result, id: isAdmin ? null : result.id });
      });
  }, []);

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
