import { createContext } from 'react';

export enum enAlertSeverity {
  success = 'success',
  error = 'error'
}

type TToastContext = {
  showAlert: (toastMessage: string, toastSeverity: enAlertSeverity) => void;
};

const ToastContext = createContext<TToastContext>({} as TToastContext);

export default ToastContext;
