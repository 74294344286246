import { FormControlLabel, makeStyles, Switch as MaterialSwitch } from '@material-ui/core';
import TooltipDescription from '../TooltipDescription';
import React, { ChangeEvent } from 'react';

const useStyles = makeStyles(theme => ({
  label: {
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));

export interface SwitchProps {
  label?: string;
  checked: boolean;
  name?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  tooltipText?: string;
  disableTooltip?: boolean;
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  label,
  name,
  handleChange,
  tooltipText,
  value,
  disableTooltip = false
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        label={label}
        className={classes.label}
        control={<MaterialSwitch checked={checked} name={name} onChange={handleChange} value={value} />}
      />
      {disableTooltip ? null : <TooltipDescription text={tooltipText} />}
    </>
  );
};

export default Switch;
