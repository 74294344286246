import { Paper, Theme, Typography } from '@material-ui/core';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { WithStyles } from 'decorators/withStyles';
import { IFilterModel } from 'interfaces/filter';
import { ISalesNotification } from 'interfaces/sales';
import { enComponent } from 'interfaces/socket';
import React from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { socketService } from 'services/socket';

// News Filtro Hoje: hideNews-001-PopUp
interface IProps {
  classes?: any;
}

interface ISales {
  total: number;
  totalValue: number;
  creditCard?: number;
  bankslip?: number;
  free?: number;
  pix?: number;
}

interface IState {
  transactions: ISalesNotification[];
  sales: ISales;
  currentFilter: IFilterModel;
}

@WithStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    position: 'absolute',
    top: 26,
    left: 'calc(100% - 590px)',
    width: 570,
    zIndex: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.34)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    animation: '$openNotification 10s',
    animationIterationCount: 'unset',
    animationFillMode: 'forwards',
    '& svg': {
      width: 48,
      fill: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 15px)',
      right: 0,
      left: 'unset',
      top: 15,
      padding: theme.spacing(1),
      margin: theme.spacing(1)
    }
  },
  '@keyframes openNotification': {
    from: { transform: 'translate(0, -150px)' },
    '10%': { transform: 'translate(0, 20px)' },
    '12%': { transform: 'translate(0, 22px)' },
    '16%': { transform: 'translate(0, 20px)' },
    '80%': { transform: 'translate(0, 20px)' },
    '85%': { transform: 'translate(0, 25px)' },
    to: { transform: 'translate(0, -150px)' }
  },
  totalSalesLabel: {
    fontWeight: 900,
    fontSize: '1rem',
    color: theme.variables.colors['text'],
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  totalSalesValue: {
    color: theme.variables.colors['success'],
    paddingLeft: 14,
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  content: {
    display: 'flex'
  },
  salesInfo: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      '& p': {
        fontSize: '0.7rem'
      }
    }
  },
  closeButton: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      '& p': {
        fontSize: '0.7rem'
      }
    }
  }
}))
export class NewSalesNotifications extends React.PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      transactions: null,
      currentFilter: null,
      sales: {
        total: 0,
        totalValue: 0
      }
    };
  }

  componentDidMount() {
    const animation = `
      @keyframes closeNotification {
        from {
          transform: translate(0, 25px);
        }
        to {
          transform: translate(0, -150px);
        }
      }
    `;

    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = animation;

    document.head.append(style);

    socketService
      .listen(enComponent.salesNotification)
      .pipe(
        rxjsOperators.filter(() => JSON.parse(localStorage.getItem('telescope_dashboardEnabledNotifications'))),
        rxjsOperators.filter((transactions: ISalesNotification[]) => !!transactions),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe((transactions: ISalesNotification[]) => {
        const currentTransactions: ISalesNotification[] = this.state.transactions || transactions;
        const newTransactions: ISalesNotification[] = transactions.filter(
          item => !currentTransactions.some(other => item.id === other.id)
        );

        const sales: ISales = {
          total: newTransactions.length,
          totalValue: newTransactions.reduce((total, t) => total + t.price, 0),
          creditCard: newTransactions.filter(t =>
            ['creditcard', 'oneclick', 'oneclickbuy', 'paypal'].includes(t.paymentMethod.toLowerCase())
          ).length,
          bankslip: newTransactions.filter(t => 'bankslip' === t.paymentMethod.toLowerCase()).length,
          free: newTransactions.filter(t => 'free' === t.paymentMethod.toLowerCase()).length,
          pix: newTransactions.filter(t => 'pix' === t.paymentMethod.toLowerCase()).length
        };

        if (sales.creditCard === 0) {
          delete sales.creditCard;
        }

        if (sales.bankslip === 0) {
          delete sales.bankslip;
        }

        if (sales.free === 0) {
          delete sales.free;
        }

        if (sales.pix === 0) {
          delete sales.pix;
        }

        this.setState({ transactions, sales });
      });

    filterService
      .getFilter()
      .pipe(
        rxjsOperators.skip(1),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this)
      )
      .subscribe(dashboardFilter => {
        const filter = JSON.parse(JSON.stringify(dashboardFilter));
        const currentFilter = JSON.parse(JSON.stringify(this.state.currentFilter));

        if (JSON.stringify(filter) !== JSON.stringify(currentFilter)) {
          this.setState({ transactions: null });
        }

        this.setState({ currentFilter: filter });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.salesNotification);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.salesNotification);
  }

  closeNotifications = () => {
    const element = document.getElementById('sales-notification-card');

    if (!element) {
      return;
    }

    element.style.animation = 'closeNotification 1s';
    element.style.animationFillMode = 'forwards';
  };

  render() {
    const { classes } = this.props;
    const { sales, transactions } = this.state;

    if (!transactions || sales.total === 0) {
      return null;
    }

    return (
      <Paper className={classes.root} id='sales-notification-card' key={transactions[0].id}>
        <div className={classes.content}>
          <SvgIcon name='saleNotification' />
          <div className={classes.salesInfo}>
            <Typography variant='subtitle1' className={classes.totalSalesLabel}>
              {sales.total === 1 ? '1 Venda Realizada' : `${sales.total} Vendas Realizadas`}
              <span className={classes.totalSalesValue}>R$ {sales.totalValue.toFixed(2)}</span>
            </Typography>

            {sales.creditCard && (
              <Typography variant='body2'>{sales.creditCard} através de cartão de crédito</Typography>
            )}

            {sales.bankslip && <Typography variant='body2'>{sales.bankslip} através de boleto</Typography>}

            {sales.pix && <Typography variant='body2'>{sales.pix} através de PIX</Typography>}

            {sales.free && (
              <Typography variant='body2'>{sales.free === 1 ? '1 gratuito' : `${sales.free} gratuitos`}</Typography>
            )}
          </div>
        </div>
        <div className={classes.closeButton}>
          <Typography variant='body2' onClick={this.closeNotifications}>
            fechar
          </Typography>
        </div>
      </Paper>
    );
  }
}
