import React, { useState } from 'react';
import DrawerContext from './context';

const DrawerProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsOpen(prevValue => !prevValue);
  };

  return <DrawerContext.Provider value={{ isOpen, toggleDrawer }}>{children}</DrawerContext.Provider>;
};

export default DrawerProvider;
