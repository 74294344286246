/* eslint-disable react/jsx-no-bind */
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { KeyboardArrowDown } from '@material-ui/icons';
import { theme } from 'assets/theme';
import { roundNumber } from 'helpers/formatNumber';
import { IHorizontalBarChart } from 'interfaces/chart';
import { enDataType } from 'interfaces/dashboard';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import InsufficientData from '../InsufficientData';
import { SvgIcon } from '../SvgIcon';
import TooltipDescription from '../TooltipDescription';
import useStyles from './styles';

interface IProps {
  chartData: IHorizontalBarChart;
  highlightColor?: string;
  elevation?: number;
  description?: string;
  showOptions: boolean;
  enableSwitch?: boolean;
  defaultDataType?: enDataType;
}

const HorizontalBarChart: React.FC<IProps> = ({
  chartData,
  showOptions,
  defaultDataType,
  elevation,
  enableSwitch,
  description,
  highlightColor
}) => {
  const classes = useStyles();

  const [option, setOption] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [dataType, setDataType] = useState<enDataType>(defaultDataType ? defaultDataType : enDataType.numeric);

  const changeDataType = useCallback((type: enDataType) => {
    setDataType(type);
  }, []);

  const handleChangeOption = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setOption(Number(event.target.value));
  };

  const calcTotal = useCallback(() => {
    const mapTotals = chartData.indicators[option].data.map(data => data.total);

    return mapTotals.reduce((prevValue, currentValue) => prevValue + currentValue, 0);
  }, [chartData.indicators, option]);

  const calcProgress = useCallback(
    (value: number) => {
      return ((value / total) * 100).toFixed(2);
    },
    [total]
  );

  useEffect(() => {
    const result = calcTotal();

    setTotal(result);
  }, [calcTotal, option]);

  return (
    <Paper elevation={elevation}>
      <div className={classes.root}>
        <Typography variant='subtitle1' className={classes.title}>
          {description && (
            <div className={classes.tooltipContainer}>
              <TooltipDescription text={description} />
            </div>
          )}
          {chartData.title}
        </Typography>
        {enableSwitch && (
          <div className={classes.switchUnitData}>
            <div
              className={dataType === enDataType.numeric ? classes.switchUnitDataActive : ''}
              onClick={() => changeDataType(enDataType.numeric)}
            >
              n°
            </div>
            <div
              className={dataType === enDataType.percent ? classes.switchUnitDataActive : ''}
              onClick={() => changeDataType(enDataType.percent)}
            >
              %
            </div>
          </div>
        )}
      </div>
      <div className={classes.selectContainer}>
        {showOptions ? (
          <Select
            value={option}
            onChange={e => handleChangeOption(e)}
            className={classes.select}
            name='options'
            IconComponent={KeyboardArrowDown}
            disableUnderline
          >
            {chartData.indicators.map((indicator, index) => (
              <MenuItem value={index} key={`horizontal-bar-chart-option-${indicator.optionName}`}>
                <span className={classes.selectOption}>
                  <SvgIcon name='parameters' /> {indicator.optionName}
                </span>
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </div>
      <div>
        {total === 0 && <InsufficientData />}
        {chartData.indicators[option].data.map((data, index) => (
          <div key={data.label} className={classes.bar} data-text={data.label && data.label}>
            <div className={classes.barData}>
              <div>
                <div className={classes.label}>
                  {!!data.label && data.label.toString().toLowerCase() === 'nutror' ? (
                    <SvgIcon name='nutror' />
                  ) : (
                    data.label
                  )}
                </div>
                <div>
                  <Typography variant='body1' className={classes.value}>
                    {dataType === enDataType.numeric && data.total && roundNumber(data.total, 1)}
                    {dataType === enDataType.percent && data.percentage && `${roundNumber(data.percentage, 1)}%`}
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={classes.barProgress}
              id={`progressBar-${index}`}
              style={{
                width: `${data.total ? calcProgress(data.total) : data.percentage}%`,
                backgroundColor: `${
                  index === 0 ? highlightColor || theme.variables.colors['horizontalBarChartPrimaryColor'] : ''
                }`
              }}
            />
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default HorizontalBarChart;
