import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { ICreditCardApprovalFee } from 'interfaces/creditCardApprovalFee';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const CreditCardApprovalFee = () => {
  const [previousCreditCardApprovalFee, setPreviousCreditCardApprovalFee] = useState(0);

  useEffect(() => {
    dashboardService.addComponent(enComponent.creditCardApprovalFee);

    return () => {
      dashboardService.removeComponent(enComponent.creditCardApprovalFee);
    };
  }, []);

  const [creditCardApprovalFee] = useObservable<ICreditCardApprovalFee>(() => {
    return socketService.listen(enComponent.creditCardApprovalFee).pipe(
      rxjsOperators.filter((approvalFee: ICreditCardApprovalFee) => !!approvalFee),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(caf => {
        const creditCardApprovalFee: ICreditCardApprovalFee = {
          creditCardApprovalFee: 0,
          performance: 0
        };

        setPreviousCreditCardApprovalFee(creditCardApprovalFee.creditCardApprovalFee);

        creditCardApprovalFee.creditCardApprovalFee = caf.creditCardApprovalFee;
        creditCardApprovalFee.performance = caf.performance;

        return creditCardApprovalFee;
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Taxa de aprovação de cartão considerando todas as recusas',
        value: creditCardApprovalFee?.creditCardApprovalFee,
        dataType: enDataType.percent,
        icon: 'creditCardIndicatorPanel'
      }}
      oldIndicatorDataValue={previousCreditCardApprovalFee}
      description='Este percentual mostra a aceitação do cartão de crédito nas instituições financeiras considerando todas as recusas de cartão do cliente'
    >
      <MetricPerformance performance={creditCardApprovalFee?.performance || 0} />
    </IndicatorPanel>
  );
};

export default CreditCardApprovalFee;
