import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Theme
} from '@material-ui/core';
import { WithStyles } from 'decorators/withStyles';
import * as React from 'react';
import { AlertGlobalProvider } from './global';

interface IState {
  opened: boolean;
  message?: React.ReactNode;
  title?: string;
  confirmation?: boolean;
  hideCancelButton?: boolean;
}

interface IProps {
  opened: boolean;
  message: React.ReactNode;
  title?: string;
  confirmation?: boolean;
  positiveButtonText?: string;
  negativeButtonText?: string;
  hideCancelButton?: boolean;
  confirmOnClickOutside?: boolean;
  global?: boolean;
  onClose: (ok: boolean) => void;
  classes?: any;
}

export interface IAlertShowParams {
  message?: React.ReactNode;
  title?: string;
  confirmation?: boolean;
  positiveButtonText?: string;
  negativeButtonText?: string;
  hideCancelButton?: boolean;
  confirmOnClickOutside?: boolean;
  onClose?: (ok: boolean) => void;
}

@WithStyles((theme: Theme) => ({
  root: {
    '& div:nth-child(2)': {
      backgroundColor: '#FFF',
      maxWidth: '440px',
      padding: '0px'
    }
  },
  wrapperContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '32px'
  },
  content: {
    fontWeight: 400,
    width: '100%',
    padding: '16px',
    color: theme.variables.colors['text'],
    textAlign: 'center'
  },
  paper: {
    backgroundColor: '#FFF'
  },
  button: {
    backgroundColor: theme.variables.colors['primaryPurple'],
    color: '#FFF',
    width: 'auto',
    minWidth: 57,
    padding: '10px',
    borderRadius: 4,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.variables.colors['primaryPurple'],
      color: '#FFF',
      opacity: 0.8
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['primaryPurple'],
    minWidth: 57,
    padding: '10px',
    borderRadius: 4,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'rgba(142, 36, 170, 0.1)',
      color: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    }
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '16px',
    marginTop: '16px'
  }
}))
export default class Alert extends React.Component<IProps, IState> {
  static Global = AlertGlobalProvider;

  constructor(props: IProps) {
    super(props);
    this.state = { opened: false };
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    // prevent any changes before close the dialog. ex: title, message e etc
    if (!nextProps.opened) {
      return { ...prevState, opened: false };
    }

    return nextProps;
  }

  static show(params: string): Promise<boolean>;
  static show(params: IAlertShowParams): Promise<boolean>;
  static show(params: string | IAlertShowParams) {
    const paramsData = typeof params === 'string' ? { message: params } : params;
    return AlertGlobalProvider.show(paramsData);
  }

  static confirm(params: string): Promise<boolean>;
  static confirm(params: IAlertShowParams): Promise<boolean>;
  static confirm(params: string | IAlertShowParams) {
    const paramsData = typeof params === 'string' ? { message: params } : params;
    return AlertGlobalProvider.show({ ...paramsData, confirmation: true });
  }

  handleOk = () => {
    this.props.onClose(true);
  };

  handleCancel = () => {
    this.props.onClose(false);
  };

  onClickOutside = () => {
    this.props.onClose(this.props.confirmOnClickOutside);
  };

  render() {
    const { opened, title, message, confirmation, hideCancelButton } = this.state;
    const { classes, positiveButtonText, negativeButtonText } = this.props;

    return (
      <Dialog
        keepMounted
        open={opened}
        style={{ zIndex: 1600 }}
        className={classes.root}
        onClose={this.onClickOutside}
        TransitionComponent={Transition}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>{title || (confirmation ? 'Confirmação' : 'Atenção')}</DialogTitle>
        <DialogContent className={classes.wrapperContent}>
          <DialogContentText variant='subtitle1' className={classes.content}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {confirmation && !hideCancelButton && (
            <Button onClick={this.handleCancel} autoFocus className={`${classes.clearButton}`}>
              {negativeButtonText || 'Cancelar'}
            </Button>
          )}
          <Button autoFocus={!confirmation} onClick={this.handleOk} className={`${classes.button}`}>
            {positiveButtonText || 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function Transition(props: any) {
  return <Slide direction='up' {...props} />;
}
