import { IndicatorPieChart } from 'components/Shared/PieChart';
import Recommendation from 'components/Shared/Recommendation';
import { ICustomSalesTemplates } from 'interfaces/customSalesTemplates';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
}

export default class CustomSalesTemplate extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.customSalesTemplates)
      .pipe(
        rxjsOperators.filter((templates: ICustomSalesTemplates[]) => !!templates),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(templates => {
        const keys = ['retro', 'hotsite', 'widget'];
        const indicatorsPieChart: IIndicatorChart[] = [];

        const labels = {
          retro: 'Padrão',
          hotsite: 'Hotsite',
          widget: 'Widget'
        };

        let sum = 0;
        keys.forEach((t, index)=> {
          const template = templates.find(template => template.template === t);

          if (!template) {
            return;
          }

          let percentage = Math.round(template.percentage);
          sum += percentage;
          if (index === keys.length - 1) {
            percentage += 100 - sum;
          }
          indicatorsPieChart.push({
            label: labels[t],
            value: percentage
          });
        });

        this.setState({ indicatorsPieChart });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.customSalesTemplates);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.customSalesTemplates);
  }

  render() {
    const { indicatorsPieChart } = this.state;

    return (
      <IndicatorPieChart
        chartData={{
          title: 'Vendas com Customização',
          dataType: enDataType.percent,
          indicators: indicatorsPieChart
        }}
        description={'Porcentagem de vendas que passaram por um Checkout personalizado no momento da venda'}
        recommendation={
          <Recommendation
            title='Você sabia?'
            text='A customização de um Checkout é um dos fatores decisivos no momento da venda. Uma boa customização traz clareza e gera segurança no momento de decisão da compra.'
            button='Quero personalizar meu Checkout'
            url='https://adminsun.eduzz.com'
            hiddenSm
          />
        }
        insight={
          <Recommendation
            title='Você sabia?'
            text='A customização de um Checkout é um dos fatores decisivos no momento da venda. Uma boa customização traz clareza e gera segurança no momento de decisão da compra.'
            button='Quero personalizar meu Checkout'
            url='https://adminsun.eduzz.com'
            hiddenSm
          />
        }
        elevation={2}
      />
    );
  }
}
