import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    title: {
      display: 'flex',
      alignItems: 'center'
    },
    alignTitleRight: {
      justifyContent: 'flex-end'
    },
    data: {
      marginTop: 15
    },
    valueGrid: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        alignContent: 'center'
      }
    },
    alignValuesRight: {
      justifyContent: 'flex-end',
      textAlign: 'right'
    },
    valueContent: {
      maxWidth: '100%',
      zIndex: 2
    },
    value: {
      fontSize: '2rem',
      fontWeight: 600
    },
    rangeTime: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      color: theme.variables.colors['rangeTime']
    },
    chartWrapper: {
      marginRight: 24
    }
  })
);

export default useStyles;
