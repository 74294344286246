import { enComponent } from 'interfaces/socket';
import * as rxjs from 'rxjs';
import rxjsOperators from 'rxjs-operators';

export class DashboardService {
  private loadedComponents$: rxjs.BehaviorSubject<enComponent[]>;

  constructor() {
    this.init();
  }

  init() {
    this.loadedComponents$ = new rxjs.BehaviorSubject([]);
  }

  public addComponent(component: enComponent) {
    this.loadedComponents$.pipe(rxjsOperators.take(1)).subscribe((loadedComponents: enComponent[]) => {
      const newloadedComponent = loadedComponents;

      // Add component to the array if it doesn't exist
      if (newloadedComponent.indexOf(component) === -1) {
        newloadedComponent.push(component);
        this.loadedComponents$.next(newloadedComponent);
      }
    });
  }

  public removeComponent(component: enComponent) {
    this.loadedComponents$.pipe(rxjsOperators.take(1)).subscribe((loadedComponent: enComponent[]) => {
      const newloadedComponent = loadedComponent;
      const index = newloadedComponent.indexOf(component);

      // Remove component to the array if it exist
      if (index > -1) {
        newloadedComponent.splice(index, 1);
        this.loadedComponents$.next(newloadedComponent);
      }
    });
  }

  public getLoadedComponents(): rxjs.Observable<enComponent[]> {
    return this.loadedComponents$.pipe(rxjsOperators.distinctUntilChanged());
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
