import { HotjarEvents } from 'interfaces/hotjar';
import { IUserToken } from 'interfaces/userToken';
import React, { useCallback, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import rxjsOperators from 'rxjs-operators';
import authService from 'services/auth';
import NpsService from 'services/npsService';
import tokenService from 'services/token';
import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION, HOTJAR_SURVEY_EXPIRATION_IN_DAYS } from 'settings';

const Hotjar: React.FC = () => {
  const [clientId, setClientId] = useState<number | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const handleShouldShowSurvey = useCallback(async () => {
    const npsService = new NpsService();
    const lastAnswer = await npsService.getSurveyLastAnswer(clientId, token);

    if (!lastAnswer) return;

    const isSurveyExpired = lastAnswer.answered && lastAnswer.days_count >= HOTJAR_SURVEY_EXPIRATION_IN_DAYS;
    const shouldShowSurvey = !lastAnswer.answered || isSurveyExpired;
    if (shouldShowSurvey) {
      submitEvent(HotjarEvents.TRANSFER_REQUEST_MADE);
    }
  }, [clientId, token]);

  useEffect(() => {
    authService
      .getUser()
      .pipe(rxjsOperators.logError())
      .subscribe((result: IUserToken) => {
        if (!result) {
          return;
        }
        setClientId(result.id);
      });
  }, []);

  useEffect(() => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
  }, []);

  useEffect(() => {
    tokenService.getToken().subscribe(clientToken => setToken(clientToken));
  }, []);

  useEffect(() => {
    if (!clientId || !token) return;
    handleShouldShowSurvey();
  }, [clientId, token, handleShouldShowSurvey]);

  const submitEvent = (event: HotjarEvents) => {
    hotjar.event(event);
  };

  return null;
};

export default Hotjar;
