import Grid from '@material-ui/core/Grid';
import LiveReloadFeedback from 'components/Shared/LiveReloadFeedback';
import MetricsCards from 'components/Shared/MetricsCards';
import OfflineSocket from 'components/Shared/OfflineSocket';
import useInactivateSocket from 'hooks/useInactivateSocket';
import useUnavailableSocket from 'hooks/useUnavailableSocket';
import React from 'react';

const Metrics: React.FC = () => {
  const isConnected = useUnavailableSocket();

  useInactivateSocket();

  return (
    <>
      {!isConnected ? (
        <Grid item xs={12}>
          <OfflineSocket />
        </Grid>
      ) : (
        <>
          <LiveReloadFeedback />
          <MetricsCards />
        </>
      )}
    </>
  );
};

export default Metrics;
