import { Paper, Theme, Typography } from '@material-ui/core';
import { WithStyles } from 'decorators/withStyles';
import { dateFormat } from 'formatters/date';
import getWeekDay from 'helpers/getWeekDay';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { SvgIcon } from '../../Shared/SvgIcon';

interface IProps {
  payload?: ITooltip[];
  classes?: any;
}

interface ITooltip {
  color: string;
  fill: string;
  value: number;
  name: string;
  payload: {
    bankslip: number;
    creditCard: number;
    date1: string;
    date2: string;
  };
}

@WithStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    width: 125
  },
  values: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 0',
    '& svg': {
      width: 30,
      marginRight: 12
    }
  },
  total: {
    fontSize: 24
  }
}))
export default class CustomTooltip extends PureComponent<IProps, {}> {
  getTotal = () => {
    let total = 0;

    if (!this.props.payload) {
      return total;
    }

    this.props.payload.forEach((payload: ITooltip) => {
      total += payload.value;
    });

    return total.toLocaleString('pt-BR');
  };

  render() {
    const { classes } = this.props;
    const date = get(this.props, 'payload[0].payload.date1', null);

    return (
      <Paper className={classes.root}>
        <Typography variant='body2'>
          {getWeekDay(new Date(date).getDay())}, <br />
          {dateFormat(new Date(date), 'dd/MM/yyyy HH:mm')}
        </Typography>
        <Typography variant='subtitle1' className={classes.total}>
          {this.getTotal()}
        </Typography>
        {this.props.payload
          ? this.props.payload.map((payload: ITooltip, index: number) => (
              <div className={classes.values} key={index}>
                <span style={{ fill: payload.color }}>
                  <SvgIcon name={payload.name} />
                </span>
                <Typography variant='body2' style={{ color: payload.color }}>
                  {payload.value.toLocaleString('pt-BR')}
                </Typography>
              </div>
            ))
          : null}
      </Paper>
    );
  }
}
