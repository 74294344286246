import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { enDataType } from 'interfaces/dashboard';
import { IBilling } from 'interfaces/grossBilling';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const GrossBilling = () => {
  const [previousGrossBilling, setPreviousGrossBilling] = useState(0);

  useEffect(() => {
    dashboardService.addComponent(enComponent.grossBilling);

    return () => {
      dashboardService.removeComponent(enComponent.grossBilling);
    };
  }, []);

  const [grossBilling] = useObservable<IBilling>(() => {
    return socketService.listen(enComponent.grossBilling).pipe(
      rxjsOperators.filter((billing: IBilling) => !!billing),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(gb => {
        const grossBilling: IBilling = {
          billing: 0,
          performance: 0
        };

        setPreviousGrossBilling(grossBilling.billing);

        grossBilling.billing = gb.billing;
        grossBilling.performance = gb.performance;

        return grossBilling;
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Qual é o meu faturamento bruto total?',
        value: grossBilling?.billing,
        dataType: enDataType.currency
      }}
      oldIndicatorDataValue={previousGrossBilling}
      description='Valor total bruto de faturamento, sem contabilizar descontos de taxas e afins'
    >
      <MetricPerformance performance={grossBilling?.performance || 0} />
    </IndicatorPanel>
  );
};

export default GrossBilling;
