import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  chart: {
    width: '100%',
    padding: '12px 48px',

    [theme.breakpoints.down('sm')]: {
      padding: 12
    }
  },
  chartWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '400px',

    [theme.breakpoints.down('sm')]: {
      gap: '12px',
      flexDirection: 'column'
    }
  },
  legend: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 24px',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: '0 12px'
    }
  }
}));

export default useStyles;
