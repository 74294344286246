import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { SvgIcon } from '../SvgIcon';

const OfflineSocket: React.FC = () => {
  return (
    <Paper elevation={2} style={{ padding: '1rem' }}>
      <div style={{ maxWidth: '500px', margin: '0 auto' }}>
        <SvgIcon name='insufficientData' />
      </div>
      <Typography variant='h5' align='center' style={{ marginTop: '1rem', marginBottom: '.25rem' }}>
        Seus dados estão indisponíveis no momento
      </Typography>
      <Typography variant='body2' align='center'>
        Aguarde alguns minutos e atualize a página.
      </Typography>
    </Paper>
  );
};

export default OfflineSocket;
