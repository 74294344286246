import { Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback } from 'react';
import { useObservable } from 'react-use-observable';
import { map } from 'rxjs/operators';
import authService from 'services/auth';
import loginService from 'services/login';

const useStyles = makeStyles((theme: Theme) => ({
  options: {
    padding: 30,
    position: 'absolute',
    bottom: 35
  },
  avatar: {
    backgroundColor: theme.variables.colors['avatar'],
    color: theme.variables.colors['tableTextColor'],
    fontFamily: 'Lato',
    marginRight: 16,
    height: 30,
    width: 30,
    fontSize: '0.75rem',
    fontWeight: 700
  },
  myAccount: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  myAccountText: {
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    lineHeight: '150%'
  }
}));

const DrawerOptions = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [userName] = useObservable(() => {
    return authService.getUser().pipe(map(user => user.name));
  }, []);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const logout = useCallback(() => {
    setAnchorEl(null);
    loginService.logout();
  }, []);

  return (
    <div className={classes.options}>
      <div className={classes.myAccount} onClick={handleClick}>
        <Avatar className={classes.avatar}>{!!userName && userName[0]}</Avatar>
        <Typography variant='body1' className={classes.myAccountText}>
          Minha Conta
        </Typography>
      </div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            minWidth: 150
          }
        }}
      >
        <MenuItem onClick={logout}>Sair</MenuItem>
      </Menu>
    </div>
  );
};

export default DrawerOptions;
