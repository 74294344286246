import { isJSON } from 'helpers/isJson';
import { enComponent } from 'interfaces/socket';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import rxjsOperators from 'rxjs-operators';
import authService from 'services/auth';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { IS_DEVELOPMENT, SOCKET_SERVER } from 'settings';
import io, { Socket } from 'socket.io-client';
import { IFilterModel } from '../interfaces/filter';
import tokenService from './token';

const componentsToResetOnChange = [
  enComponent.orderBumpBilling,
  enComponent.grossBilling,
  enComponent.noLimitInstallments,
  enComponent.cancellations,
  enComponent.pendingSales,
  enComponent.creditCardApprovalFee,
  enComponent.bankslipConversion,
  enComponent.conversionFunnel,
  enComponent.checkoutGeneralConversion,
  enComponent.cardRefusalsCount,
  enComponent.transactionsTable,
  enComponent.chargebackRates
];

export class SocketService {
  private socket: Socket;
  private listeners: {} = [];
  private watchDashboardSubscription: Subscription = null;
  private socketIsConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private socketIsActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isActive = false;

  init() {
    tokenService
      .getToken()
      .pipe(
        rxjsOperators.filter((token: string) => !!token),
        rxjsOperators.logError()
      )
      .subscribe((token: string) => {
        if (this.socket) {
          this.socket.connect();
          return;
        }

        this.socket = io(SOCKET_SERVER, { query: { token } });

        this.socket.on('disconnect', () => {
          this.socketIsConnected$.next(false);
          this.socketIsActive$.next(false);

          console.log('Socket disconnected');
        });

        this.socket.on('welcome', () => {
          this.listenExceptions();
          this.socketIsConnected$.next(true);
          this.socketIsActive$.next(this.isActive);
          if (!this.isActive) {
            this.inactivate();
          }
          console.log('Socket connected');
        });

        this.socket.on('isActive', (isActive: boolean) => {
          if (IS_DEVELOPMENT) console.log('isActive', isActive);
          this.socketIsActive$.next(isActive);
        });

        this.listeners = [];
      });
  }

  public startWatchDashboard() {
    this.watchDashboardSubscription = this.socketIsConnected()
      .pipe(
        rxjsOperators.filter(socketIsConnected => !!socketIsConnected),
        rxjsOperators.switchMap(() => filterService.getFilter()),
        rxjsOperators.filter((filter: IFilterModel) => !!filter),
        rxjsOperators.combineLatest(dashboardService.getLoadedComponents()),
        rxjsOperators.logError()
      )
      .subscribe(([filter, loadedComponents]) => {
        console.log('Start Watch Dashboard');
        componentsToResetOnChange.forEach((component: enComponent) => {
          if (component === enComponent.chargebackRates) {
            return this.listeners[component]?.next({});
          }
          this.listeners[component]?.next(1);
        });
        const payload = JSON.stringify({
          loadedComponents,
          filter,
          version: '3.9.0'
        });
        this.emit(
          'startWatchDashboard',
          payload
        );
      });
  }

  public socketIsConnected() {
    return this.socketIsConnected$.asObservable().pipe(rxjsOperators.distinctUntilChanged());
  }

  public socketIsActive() {
    return this.socketIsActive$.asObservable().pipe(rxjsOperators.distinctUntilChanged());
  }

  public clearWatchDashboardSubscription() {
    if (!this.watchDashboardSubscription) {
      return;
    }

    this.watchDashboardSubscription.unsubscribe();
    this.watchDashboardSubscription = null;
  }

  public stopWatchDashboard() {
    this.clearWatchDashboardSubscription();
    this.emit('stopWatchDashboard', null);
  }

  public listen<T>(event: string): Observable<T> {
    if (!this.socket) {
      return of(null);
    }

    if (!this.listeners[event]) {
      const data$ = new Subject<T>();

      setTimeout(() => {
        this.socket.on(event, (msg: string) => {
          if (isJSON(msg)) {
            const data: T = JSON.parse(msg);
            data$.next(data);
            filterService.showLoadingFilter(false);
          }
        });
      }, 100);

      this.listeners[event] = data$;
    }

    return this.listeners[event].asObservable();
  }

  public emit(event: string, args: any) {
    if (!this.socket) {
      return;
    }

    this.socket.emit(event, args);
  }

  public disconnect() {
    this.socket.disconnect();
  }

  private listenExceptions() {
    if (!this.socket) {
      return;
    }

    this.socket.on('exception', (msg: string) => {
      console.error('Socket Exception:', msg);
    });

    this.socket.on('unauthorized', (msg: string) => {
      console.error('Unauthorized', msg);
      authService.logout();
    });
  }

  public activate() {
    this.isActive = true;
    this.emit('activate', {});
  }

  public inactivate() {
    this.isActive = false;
    this.emit('inactivate', {});
  }
}

export const SocketServiceFactory = () => new SocketService();

export const socketService = SocketServiceFactory();
export default socketService;
