import { makeStyles, Theme } from '@material-ui/core';

const useTableStyles = makeStyles((theme: Theme) => ({
  columnTitle: {
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.variables.colors['tableTextColor'],
    margin: '0 12px'
  },
  data: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    color: theme.variables.colors['text'],
    lineHeight: '150%',
    margin: '0 12px',
    '& svg': {
      height: 20,
      marginRight: 12
    }
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      minWidth: 65
    }
  },
  breakContent: {
    whiteSpace: 'normal',
    textOverflow: 'unset',
    minWidth: 0
  },
  row: {
    borderBottom: '1px solid #ECEFF1',
    width: '100%',
    display: 'flex',
    padding: '8px 0',
    '&:hover': {
      backgroundColor: '#FFF',
      boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.12)',
      cursor: 'pointer'
    }
  },
  alignRight: {
    textAlign: 'right',
    justifyContent: 'flex-end'
  },
  hideFieldInMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default useTableStyles;
