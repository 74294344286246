import { Grid, Paper, Theme } from '@material-ui/core';
import BlockedData from 'components/Shared/BlockedData';
import HorizontalBarChart from 'components/Shared/HorizontalBarChart';
import { WithStyles } from 'decorators/withStyles';
import { ISourceSaleAffiliates } from 'interfaces/affiliatesSales';
import { enDataType } from 'interfaces/dashboard';
import { enViewAs } from 'interfaces/filter';
import { IIndicatorChart, IIndicatorHorizontalBarChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { socketService } from 'services/socket';
import { IndicatorPieChart } from '../../Shared/PieChart';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
  indicatorsBarChart: IIndicatorHorizontalBarChart[];
  viewAsAffiliate: boolean;
}

@WithStyles((theme: Theme) => ({
  container: {
    height: '100%'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column'
    }
  },
  divider: {
    width: 1,
    borderLeft: 'solid 1px',
    color: theme.variables.colors['indicatorChartSeparator'],
    '@media screen and (max-width: 960px)': {
      width: '100%',
      height: 1,
      borderTop: 'solid 1px'
    }
  }
}))
export default class AffiliateSales extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }],
      indicatorsBarChart: [{ data: [] }],
      viewAsAffiliate: false
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.affiliateSales)
      .pipe(
        rxjsOperators.filter((affiliatesSales: ISourceSaleAffiliates) => !!affiliatesSales),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(sourceSalesAffiliates => {
        this.setSourceSales(sourceSalesAffiliates);
        this.setTopAffiliates(sourceSalesAffiliates);
      });

    filterService
      .getFilter()
      .pipe(
        rxjsOperators.map(filter => filter.viewAs),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(viewAs => {
        this.setState({ viewAsAffiliate: viewAs === enViewAs.affiliate });
      });
  }

  setSourceSales = (sourceSalesAffiliates: ISourceSaleAffiliates) => {
    const salesOrigin = Object.keys(sourceSalesAffiliates.sourceSales);
    const indicators: IIndicatorChart[] = [];

    const labels = {
      affiliatesSales: 'Afiliado',
      salesWithoutAffiliates: 'Produtor'
    };

    salesOrigin.forEach((sale: string) => {
      if (!!sourceSalesAffiliates.sourceSales[sale].percentage) {
        indicators.push({
          label: labels[sale],
          value: sourceSalesAffiliates.sourceSales[sale].percentage
        });
      }
    });

    this.setState({ indicatorsPieChart: indicators.reverse() });
  };

  setTopAffiliates = (sourceSalesAffiliates: ISourceSaleAffiliates) => {
    const indicatorsBarChart: IIndicatorHorizontalBarChart[] = [{ data: [] }];

    sourceSalesAffiliates.topAffiliates.forEach(a => {
      indicatorsBarChart[0].data.push({
        label: a.affiliate,
        total: a.value.total,
        percentage: a.value.percentage
      });
    });

    this.setState({ indicatorsBarChart });
  };

  componentWillMount() {
    dashboardService.addComponent(enComponent.affiliateSales);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.affiliateSales);
  }

  render() {
    const { classes } = this.props;
    const { indicatorsPieChart, indicatorsBarChart, viewAsAffiliate } = this.state;

    if (viewAsAffiliate) {
      return (
        <div className={classes.container}>
          <Paper className={classes.root}>
            <Grid item xs={12}>
              <BlockedData />
            </Grid>
          </Paper>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={2}>
          <Grid item xs={12} md={12} lg={6}>
            <IndicatorPieChart
              chartData={{
                title: 'Vendido por',
                dataType: enDataType.percent,
                indicators: indicatorsPieChart
              }}
              description='Percentual de vendas feitas por produtor e afiliado'
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item xs={12} md={12} lg={6}>
            <HorizontalBarChart
              chartData={{
                title: 'Vendedores',
                dataType: enDataType.numeric,
                indicators: indicatorsBarChart
              }}
              elevation={0}
              showOptions={false}
              enableSwitch
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}
