import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    tooltipContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    selectContainer: {
      margin: '0 10px',
      flex: 1
    },
    select: {
      width: '100%'
    },
    bar: {
      backgroundColor: theme.variables.colors['horizontalBarChartBackground'],
      margin: '12px 16px',
      borderRadius: '50px',
      height: 30,
      position: 'relative',
      overflow: 'hidden',
      transition: 'all .2s linear',

      '&:hover, &:focus': {
        overflow: 'inherit',
        zIndex: 2
      },

      '&:focus:after, &:hover:after': {
        content: 'attr(data-text)',
        fontSize: '0.75rem',
        background: '#fff',
        position: 'absolute',
        border: '1px solid #eaebec',
        borderRadius: '4px',
        padding: '0 .5rem',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,.28)',
        marginTop: '-1.75rem'
      }
    },
    barProgress: {
      borderRadius: '50px',
      height: 30,
      backgroundColor: theme.variables.colors['horizontalBarChartSecondaryColor'],
      width: '0',
      '&#progressBar-0': {
        backgroundColor: theme.variables.colors['horizontalBarChartPrimaryColor']
      }
    },
    barData: {
      position: 'absolute',
      width: '100%',
      padding: '6px 12px',
      height: 30,
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#1A1836'
      }
    },
    label: {
      maxWidth: '85%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 25,
      whiteSpace: 'nowrap',
      color: theme.variables.colors['horizontalBarChartText'],
      fontSize: '0.75rem',
      '& svg': {
        height: 20
      }
    },
    value: {
      color: theme.variables.colors['horizontalBarChartText'],
      fontSize: '0.75rem'
    },
    switchUnitData: {
      backgroundColor: theme.variables.colors['disabledSwitch'],
      border: `1px solid ${theme.variables.colors['switchBorder']}`,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.variables.colors['text'],
      cursor: 'pointer',
      overflow: 'hidden',
      marginTop: 8,
      fontSize: '0.75rem',
      fontWeight: 700,
      '& div': {
        width: 25,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    switchUnitDataActive: {
      backgroundColor: theme.variables.colors['activeSwitch'],
      color: '#FFF',
      fontSize: '0.75rem',
      fontWeight: 700
    },
    selectOption: {
      display: 'flex',
      '& svg': {
        marginRight: 15,
        width: 20,
        fill: theme.variables.colors['primaryPurple']
      }
    }
  })
);

export default useStyles;
