export default function formatData(value: string | number, type: string): string | number {
  if (type === 'currency') {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(
      Number(value)
    );
  }

  if (type === 'percentage') {
    return `${value}%`;
  }

  return value;
}
