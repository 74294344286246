import { IndicatorPieChart } from 'components/Shared/PieChart';
import { ICreditCardInstallments } from 'interfaces/creditCardPaymentInstallments';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import React, { Component } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
}

export default class CreditCardPaymentInstallments extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.creditCardInstallments)
      .pipe(
        rxjsOperators.filter(
          (creditCardPaymentInstallments: ICreditCardInstallments) => !!creditCardPaymentInstallments
        ),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(creditCardPaymentInstallments => {
        this.setCreditCardPaymentInstallments(creditCardPaymentInstallments);
      });
  }

  setCreditCardPaymentInstallments = (creditCardPaymentInstallments: ICreditCardInstallments) => {
    const paymentInstallments = Object.keys(creditCardPaymentInstallments.creditCardPayment);
    const indicatorsPieChart: IIndicatorChart[] = [];

    const labels = {
      withoutInstallments: 'À vista',
      multipleInstallments: 'Parcelado'
    };

    paymentInstallments.forEach((pi: string) => {
      if (creditCardPaymentInstallments.creditCardPayment[pi]) {
        indicatorsPieChart.push({
          label: labels[pi],
          value: creditCardPaymentInstallments.creditCardPayment[pi]
        });
      }
    });

    this.setState({ indicatorsPieChart });
  };

  componentWillMount() {
    dashboardService.addComponent(enComponent.creditCardInstallments);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.creditCardInstallments);
  }

  render() {
    const { indicatorsPieChart } = this.state;

    return (
      <IndicatorPieChart
        chartData={{
          title: 'Pagamentos no Cartão',
          dataType: enDataType.percent,
          indicators: indicatorsPieChart
        }}
        elevation={2}
      />
    );
  }
}
