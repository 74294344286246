import { Paper, Tooltip, Typography } from '@material-ui/core';
import Spinner from 'components/Shared/Spinner';
import { IConversionFunnel } from 'interfaces/conversionFunnel';
import { enComponent } from 'interfaces/socket';
import React, { useCallback, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { socketService } from 'services/socket';
import useStyles from './styles';

const funnelCheckoutsTooltip =
  'Quantidade de acessos únicos no checkout, tendo um acesso único a duração de 24h. Após esse intervalo é contabilizado um novo acesso.';
const funnelAttemptsTooltip = 'Número de acessos que clicaram no botão de concluir a compra.';
const funnelSalesTooltip = 'Número de acessos que chegaram na página de obrigado e tiveram o pagamento confirmado.';

const ConversionFunnel = () => {
  const classes = useStyles();
  const [previousConversionFunnelMetrics, setPreviousConversionFunnelMetrics] = useState(null);

  useEffect(() => {
    dashboardService.addComponent(enComponent.conversionFunnel);

    return () => {
      dashboardService.removeComponent(enComponent.conversionFunnel);
    };
  }, []);

  const [conversionFunnelMetrics] = useObservable<IConversionFunnel>(() => {
    return socketService.listen(enComponent.conversionFunnel).pipe(
      rxjsOperators.filter((funnel: IConversionFunnel) => !!funnel),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.tap(() => {
        setPreviousConversionFunnelMetrics(conversionFunnelMetrics);
      }),
      rxjsOperators.map(funnel => {
        const conversionFunnelMetrics: IConversionFunnel = {
          accessNumber: 0,
          purchaseAttempts: {
            total: 0,
            percentage: 0
          },
          purchaseConfirmed: {
            total: 0,
            percentage: 0
          }
        };

        conversionFunnelMetrics.accessNumber = funnel.accessNumber;
        conversionFunnelMetrics.purchaseAttempts = funnel.purchaseAttempts;
        conversionFunnelMetrics.purchaseConfirmed = funnel.purchaseConfirmed;

        return conversionFunnelMetrics;
      })
    );
  }, []);

  const [rangeTime] = useObservable(() => {
    return filterService.getFilterRange().pipe(
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(filterRange => filterRange.range)
    );
  }, []);

  const [ignoreBankslip] = useObservable<boolean>(() => {
    return filterService.getFilter().pipe(
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(filter => filter.ignoreBankslip)
    );
  }, []);

  const formatNumber = useCallback((n: number) => {
    return n.toLocaleString('pt-BR', { maximumFractionDigits: 0 });
  }, []);

  return (
    <Paper elevation={2} className={classes.paper}>
      <div>
        <Typography variant='subtitle1' className={classes.title}>
          Funil de Conversão
          <Typography variant='body2' className={classes.rangeTime}>
            {rangeTime}
          </Typography>
        </Typography>
      </div>
      <div className={classes.funnel}>
        <Typography variant='subtitle1' className={classes.funnelLabel}>
          Checkouts
        </Typography>
        <Tooltip
          title={`
            ${funnelCheckoutsTooltip}
            ${ignoreBankslip ? 'Boletos estão sendo desconsiderados devido ao filtro aplicado.' : ''}
          `}
          placement='top'
        >
          <div className={classes.trapezoid} id='funnel-checkouts'>
            <div className={classes.corner} id='left' />
            <div className={classes.center}>
              {isNaN(conversionFunnelMetrics?.accessNumber) ? (
                <Spinner size={24} color='#fff' />
              ) : (
                <label className={classes.trapezoidLabel}>
                  <CountUp
                    start={previousConversionFunnelMetrics?.accessNumber || 0}
                    end={conversionFunnelMetrics?.accessNumber || 0}
                    duration={0.7}
                    formattingFn={formatNumber}
                  />
                </label>
              )}
            </div>
            <div className={classes.corner} id='right' />
          </div>
        </Tooltip>

        <Typography variant='subtitle1' className={classes.funnelLabel}>
          Tentativas (
          <CountUp
            start={previousConversionFunnelMetrics?.purchaseAttempts?.percentage || 0}
            end={conversionFunnelMetrics?.purchaseAttempts?.percentage || 0}
            duration={0.7}
            formattingFn={formatNumber}
          />
          %)
        </Typography>
        <Tooltip title={funnelAttemptsTooltip} placement='top'>
          <div className={classes.trapezoid} id='funnel-attempts'>
            <div className={classes.corner} id='left' />
            <div className={classes.center}>
              {isNaN(conversionFunnelMetrics?.purchaseAttempts?.total) ? (
                <Spinner size={24} color='#fff' />
              ) : (
                <label className={classes.trapezoidLabel}>
                  <CountUp
                    start={previousConversionFunnelMetrics?.purchaseAttempts?.total || 0}
                    end={conversionFunnelMetrics?.purchaseAttempts?.total || 0}
                    duration={0.7}
                    formattingFn={formatNumber}
                  />
                </label>
              )}
            </div>
            <div className={classes.corner} id='right' />
          </div>
        </Tooltip>

        <Typography variant='subtitle1' className={classes.funnelLabel}>
          Vendas Confirmadas (
          <CountUp
            start={previousConversionFunnelMetrics?.purchaseConfirmed?.percentage || 0}
            end={conversionFunnelMetrics?.purchaseConfirmed?.percentage || 0}
            duration={0.7}
            formattingFn={formatNumber}
          />
          %)
        </Typography>
        <Tooltip title={funnelSalesTooltip} placement='top'>
          <div className={classes.trapezoid} id='funnel-sales'>
            <div className={classes.corner} id='left' />
            <div className={classes.center}>
              {isNaN(conversionFunnelMetrics?.purchaseConfirmed?.total) ? (
                <Spinner size={24} color='#fff' />
              ) : (
                <label className={classes.trapezoidLabel}>
                  <CountUp
                    start={previousConversionFunnelMetrics?.purchaseConfirmed?.total || 0}
                    end={conversionFunnelMetrics?.purchaseConfirmed?.total || 0}
                    duration={0.7}
                    formattingFn={formatNumber}
                  />
                </label>
              )}
            </div>
            <div className={classes.corner} id='right' />
          </div>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default ConversionFunnel;
