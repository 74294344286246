import { MuiThemeProvider } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { theme } from 'assets/theme';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorPanel } from 'interfaces/indicator';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import rxjsOperators from 'rxjs-operators';
import filterService from 'services/filter';
import Spinner from '../Spinner';
import TooltipDescription from '../TooltipDescription';
import useStyles from './styles';

interface IProps {
  indicatorData: IIndicatorPanel;
  oldIndicatorDataValue?: number;
  elevation?: number;
  disableDecimal?: boolean;
  disableCountUp?: boolean;
  description?: string;
  disableValue?: boolean;
  customRangeTime?: string;
  hideTime?: boolean;
}

const IndicatorPanel: React.FC<IProps> = ({
  indicatorData,
  customRangeTime,
  description,
  disableCountUp,
  disableValue,
  children,
  oldIndicatorDataValue,
  hideTime = false
}) => {
  const classes = useStyles();

  const [rangeTime, setRangeTime] = useState<string>('');

  useEffect(() => {
    if (customRangeTime) {
      setRangeTime(customRangeTime);
    }
  }, [customRangeTime]);

  useEffect(() => {
    filterService
      .getFilterRange()
      .pipe(rxjsOperators.logError())
      .subscribe(filterRange => {
        setRangeTime(filterRange.range);
      });
  }, []);

  function formatNumber(n: number) {
    return n.toLocaleString('pt-BR');
  }

  if (!indicatorData) return null;

  const getDecimals = () => {
    if (indicatorData.dataType === enDataType.percent) {
      return 1;
    }
    return indicatorData.dataType === enDataType.second ? 1 : 0;
  };


  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.container}>
        {indicatorData.icon && (
          <span className={classes.icon}>
            <SvgIcon name={indicatorData.icon} />
          </span>
        )}
        <Paper className={classes.root} elevation={2}>
          <div>
            <Typography variant='subtitle1' className={classes.title}>
              {indicatorData.title}
              {description ? (
                <div className={classes.tooltipContainer}>
                  <TooltipDescription text={description} />
                </div>
              ) : null}
            </Typography>
          </div>
          <Grid
            container
            direction='row'
            className={`${classes.indicators} ${children && classes.indicatorsWithChildren}`}
          >
            <Grid item xs={12} className={classes.valueGrid}>
              {isNaN(indicatorData.value) ? <Spinner size={40} /> : ''}
              <Typography component='div' variant='body2' className={classes.value} align='right'>
                {!disableValue && !isNaN(indicatorData.value) ? (
                  <div>
                    {indicatorData.dataType === enDataType.currency && <span className={classes.currency}>R$</span>}

                    <span>
                      {!disableCountUp ? (
                        <CountUp
                          start={oldIndicatorDataValue || 0}
                          end={indicatorData.value || 0}
                          decimals={getDecimals()}
                          duration={0.7}
                          formattingFn={formatNumber}
                        />
                      ) : (
                        indicatorData.value.toLocaleString('pt-BR')
                      )}

                      {indicatorData.dataType === enDataType.percent && '%'}
                      {indicatorData.dataType === enDataType.second && 's'}

                      {children}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </Typography>

              <span className={classes.rangeTime}>{!hideTime && (rangeTime || <span>&nbsp;</span>)}</span>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </MuiThemeProvider>
  );
};

export default IndicatorPanel;
