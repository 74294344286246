import { makeStyles } from '@material-ui/core';
import StackedAreaChart from 'components/Shared/StackedAreaChart';
import { IBankslipCreditCardSales } from 'interfaces/bankslipCreditCardSales';
import { IStackedAreaChartData } from 'interfaces/chart';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import socketService from 'services/socket';
import CustomTooltip from './customTooltip';

const useStyles = makeStyles(() => ({
  overrides: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}));

export interface IPaymentMethodConversion {
  date1: string;
  date2: string;
  bankslip: number;
  creditcard: number;
  pix: number;
}
const BankslipCreditCardSales: React.FC = () => {
  const classes = useStyles();

  const [chartInformations, setChartInformations] = useState<IStackedAreaChartData[]>([
    {
      title: 'Compras com Cartão',
      description: 'Todas as compras efetuadas com cartão de crédito, Paypal, E-Wallet e Saldo Eduzz',
      value: 0,
      key: 'creditcard',
      strokeColor: '#FFCA28',
      dataType: enDataType.numeric
    },
    {
      title: 'Compras com Boletos',
      value: 0,
      key: 'bankslip',
      strokeColor: '#4CAF50',
      dataType: enDataType.numeric
    },
    {
      title: 'Compras com Pix',
      value: 0,
      key: 'pix',
      strokeColor: '#8E24AA',
      dataType: enDataType.numeric
    }
  ]);
  const [chartData, setChartData] = useState<IPaymentMethodConversion[]>([]);

  useEffect(() => {
    dashboardService.addComponent(enComponent.bankslipCreditCardSales);

    return () => {
      dashboardService.removeComponent(enComponent.bankslipCreditCardSales);
    };
  }, []);
  useEffect(() => {
    socketService
      .listen(enComponent.bankslipCreditCardSales)
      .pipe(
        rxjsOperators.filter((bankslipCreditCardSales: IBankslipCreditCardSales) => !!bankslipCreditCardSales),
        rxjsOperators.distinctUntilChanged(
          (a: IBankslipCreditCardSales, b: IBankslipCreditCardSales) => JSON.stringify(a) === JSON.stringify(b)
        ),
        rxjsOperators.withLatestFrom(filterService.getFilter()),
        rxjsOperators.logError()
      )
      .subscribe(([bankslipCreditCardSales, filter]) => {
        setChartInformations(previousData =>
          previousData.map(info => {
            info.value = bankslipCreditCardSales[info.key];
            if (info.key === 'bankslip') {
              info.disabled = filter.ignoreBankslip;
              info.disabledMessage = filter.ignoreBankslip
                ? 'O filtro aplicado não corresponde a essa informação.'
                : '';
            }
            return info;
          })
        );

        if (bankslipCreditCardSales.bankslipCreditCardSalesByDay) {
          const mappedChartData: IPaymentMethodConversion[] = [];
          bankslipCreditCardSales.bankslipCreditCardSalesByDay.forEach(sale => {
            if (sale.bankslip || sale.creditcard || sale.pix) {
              mappedChartData.push({
                date1: `${sale.date}T00:00:00.000-03:00`,
                date2: `${sale.date}T00:00:00.000-03:00`,
                creditcard: sale.creditcard,
                bankslip: sale.bankslip,
                pix: sale.pix
              });
            }
          });

          setChartData(mappedChartData);
        }
      });
  }, []);

  return (
    <StackedAreaChart
      chartInformations={chartInformations}
      data={chartData}
      disableDecimal
      className={classes.overrides}
      customTooltip={<CustomTooltip />}
    />
  );
};

export default BankslipCreditCardSales;
