import React, { PureComponent } from 'react';
import { GA_MEASUREMENT_ID } from 'settings';
import googleAnalytics from 'services/googleAnalytics';

export default class GoogleAnalytics extends PureComponent {
  componentDidMount() {
    const scripts = document.createElement('div');
    const script = document.createElement('script');

    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GA_MEASUREMENT_ID}');`;

    const scriptAsync = document.createElement('script');
    scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    scriptAsync.async = true;

    scripts.appendChild(script);
    scripts.appendChild(scriptAsync);

    document.getElementById('google-analytics-script').appendChild(scripts);

    googleAnalytics.sendPageView(document.title, `${window.location.pathname} ${window.location.search}`);
  }

  render() {
    return <div id='google-analytics-script' />;
  }
}
