import React from 'react';

export const logoIcon = {
  svg: (
    <svg viewBox="0 0 135.8 42.5">
      <g>
        <g fill="#ffffff">
          <path d="M60.5 7.8c2.5 0 4.3.5 6 1.5.2.1.3.3 0 .6l-1 2c0 .2-.2.2-.5.1-.9-.3-1.7-.7-2.6-.9h-2.6c-.7.2-1 .7-1.2 1.3-.2.5.3 1.1.8 1.5l1.4.5c1.3.4 2.5.7 3.7 1.3 1.3.6 2.2 1.6 2.4 3 .3 2-.3 3.8-2 4.9a8.8 8.8 0 0 1-5.5 1.2c-1.8-.1-3.5-.6-5-1.6-.5-.3-.5-.3-.2-.8l1-1.9c.1-.2.2-.3.5-.1 1 .4 2 .8 3.2 1 .8.3 1.6.3 2.4.1.7-.1 1.2-.5 1.5-1 .2-.3.2-.6.1-.9-.3-.6-.7-1-1.3-1.3-1.1-.5-2.3-.7-3.4-1.1a7 7 0 0 1-2.6-1.4c-.6-.6-1-1.3-1-2.1-.4-2.5.8-4.4 3-5.3 1.2-.4 2.3-.6 2.9-.6"/>
          <path d="M0 10.5V3.1h3.6c.3 0 .2.2.2.4V8c0 .3.1.4.5.4h4.2c.3 0 .4 0 .3.3v2.6c0 .3 0 .3-.3.3H4.4c-.3 0-.4.1-.4.4v7.5c.2 1 .8 1.7 2 1.8a5 5 0 0 0 2.4-.3c.2-.2.3-.1.4.2l.9 2.4c0 .2 0 .3-.2.4a7.4 7.4 0 0 1-7.2-.2C.8 23 .2 21.4 0 19.8v-9.3"/>
          <path d="M29 0h3.7V23.3c0 .3 0 .4-.5.4h-2.8c-.3 0-.4 0-.4-.4V0"/>
          <path d="M70.1 14.8c.8-4 4.1-6.7 8-7 .4 0 .5 0 .5.4v2.5c.1.2 0 .3-.3.4-2.4.2-4.5 2-5 4.3-.5 3.1 2 6.1 5.3 6.2.3 0 .3 0 .3.3l.1 2.6c0 .2 0 .3-.4.3-1.8 0-3.5-.5-5-1.6-2.7-2-4-4.7-3.5-8v-.4"/>
          <path d="M83 11.2c1.5-1.8 3.4-3 5.8-3.3 4.5-.6 8.7 2 9.9 6.2 1.4 4.9-2 10-7.3 10.6-1.9.3-3.6 0-5.3-.8.7 0 1.4.3 2 .3a8 8 0 0 0 7.5-4.1c2.4-4.6-.4-10-5.7-10.9a8 8 0 0 0-6.6 1.9l-.2.1"/>
          <path d="M93 21.2a5.9 5.9 0 0 1-4.2 2.6c-3.3.4-6.3-2-6.7-5.7-.5-3.3 1.9-6.6 5-7.2 1.2-.2 2.4 0 3.6.5 0 0 .2 0 .3.3l-1.2-.2c-2.7-.1-5 1.9-5.4 4.7a5.5 5.5 0 0 0 3.8 6c1.8.6 3.3.1 4.7-1h.1"/>
          <path d="M91 12.8c.6 0 1 .5 1 1 0 .6-.5 1-1 1a1 1 0 0 1-1-1c0-.6.4-1 1-1"/>
          <path d="M90.5 15.8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.3.5.5c0 .3-.2.5-.5.5"/>
          <path d="M109.9 21.8c-3 0-5.5-2.4-5.5-5.3 0-3 2.4-5.4 5.5-5.4 3 0 5.5 2.4 5.5 5.4 0 3-2.5 5.4-5.5 5.3m8.7-6.8a8.8 8.8 0 0 0-10.1-7 8.6 8.6 0 0 0-7.5 8.3v13.5h3.3v-6.6l.3.2a9 9 0 0 0 7 1.5c4.6-.8 7.9-5.4 7-10"/>
          <path d="M124.3 14.3a4 4 0 0 1 5.7-2.9c1.3.6 1.7 1.8 1.9 3 0 .3-.2.3-.4.3h-6.8c-.4 0-.4 0-.4-.4m.3 3.2h10.5q.7 0 .7-.7v-1.6c-.2-3.6-2.5-6.9-6.7-7.2-3-.3-5.5.7-7.3 3.1a8.9 8.9 0 0 0-1.5 7c.4 2 1.3 3.6 2.9 4.9a9 9 0 0 0 7.8 1.5c1.7-.4 3.1-1.2 4.2-2.5.2-.3.2-.4 0-.6l-2-1.4c-.1-.2-.3-.2-.5 0a5.6 5.6 0 0 1-5.6 1.3c-1.9-.6-3-2-2.9-3.7.2-.1.3 0 .4 0"/>
          <path d="M46.5 14.6h-6.3c-.3 0-.4 0-.3-.3.4-2.7 2.8-4.1 5.3-3 1.1.4 1.7 1.4 1.9 2.7.1.6 0 .6-.6.6m4.2-1.2c-.6-2.4-2-4.3-4.3-5.2a7.7 7.7 0 0 0-4-.3 7 7 0 0 0-5.8 4.7c-.6 1.7-.7 3.4-.5 5.1.1 2.2 1 4 2.7 5.4 2 1.5 4.3 2 6.8 1.6 2-.3 3.6-1.2 4.8-2.8.2-.2.2-.3 0-.5a46 46 0 0 1-2-1.5c-.1-.2-.3-.1-.4 0a7 7 0 0 1-2.5 1.4c-2.2.7-5.4-.2-5.7-3.5 0-.3 0-.4.4-.4h10.4c.2 0 .4 0 .4-.2 0-1.3 0-2.5-.3-3.7"/>
          <path d="M21.9 14.6H15c-.2 0-.3 0-.2-.3.4-3 3.7-4.4 6-2.7.8.7 1.1 1.7 1.2 2.8 0 .3-.1.2-.3.2m4-1a7.3 7.3 0 0 0-4-5.2c-1.5-.7-3-.7-4.5-.4a6.9 6.9 0 0 0-5.5 4 11 11 0 0 0-.8 5.6c.2 3 1.7 5 4.2 6.3 1.8 1 3.7 1.1 5.7.7a7.4 7.4 0 0 0 4.5-2.7c.2-.2.2-.3 0-.5a34 34 0 0 1-1.8-1.4c-.3-.2-.4-.2-.7 0a5.8 5.8 0 0 1-4.4 1.5c-2-.3-3.4-1.4-3.7-3.6 0-.3 0-.5.4-.5h10.5c.1 0 .3 0 .3-.2 0-1.2 0-2.4-.2-3.5"/>
         </g>
         <path fill="#ffffff" d="m2 39.5-1 3H.3l3.2-8.6h.7l3.3 8.6h-.7l-1.1-3H2Zm3.5-.6-1.7-4.3-1.5 4.3h3.2ZM10.7 42.5v-8.6h1c1.4 2 4.5 6.8 5 7.8v-7.8h.6v8.6h-.9l-5-8v8h-.6ZM22.4 39.5l-1.1 3h-.7l3.1-8.6h.8l3.3 8.6h-.7l-1.1-3h-3.6Zm3.3-.6-1.6-4.3-1.5 4.3h3.1ZM31 33.9h.7v8h4.6l-.1.5H31V34ZM40 42.5V39l-3-5.2h.7l2.7 4.6 2.8-4.6h.8l-3.2 5.2V42.4H40ZM49.2 34.5h-2.8V34h6.4v.6h-2.9v8h-.6v-8ZM56.4 33.9v8.6h-.6v-8.6h.6ZM67 40.2a3.1 3.1 0 0 1-3.2 2.3c-2.5 0-3.8-2-3.8-4.3s1.3-4.4 3.8-4.4c1.8 0 3 1 3.2 2.4h-.7c-.4-1-1-1.8-2.6-1.8-2 0-3 2-3 3.8s1 3.7 3.1 3.7c1.4 0 2.1-.7 2.5-1.7h.7ZM70.7 40.2c.2 1.1 1 1.8 2.4 1.8 1.6 0 2.2-.8 2.2-1.7s-.5-1.6-2.3-2c-1.9-.5-2.6-1.1-2.6-2.3 0-1.2.8-2.2 2.6-2.2s2.7 1.1 2.8 2.2h-.7c-.2-1-.8-1.6-2.1-1.6-1.2 0-2 .6-2 1.5s.6 1.3 2.2 1.7c2.4.6 2.8 1.6 2.8 2.6 0 1.3-1 2.4-3 2.4s-2.8-1.3-3-2.4h.7Z"/>
      </g>
    </svg>
  )
}
