import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: '15px',
    overflow: 'hidden',
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
      paddingInline: '15px'
    }
  },
  mapContainer: {
    flexBasis: '50%',
    minHeight: 545,
    borderRight: `1px solid ${theme.variables.colors['indicatorChartSeparator']}`,
    paddingInline: '15px',
    [theme.breakpoints.down(960)]: {
      padding: 0,
      borderRight: 0,
      borderBottom: `1px solid ${theme.variables.colors['indicatorChartSeparator']}`
    }
  },
  tableContainer: {
    flexBasis: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
