import UserContextProvider from 'components/contexts/UserContext';
import AppWrapper from 'components/Layout/AppWrapper';
import Metrics from 'components/Pages/Admin/Metrics';
import Settings from 'components/Pages/Admin/Settings';
import Transactions from 'components/Pages/Admin/Transactions';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'services/socket';
import RouterProtected from './RouterProtected';
const AppRouter: React.FC = () => {
  return (
    <UserContextProvider>
      <RouterProtected>
        <Router>
          <AppWrapper>
            <Switch>
              <Route path='/configuracoes' exact>
                <Settings />
              </Route>
              <Route path='/transacoes' exact>
                <Transactions />
              </Route>
              <Route path='/'>
                <Metrics />
              </Route>
            </Switch>
          </AppWrapper>
        </Router>
      </RouterProtected>
    </UserContextProvider>
  );
};

export default AppRouter;
