import { IUserToken } from 'interfaces/userToken';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import * as rxjsOperators from 'rxjs-operators';

export class TokenService {
  private token$: BehaviorSubject<string>;

  constructor() {
    this.token$ = new BehaviorSubject(null);
  }

  public getToken(): Observable<string> {
    return this.token$.pipe(rxjsOperators.distinctUntilChanged());
  }

  public setToken(token: string): void {
    this.token$.next(token);
  }

  public clearToken(): void {
    this.setToken(null);
  }

  public decode(token: string): IUserToken {
    try {
      const data = jwtDecode<IUserToken>(token);
      const currentTime = Date.now() / 1000;

      return currentTime > data.exp ? null : data;
    } catch (err) {
      return null;
    }
  }
}

const tokenService = new TokenService();
export default tokenService;
