import { Slide } from '@material-ui/core';
import { enAlertSeverity } from 'components/contexts/ToastContext/context';
import React from 'react';
import { SvgIcon } from '../SvgIcon';
import useStyles from './styles';

enum enSeverityIcon {
  success = 'faceHappy',
  error = 'faceSad'
}

interface IProps {
  opened: boolean;
  message: string;
  severity: enAlertSeverity;
}

const Toast: React.FC<IProps> = ({ message, opened, severity }) => {
  const classes = useStyles();

  return (
    <Slide direction='left' in={opened}>
      <div className={`${classes.snackbar} ${classes[severity]}`}>
        <div className={classes.svg}>
          <SvgIcon name={enSeverityIcon.success} />
        </div>
        <p>{message}</p>
      </div>
    </Slide>
  );
};

export default Toast;
