import Paper from '@material-ui/core/Paper/Paper';
import { WithStyles } from 'decorators/withStyles';
import React, { PureComponent } from 'react';
import BankSlipGeneratedCreditCardAttempts from '../BankslipCreditCardAttempts';
import TotalSales from '../TotalSales';

interface IProps {
  classes?: any;
}

@WithStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  overrides: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
}))
export default class Sales extends PureComponent<IProps, {}> {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={2}>
        <TotalSales />
        <BankSlipGeneratedCreditCardAttempts />
      </Paper>
    );
  }
}
