import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loader: {
    width: 70,
    height: 70,
    color: theme.variables.colors['loader'],
    overflow: 'hidden !important'
  }
}));

export default useStyles;
