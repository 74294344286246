import { Grid, Tooltip } from '@material-ui/core';
import React, { Fragment, useCallback } from 'react';
import formatData from '../../../helpers/formatData';
import { SvgIcon } from '../SvgIcon';
import useTableStyles from './styles';

interface ITableProps {
  columns: ITableColumn[];
  rows: ITableRow[];
  onClick?: (props: any) => void;
  onClickPropName?: string;
}

export interface ITableColumn {
  field: string;
  headerName: string;
  size?: {
    lg?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    sm?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    xs?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  };
  type?: 'numeric' | 'percentage' | 'currency' | 'text';
  align?: 'left' | 'right';
  breakContent?: boolean;
  render?: (props?: object) => React.ReactNode;
  hideFieldInMobile?: boolean;
}

export interface ITableRow {
  [key: string]: ITableRowProps | any;
}

export interface ITableRowProps {
  value?: string | number;
  props?: object | object[] | string | number | string[] | number[];
  icon?: string;
  iconTooltip?: string;
}

const Table: React.FC<ITableProps> = ({ columns, rows, onClick, onClickPropName }) => {
  const classes = useTableStyles();

  const handleClick = useCallback(
    row => () => {
      if (!onClick) {
        return;
      }

      onClick(row);
    },
    [onClick]
  );

  return (
    <Grid container>
      <div className={classes.row}>
        {columns.map(column => (
          <Grid
            key={`column-${column.field}`}
            item
            xs={column.size.xs}
            sm={column.size.sm}
            md={column.size.md}
            lg={column.size.lg}
            className={column.hideFieldInMobile ? classes.hideFieldInMobile : ''}
          >
            <div
              className={`
                ${classes.columnTitle}
                ${!!column.type && column.type !== 'text' ? classes.alignRight : ''}
              `}
            >
              {column.headerName}
            </div>
          </Grid>
        ))}
      </div>

      {rows &&
        rows.map((row, i) => (
          <div key={i} className={classes.row} onClick={handleClick(row[onClickPropName])}>
            {columns.map((column, x) => (
              <Fragment key={`row-column-${i}-${x}`}>
                <Grid
                  item
                  xs={column.size.xs}
                  sm={column.size.sm}
                  md={column.size.md}
                  lg={column.size.lg}
                  className={column.hideFieldInMobile ? classes.hideFieldInMobile : ''}
                >
                  <div
                    className={`
                      ${classes.data}
                      ${column.align === 'right' || (!!column.type && column.type !== 'text') ? classes.alignRight : ''}
                    `}
                  >
                    {!column?.render && row[column.field]?.icon && (
                      <Tooltip title={row[column.field].iconTooltip} placement='top'>
                        <span>
                          <SvgIcon name={row[column.field].icon} />
                        </span>
                      </Tooltip>
                    )}

                    {!column?.render && row[column.field]?.value !== null && row[column.field]?.value !== undefined && (
                      <span className={`${classes.text} ${column.breakContent ? classes.breakContent : ''}`}>
                        {formatData(row[column.field].value, column.type)}
                      </span>
                    )}

                    {column?.render && column.render(row[column.field])}
                  </div>
                </Grid>
              </Fragment>
            ))}
          </div>
        ))}
    </Grid>
  );
};

export default Table;
