import { Grid, MuiThemeProvider, Paper, Typography } from '@material-ui/core';
import { theme } from 'assets/theme';
import variables from 'assets/theme/variables';
import { IChart } from 'interfaces/chart';
import { enDataType } from 'interfaces/dashboard';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import rxjsOperators from 'rxjs-operators';
import filterService from 'services/filter';
import MetricPerformance from '../MetricPerformance';
import Spinner from '../Spinner';
import TooltipDescription from '../TooltipDescription';
import CustomTooltip from './customTooltip';
import useStyles from './styles';

interface IProps {
  chartData: IChart;
  alignTitleRight?: boolean;
  alignValuesRight?: boolean;
  elevation?: number;
  disableDecimal?: boolean;
  minHeight?: number;
  childrenComponent?: React.ReactNode;
  className?: any;
  description?: string;
  renderChildrenAfterChart?: boolean;
}

const Chart: React.FC<IProps> = ({
  chartData,
  alignTitleRight,
  alignValuesRight,
  childrenComponent: children,
  className,
  description,
  minHeight,
  renderChildrenAfterChart
}) => {
  const [rangeTime, setRangeTime] = useState<string | null>(null);
  const [oldValue, setOldValue] = useState<number>(0);

  const classes = useStyles();

  useEffect(() => {
    filterService
      .getFilterRange()
      .pipe(rxjsOperators.logError())
      .subscribe(filterRange => {
        setRangeTime(filterRange.range);
      });
  }, []);

  useEffect(() => {
    setOldValue(chartData.value);
  }, [chartData]);

  function formatNumber(n: number) {
    return n.toLocaleString('pt-BR');
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Paper elevation={2} className={`${classes.root} ${className}`} style={{ minHeight }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant='subtitle1'
            align='right'
            className={`${classes.title} ${alignTitleRight && classes.alignTitleRight}`}
          >
            {chartData.title}
            {chartData.description && <TooltipDescription text={chartData.description} />}
          </Typography>
          {description && <TooltipDescription text={description} />}
        </div>
        {!!chartData.value && (
          <>
            {isNaN(chartData.value) ? (
              <div style={{ display: 'flex', justifyContent: 'center', minHeight: '160px' }}>
                <Spinner size={32} />
              </div>
            ) : (
              <>
                <Grid container direction='row' alignItems='flex-start' justify='space-between'>
                  {renderChildrenAfterChart && (
                    <Grid item xs={2}>
                      {children}
                    </Grid>
                  )}
                  {chartData.value ? (
                    <Grid item xs={renderChildrenAfterChart ? 10 : 12} className={classes.valueGrid}>
                      <div className={`${classes.valueContent} ${alignValuesRight && classes.alignValuesRight}`}>
                        <div className={classes.data}>
                          <Typography variant='body2' className={classes.rangeTime}>
                            {rangeTime}
                          </Typography>
                          <Typography variant='body2' className={classes.value}>
                            <CountUp
                              start={oldValue}
                              end={chartData.value || 0}
                              decimals={0}
                              duration={0.7}
                              formattingFn={formatNumber}
                            />
                            {chartData.dataType === enDataType.percent && '%'}
                            {chartData.dataType === enDataType.second && 's'}
                          </Typography>
                          {typeof chartData.performance === 'number' && (
                            <Typography variant='body2' className={alignValuesRight && classes.alignValuesRight}>
                              <MetricPerformance performance={chartData.performance || 0} />
                            </Typography>
                          )}
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    children &&
                    !renderChildrenAfterChart && (
                      <Grid item xs={12} sm={12} md={3} className={classes.valueGrid}>
                        {children}
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ResponsiveContainer width='100%' height={120} className={classes.chartWrapper}>
                    <AreaChart data={chartData.data}>
                      <XAxis dataKey={'date'} hide={true} />
                      <Tooltip content={<CustomTooltip label={'date'} dataType={chartData.dataType} />} />
                      <Area
                        dot={{
                          stroke: variables.colors['chartStroke'],
                          strokeWidth: 1,
                          r: 3,
                          fill: variables.colors['chartStroke']
                        }}
                        type='monotone'
                        dataKey={chartData.key}
                        stroke={variables.colors['chartStroke']}
                        strokeWidth={2}
                        fill={variables.colors['chartFill']}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Grid>
              </>
            )}
          </>
        )}
      </Paper>
    </MuiThemeProvider>
  );
};

export default Chart;
