import { MuiThemeProvider, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography';
import { theme } from 'assets/theme';
import { WithStyles } from 'decorators/withStyles';
import formatNumber from 'helpers/formatNumber';
import { IStackedAreaChartData } from 'interfaces/chart';
import { enDataType } from 'interfaces/dashboard';
import React, { PureComponent } from 'react';
import CountUp from 'react-countup';
import { Area, AreaChart, ContentRenderer, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import rxjsOperators from 'rxjs-operators';
import filterService from 'services/filter';
import InsufficientData from '../InsufficientData';
import TooltipDescription from '../TooltipDescription';

interface IProps {
  chartInformations: IStackedAreaChartData[];
  data: any;
  elevation?: number;
  disableDecimal?: boolean;
  classes?: any;
  minHeight?: number;
  children?: React.ReactNode;
  customTooltip?: React.ReactElement<any> | React.StatelessComponent<any> | ContentRenderer<TooltipProps>;
  className?: any;
}

interface IState {
  rangeTime: string;
  oldValue: number;
}

@WithStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flex: 2
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  valueGrid: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%'
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  indicators: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    '&:last-child': {
      border: 'none'
    },
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down(600)]: {
      height: '100%',
      justifyContent: 'space-between'
    }
  },
  valueContent: {
    marginTop: 15
  },
  areaChartContainer: {
    flex: 2,
    minHeight: '320px'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  value: {
    fontSize: '2rem',
    fontWeight: 600,
    '@media screen and (max-width: 600px)': {
      fontSize: 28
    }
  },
  rangeTime: {
    fontSize: 14
  },
  disabled: {
    fontSize: '1rem',
    padding: '12px 0'
  },
  chartArea: {
    backgroundColor: theme.palette.background.default
  }
}))
export default class StackedAreaChart extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { rangeTime: null, oldValue: 0 };
  }

  componentDidMount() {
    filterService
      .getFilterRange()
      .pipe(rxjsOperators.bindComponent(this), rxjsOperators.logError())
      .subscribe(filterRange => {
        this.setState({ rangeTime: filterRange.range });
      });
  }

  formatCurrency(value: number) {
    return formatNumber(value);
  }

  formatNumber(n: number) {
    return n.toLocaleString('pt-BR');
  }

  render() {
    const { chartInformations, data, minHeight, classes, className, customTooltip } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Paper className={`${classes.root} ${className}`} style={{ minHeight }}>
          {!!data.length ? (
            <div className={classes.content}>
              <Grid item xs={12} className={classes.valueGrid}>
                <div className={classes.wrapper}>
                  {chartInformations.map((info, index) => (
                    <div key={index} className={classes.indicators}>
                      <Typography variant='subtitle1' className={classes.title}>
                        {info.title}
                        {info.description && <TooltipDescription text={info.description} />}
                      </Typography>
                      {!info.disabled && (
                        <Typography variant='subtitle1' className={classes.value} style={{ color: info.strokeColor }}>
                          <CountUp
                            start={info.value}
                            end={info.value || 0}
                            decimals={0}
                            duration={0.7}
                            formattingFn={this.formatNumber}
                          />
                          {info.dataType === enDataType.percent && '%'}
                        </Typography>
                      )}

                      {info.disabled && (
                        <Typography variant='body2' className={classes.disabled} style={{ color: info.strokeColor }}>
                          {info.disabledMessage}
                        </Typography>
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} className={classes.areaChartContainer}>
                <ResponsiveContainer width='100%' height='100%'>
                  <AreaChart data={data} className={classes.chartArea}>
                    {chartInformations.map((info, index) => {
                      return (
                        <Area
                          key={index}
                          dot={{
                            stroke: info.strokeColor,
                            strokeWidth: 1,
                            r: 3,
                            fill: info.strokeColor,
                            fillOpacity: 1
                          }}
                          type='monotone'
                          dataKey={info.key}
                          stroke={info.strokeColor}
                          fillOpacity={0}
                          strokeWidth={2}
                        />
                      );
                    })}
                    {customTooltip ? <Tooltip content={customTooltip} /> : <Tooltip />}
                  </AreaChart>
                </ResponsiveContainer>
              </Grid>
            </div>
          ) : (
            <InsufficientData />
          )}
        </Paper>
      </MuiThemeProvider>
    );
  }
}
