export enum enDataType {
  currency = 'currency',
  percent = 'percent',
  numeric = 'numeric',
  second = 'second'
}

export interface ITotal {
  percentage?: number;
  total?: number;
}
