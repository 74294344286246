import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listFiltersButton: {
    backgroundColor: 'transparent',
    color: theme.variables.colors['text'],
    width: 'auto',
    minWidth: 100,
    padding: 10,
    borderRadius: 4,
    margin: '0 8px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.variables.colors['primaryPurple']
    },
    [theme.breakpoints.down('xs')]: {
      height: 45
    },
    '& label': {
      color: theme.variables.colors['text']
    }
  },
  listLabel: {
    padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  list: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  divider: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  deleteButton: {
    '&:hover': {
      color: theme.variables.colors['negative']
    }
  },
  emptyMessage: {
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  buttonIcon: {
    color: theme.variables.colors['primaryPurple']
  },
  loaderWrapper: {
    minHeight: '320px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;
