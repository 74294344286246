import { makeStyles } from '@material-ui/core';
import { verifyIsMobileAgent } from 'helpers/verifyIsMobileAgent';
import React, { useState } from 'react';
import googleAnalytics from 'services/googleAnalytics';
import Option from './Option';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px'
  }
}));

const Settings: React.FC = () => {
  const classes = useStyles();

  const [enabledNotifications, setEnabledNotifications] = useState<boolean>(
    JSON.parse(localStorage.getItem('telescope_dashboardEnabledNotifications'))
  );
  const [enabledSound, setEnabledSound] = useState<boolean>(
    JSON.parse(localStorage.getItem('telescope_dashboardEnabledSoundAlert'))
  );
  const [enabledSmartTvMode, setEnabledSmartTvMode] = useState<boolean>(
    JSON.parse(localStorage.getItem('telescope_enabledSmartTvMode'))
  );

  const toggleNotifications = () => {
    setEnabledNotifications(prevState => {
      localStorage.setItem('telescope_dashboardEnabledNotifications', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  const toogleSoundAlert = () => {
    setEnabledSound(prevState => {
      localStorage.setItem('telescope_dashboardEnabledSoundAlert', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  const toogleSmartTvMode = () => {
    const eventName = enabledSmartTvMode ? 'disable_smartv_mode' : 'enable_smartv_mode';
    googleAnalytics.sendEvent(eventName, { event_category: 'config' });

    setEnabledSmartTvMode(prevState => {
      localStorage.setItem('telescope_enabledSmartTvMode', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <div className={classes.wrapper}>
      <Option
        icon={enabledSound ? 'playSound' : 'muteSound'}
        name='Sons'
        checked={enabledSound}
        handleChange={toogleSoundAlert}
        disableTooltip
      />
      <Option
        icon={enabledNotifications ? 'notifications' : 'disableNotifications'}
        name='Notificações'
        checked={enabledNotifications}
        handleChange={toggleNotifications}
        disableTooltip
      />
      {!verifyIsMobileAgent() && (
        <Option
          icon='reload'
          name='Atualização de dados em Smart TVs'
          checked={enabledSmartTvMode}
          handleChange={toogleSmartTvMode}
          explanation='Ative esta opção para acompanhar as atualizações dos dados em Smart TVs'
          disableTooltip
        />
      )}
    </div>
  );
};

export default Settings;
