import { Button, Checkbox, Dialog, Paper, TextField, Typography } from '@material-ui/core';
import Spinner from 'components/Shared/Spinner';
import { IProduct } from 'interfaces/product';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';

const getFilteredProducts = (searchTerm: string, products: IProduct[]) => {
  return searchTerm.length > 0
    ? products
        .filter(
          product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.id.toString().includes(searchTerm) ||
            (!!product.children &&
              product.children.find(
                c =>
                  c.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                  c.id.toString().includes(searchTerm)
              ))
        )
        .sort((b, a) => a.id - b.id)
    : null;
};

interface IProps {
  open: boolean;
  products: IProduct[];
  handleClose: () => void;
  handleConfirm: (ids: number[]) => void;
  isLoading: boolean;
  initialSelectedProducts: number[];
  shouldClear: boolean;
  coproducer: boolean;
}
const List: React.FC<IProps> = ({
  handleClose,
  handleConfirm,
  open,
  products,
  isLoading,
  initialSelectedProducts,
  shouldClear,
  coproducer = false
}) => {
  const classes = useStyles();

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedProductsCounter, setSelectedProductsCounter] = useState<number>(0);
  const [initialSelectedProductsCounter, setInitialSelectedProductsCounter] = useState(selectedProductsCounter);

  const parentIds = useMemo(() => {
    const parentsProductsIds = (products || []).filter(product => product.children?.length).map(p => p.id);
    return (initialSelectedProducts || []).filter(i => parentsProductsIds.includes(i));
  }, [products, initialSelectedProducts]);

  const filteredProducts = getFilteredProducts(searchTerm, products);

  const handleChangeCheckbox = (isChecked: boolean, productId: number) => {
    const product = products.find(p => p.id === productId);

    if (product && (!product.children || !product.children.length)) {
      if (isChecked) {
        if (coproducer && selectedProductsCounter + 1 > 10) return;
        coproducer && setSelectedProductsCounter(prevState => prevState + 1);
        setSelectedProducts(prevState => [...prevState, productId]);
      } else {
        coproducer && setSelectedProductsCounter(prevState => prevState - 1);
        setSelectedProducts(prevState => prevState.filter(id => id !== productId));
      }
      return;
    }

    const childrenIds = product.children.map(p => p.id);
    if (isChecked) {
      setSelectedProducts(prevState => {
        const childrenIdsToInsert = childrenIds.filter(child => !prevState.includes(child));
        if (coproducer && selectedProductsCounter + childrenIdsToInsert.length > 10) return prevState;
        coproducer && setSelectedProductsCounter(prevState => prevState + childrenIdsToInsert.length);
        return [...prevState, ...childrenIdsToInsert, productId];
      });
    } else {
      setSelectedProducts(prevState => prevState.filter(id => id !== productId));
      coproducer && setSelectedProductsCounter(prevState => prevState - childrenIds.length);
      setSelectedProducts(prevState => prevState.filter(id => !childrenIds.find(child => child === id)));
    }

    return;
  };

  const handleChangeChildCheckbox = (isChecked: boolean, productId: number, parentCod: number) => {
    const parentProduct = products.find(p => p.id === parentCod);
    const childrenIds = parentProduct.children.map(child => child.id);

    if (isChecked) {
      setSelectedProducts(prevState => {
        if (coproducer && selectedProductsCounter + 1 > 10) return prevState;
        coproducer && setSelectedProductsCounter(prevState => prevState + 1);
        const allChildrenAreSelected = childrenIds
          .map(id => [...prevState, productId].includes(id))
          .every(p => p === true);
        if (allChildrenAreSelected) {
          return [...prevState, productId, parentCod];
        }
        return [...prevState, productId];
      });
    } else {
      setSelectedProducts(prevState => prevState.filter(id => id !== productId));
      coproducer && setSelectedProductsCounter(prevState => prevState - 1);
      const isParentSelected = selectedProducts.findIndex(p => p === parentCod) !== -1;
      if (isParentSelected) {
        setSelectedProducts(prevState => prevState.filter(id => id !== parentCod));
      }
    }
  };

  const handleClearAndClose = () => {
    setSelectedProducts(initialSelectedProducts);
    coproducer && setSelectedProductsCounter(initialSelectedProductsCounter);
    handleClose();
  };

  const searchProduct = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setInitialSelectedProductsCounter(initialSelectedProducts.filter(id => !parentIds.includes(id)).length);
  }, [initialSelectedProducts, parentIds]);

  useEffect(() => {
    setSelectedProducts([]);
    setSelectedProductsCounter(0);
  }, [shouldClear]);

  useEffect(() => {
    setSelectedProducts(initialSelectedProducts);
  }, [initialSelectedProducts]);

  useEffect(() => {
    setSelectedProductsCounter(initialSelectedProducts.filter(id => !parentIds.includes(id)).length);
  }, [parentIds, initialSelectedProducts]);

  return (
    <Dialog disableBackdropClick open={open} onClose={handleClose} classes={{ paperWidthSm: classes.paperWidthSm }}>
      <Paper elevation={0} className={classes.root}>
        {isLoading ? (
          <Spinner size={32} />
        ) : (
          <>
            <Typography variant='subtitle1' className={classes.title}>
              Selecione {coproducer ? 'Até 10 produtos' : 'seus produtos'}
            </Typography>
            <div>
              <TextField
                id='search-product'
                name='searchProduct'
                placeholder='Procure seu produto por nome ou ID (pelo menos 3 caracteres)'
                value={searchTerm}
                onChange={searchProduct}
                InputProps={{
                  disableUnderline: true
                }}
                fullWidth
                margin='normal'
              />
            </div>
            <div className={classes.list}>
              {(filteredProducts || products).map(product => {
                return (
                  <div key={product.id} className={classes.productContainer}>
                    <div className={classes.product} id='parent-product'>
                      <Checkbox
                        checked={selectedProducts?.includes(product.id)}
                        onChange={e => handleChangeCheckbox(e.currentTarget.checked, product.id)}
                        value='product'
                      />
                      <Typography variant='body1' className={classes.productLabel}>
                        {product.id} - {product.name}
                      </Typography>
                    </div>
                    {product.children &&
                      product.children.length > 0 &&
                      product.children.map(child => {
                        return (
                          <div key={child.id} className={classes.product} id='children-product'>
                            <Checkbox
                              checked={selectedProducts.includes(child.id)}
                              onChange={e => handleChangeChildCheckbox(e.currentTarget.checked, child.id, product.id)}
                              value='product'
                            />
                            <Typography variant='body1' className={classes.productLabel}>
                              {child.id} - {child.name}
                            </Typography>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            {coproducer ? (
              <div className={classes.modalFooter}>
                <p className={`${selectedProductsCounter === 10 ? classes.redCounter : ''}`}>
                  {selectedProductsCounter}/10
                </p>
                <div className={classes.actionButtons}>
                  <Button className={classes.button} id='cancel-button' onClick={handleClearAndClose}>
                    Fechar
                  </Button>
                  <Button
                    variant='contained'
                    className={`${classes.button} ${coproducer && !selectedProductsCounter ? classes.disabled : ''}`}
                    id='confirm-button'
                    onClick={() => {
                      coproducer && setInitialSelectedProductsCounter(selectedProductsCounter);
                      handleConfirm(selectedProducts);
                    }}
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.actionButtons}>
                <Button className={classes.button} id='cancel-button' onClick={handleClearAndClose}>
                  Fechar
                </Button>
                <Button
                  variant='contained'
                  className={classes.button}
                  id='confirm-button'
                  onClick={() => handleConfirm(selectedProducts)}
                >
                  Confirmar
                </Button>
              </div>
            )}
          </>
        )}
      </Paper>
    </Dialog>
  );
};

export default List;
