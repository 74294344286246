import Chart from 'components/Shared/AreaChart';
import { ICheckoutGeneralConversion } from 'interfaces/checkoutGeneralConversion';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import socketService from 'services/socket';

const CheckoutGeneralConversion: React.FC = () => {

  useEffect(() => {
    dashboardService.addComponent(enComponent.checkoutGeneralConversion);

    return () => {
      dashboardService.removeComponent(enComponent.checkoutGeneralConversion);
    };
  }, []);

  const [checkoutGeneralConversion] = useObservable<ICheckoutGeneralConversion>(() => {
    return socketService.listen(enComponent.checkoutGeneralConversion).pipe(
      rxjsOperators.filter((generalConversion: ICheckoutGeneralConversion) => !!generalConversion),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(generalConversion => {
        return generalConversion;
      })
    );
  }, []);

  return (
    <Chart
      chartData={{
        title: 'Conversão Geral do Checkout',
        value: checkoutGeneralConversion?.total,
        performance: checkoutGeneralConversion?.performance,
        performanceDescription:
          'Percentual de queda e aumento de sua Conversão Geral, baseado em vendas pagas com cartão de crédito, boletos pagos e vendas gratuitas',
        key: 'total',
        dataType: enDataType.percent,
        data: checkoutGeneralConversion?.conversionByDay?.map(conversion => {
          return {
            date: conversion.date,
            total: conversion.total || 0
          };
        })
      }}
      description={'Percentual de acessos que clicaram no botão de concluir a compra'}
      minHeight={245}
    />
  );
};

export default CheckoutGeneralConversion;
