import BarChart, { IBarChartData } from 'components/Shared/BarChart';
import CardSimple from 'components/Shared/CardSimple';
import ChartLegend, { ILegend } from 'components/Shared/ChartLegend';
import Spinner from 'components/Shared/Spinner';
import TitleCardSimple from 'components/Shared/TitleCardSimple';
import { roundNumber } from 'helpers/formatNumber';
import { enLabels, TMappedRefusal, TRefusal } from 'interfaces/cardRefusals';
import { enComponent } from 'interfaces/socket';
import React, { memo, useEffect, useMemo, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import socketService from 'services/socket';
import useStyles from './styles';

const groupRefusals = (result: TRefusal[], rules: (string | RegExp)[][]) => {
  return Object.entries(
    result.reduce((prev, current) => {
      for (let [key, regex] of rules) {
        if (current.reason.match(regex)) {
          prev[key as string] = {
            percent: (prev[key as string]?.percent || 0) + current.percent,
            count: (prev[key as string]?.count || 0) + current.count
          };
          return prev;
        }
      }
      prev[current.reason] = {
        percent: (prev[current.reason]?.percent || 0) + current.percent,
        count: (prev[current.reason]?.count || 0) + current.count
      };

      return prev;
    }, {})
  ).map(
    (entry: any[]): TRefusal => {
      return { reason: entry[0], ...entry[1] };
    }
  );
};

const regexGroups = [
  ['GENERIC', /^(GENERIC|EMPTY_TO|INVALID_TO)/],
  ['REJECTED_BAD_FILLED', /^REJECTED_BAD_FILLED/]
];

const CardRefusals = memo(() => {
  const classes = useStyles();

  const [data, setData] = useState<TMappedRefusal[] | null>(null);
  const [rangeTime, setRangeTime] = useState<string | null>(null);

  useEffect(() => {
    dashboardService.addComponent(enComponent.cardRefusalsCount);
    return () => {
      dashboardService.removeComponent(enComponent.cardRefusalsCount);
    };
  }, []);

  useEffect(() => {
    filterService
      .getFilterRange()
      .pipe(rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(filterRange => setRangeTime(filterRange.range));
  }, []);

  useEffect(() => {
    socketService
      .listen(enComponent.cardRefusalsCount)
      .pipe(
        rxjsOperators.filter(v => !!v),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((refusals: TRefusal[] | number) => {
        if (typeof refusals == 'number') {
          setData(null);
          return;
        }
        let sum = 0;
        const groupedRefusals = groupRefusals(
          refusals.filter(refusal => refusal.percent > 0),
          regexGroups
        );
        setData(
          groupedRefusals.map(
            (refusal, index): TMappedRefusal => {
              sum += refusal.percent;
              if (index === groupedRefusals.length - 1) {
                refusal.percent += 100 - sum;
              }
              return {
                prefix: String.fromCharCode(65 + index).toUpperCase(),
                name: refusal.reason,
                value: refusal.percent,
                count: refusal.count
              };
            }
          )
        );
      });
  }, []);

  const chartData = useMemo<IBarChartData[] | null>(() => {
    if (!data) return null;
    return data.map(v => ({ name: v.prefix, value: v.value ?? 0, count: v.count ?? 0 }));
  }, [data]);

  const legendData = useMemo<ILegend[] | null>(() => {
    if (!data) return null;
    return data.map(
      (v): ILegend => ({
        prefix: v.prefix,
        label: `${enLabels[v.name]}`,
        value: v.value ? roundNumber(v.value, 2) : 0,
        type: 'percentage'
      })
    );
  }, [data]);

  return (
    <CardSimple>
      <TitleCardSimple
        title='Recusas de cartão'
        subtitle={rangeTime}
        help='Tentativas recusadas de compras por cartão'
      />
      <div className={classes.chartWrapper}>
        {!chartData ? (
          <Spinner size={40} />
        ) : (
          <>
            <div className={classes.chart}>
              <BarChart data={chartData} indicator />
            </div>
            <div className={classes.legend}>
              <ChartLegend data={legendData} />
            </div>
          </>
        )}
      </div>
    </CardSimple>
  );
});

export default CardRefusals;
