import Grid from '@material-ui/core/Grid';
import AffiliateSales from 'components/Dashboard/AffiliatesSales';
import BankslipConversion from 'components/Dashboard/BankslipConversion';
import CancellationRate from 'components/Dashboard/CancellationRate';
import CardRefusals from 'components/Dashboard/CardRefusals';
import ChargebackRate from 'components/Dashboard/ChargebackRate';
import CheckoutCurrentUsers from 'components/Dashboard/CheckoutCurrentUsers';
import CheckoutGeneralConversion from 'components/Dashboard/CheckoutGeneralConversion';
import ConversionFunnel from 'components/Dashboard/ConversionFunnel';
import ConversionTracking from 'components/Dashboard/ConversionTracking';
import CreditCardApprovalFee from 'components/Dashboard/CreditCardApprovalFee';
import CreditCardPaymentInstallments from 'components/Dashboard/CreditCardPaymentInstallments';
import CustomSalesTemplate from 'components/Dashboard/CustomSalesTemplates';
import Devices from 'components/Dashboard/Devices';
import GrossBilling from 'components/Dashboard/GrossBilling';
import Installments from 'components/Dashboard/Installments';
import InternalCreditCardApprovalRate from 'components/Dashboard/InternalCreditCardApprovalRate';
import NoLimitInstallments from 'components/Dashboard/NoLimitInstallments';
import OrderBump from 'components/Dashboard/OrderBump';
import OrderBumpBilling from 'components/Dashboard/OrderBumpBilling';
import PendingSales from 'components/Dashboard/PendingSales';
import RecurringNewCustomers from 'components/Dashboard/RecurringNewCustomers';
import Regionality from 'components/Dashboard/Regionality';
import Sales from 'components/Dashboard/Sales';
import SalesByPaymentMethods from 'components/Dashboard/SalesByPaymentMethods';
import Subscriptions from 'components/Dashboard/Subscriptions';
import TopSallingProducts from 'components/Dashboard/TopSallingProducts';
import React from 'react';

const MetricsCards: React.FC = () => {
  return (
    <>
      <Grid item xs={12} lg={8}>
        <ConversionFunnel />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={12}>
            <CheckoutCurrentUsers />
          </Grid>
          <Grid item xs={12} sm={6} lg={12}>
            <GrossBilling />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <OrderBumpBilling />
      </Grid>
      <Grid item xs={12} lg={4}>
        <NoLimitInstallments />
      </Grid>
      <Grid item xs={12} lg={4}>
        <PendingSales />
      </Grid>
      <Grid item xs={12} lg={3}>
        <InternalCreditCardApprovalRate />
      </Grid>
      <Grid item xs={12} lg={3}>
        <CreditCardApprovalFee />
      </Grid>
      <Grid item xs={12} lg={3}>
        <CancellationRate />
      </Grid>
      <Grid item xs={12} lg={3}>
        <ChargebackRate />
      </Grid>
      <Grid item xs={12} sm={12} lg={8}>
        <CheckoutGeneralConversion />
      </Grid>
      <Grid item xs={12} lg={4}>
        <BankslipConversion />
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <CardRefusals />
      </Grid>
      <Grid item xs={12} sm={6} xl={3}>
        <CreditCardPaymentInstallments />
      </Grid>
      <Grid item xs={12} sm={6} xl={3}>
        <SalesByPaymentMethods />
      </Grid>
      <Grid item xs={12} xl={6}>
        <Sales />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <ConversionTracking />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <AffiliateSales />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TopSallingProducts />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Devices />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Subscriptions />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Regionality />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <RecurringNewCustomers />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Installments />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <OrderBump />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <CustomSalesTemplate />
      </Grid>
    </>
  );
};

export default MetricsCards;
