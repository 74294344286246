import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import RedDot from './RedDot';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));

const LiveReloadFeedback: React.FC = () => {
  const classes = useStyles();
  return (
    <Typography variant='body2' className={classes.wrapper}>
      Atualização em tempo real
      <RedDot />
    </Typography>
  );
};

export default LiveReloadFeedback;
