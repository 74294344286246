import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import variables from 'assets/theme/variables';
import InsufficientData from 'components/Shared/InsufficientData';
import TooltipDescription from 'components/Shared/TooltipDescription';
import { WithStyles } from 'decorators/withStyles';
import { enComponent } from 'interfaces/socket';
import { ISubscription } from 'interfaces/subscriptions';
import React, { PureComponent } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';
import CustomTooltip from './customTooltip';

interface ICoord {
  status: string;
  y: number;
  x: number;
}

interface IState {
  chartData: ISubscription[];
  coord: ICoord[];
}

interface IProps {
  classes?: any;
}

const OFFSET_Y = 6;
const OFFSET_X = 11;

const config = {
  overdue: {
    label: 'A',
    name: 'Atrasados',
    color: variables.colors['chart3'],
    description: 'Contratos cuja fatura correspondente ao mês em que o filtro está sendo aplicado, está atrasada'
  },
  active: {
    label: 'B',
    name: 'Ativos',
    color: variables.colors['chart1'],
    description: 'Contratos que tiveram a sua fatura paga no período ao qual o filtro está sendo aplicado'
  },
  suspend: {
    label: 'C',
    name: 'Suspensos',
    color: variables.colors['chart4'],
    description: 'Contratos que foram suspensos no período em que o filtro está sendo aplicado'
  },
  canceled: {
    label: 'D',
    name: 'Cancelados',
    color: variables.colors['chart7'],
    description: 'Contratos que foram cancelados no período selecionado no filtro'
  },
  new: {
    label: 'E',
    name: 'Novos',
    color: variables.colors['chart2'],
    description: 'Contratos que foram criados no período ao qual o filtro está sendo aplicado'
  }
};

@WithStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      minHeight: 545
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chart: {
    padding: theme.spacing(2)
  },
  subtitle: {
    padding: `0 ${theme.spacing(2)}px`,
    backgroundColor: theme.variables.colors['indicatorChartBackground'],
    display: 'flex',
    flexWrap: 'wrap'
  },
  status: {
    display: 'flex',
    marginRight: theme.spacing(4),
    padding: `${theme.spacing(2)}px 0`
  }
}))
export default class Subscriptions extends PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      chartData: null,
      coord: []
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.subscriptions)
      .pipe(
        rxjsOperators.filter((subscriptions: ISubscription[]) => !!subscriptions),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(subscriptions => {
        this.setState({ chartData: subscriptions, coord: [] });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.subscriptions);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.subscriptions);
  }

  renderLabel = (status: string) => ({ index, y }: any) => {
    if (index !== 0) {
      return null;
    }

    let x = 0;

    const coord: ICoord[] = Object.assign(this.state.coord);
    const approxCoordFilter = coord.filter(c => Math.abs(c.y - y) <= OFFSET_Y && c.status !== status);

    if (approxCoordFilter.length > 0) {
      const approxCoord = approxCoordFilter.sort((a, b) => a.x - b.x)[approxCoordFilter.length - 1];

      if ((approxCoord.x === 0 || approxCoord.x > x) && config[status].label > config[approxCoord.status].label) {
        x = approxCoord.x + OFFSET_X;
      }
    }

    const statusOnState = coord.find(c => c.status === status);
    if (statusOnState?.x) {
      x = statusOnState.x;
    }

    const statusIndex = this.state.coord.findIndex(c => c.status === status);

    if (statusIndex > -1) {
      coord[statusIndex].y = y;
      coord[statusIndex].x = x;
    }

    if (statusIndex === -1) {
      coord.push({
        status,
        y,
        x
      });
    }

    this.setState({ coord });

    return (
      <text x={x} y={y} fill={config[status].color} textAnchor='start' dominantBaseline='center' fontWeight='bold'>
        {config[status].label}
      </text>
    );
  };

  render() {
    const { classes } = this.props;
    const { chartData } = this.state;

    const subscriptionsStatus = ['overdue', 'active', 'suspend', 'canceled', 'new'];

    return (
      <Paper className={classes.root} elevation={2}>
        <div className={classes.chart}>
          <Typography variant='subtitle1' className={classes.title}>
            Recorrências
            <TooltipDescription text={'Dados representados sobre os seus contratos de recorrência.'} />
          </Typography>
          {!chartData && <InsufficientData />}
          {chartData && (
            <Grid item xs={12}>
              <ResponsiveContainer width='100%' height={350}>
                <LineChart data={chartData} margin={{ left: 30, right: 15, bottom: 5, top: 5 }}>
                  {subscriptionsStatus.map(status => (
                    <Line
                      key={status}
                      dot={{
                        stroke: config[status].color,
                        strokeWidth: 1,
                        r: 3,
                        fill: '#FFF'
                      }}
                      type='monotone'
                      dataKey={status}
                      stroke={config[status].color}
                      strokeWidth={1}
                      label={this.renderLabel(status)}
                    ></Line>
                  ))}
                  <Tooltip content={<CustomTooltip />} />
                </LineChart>
              </ResponsiveContainer>
            </Grid>
          )}
        </div>
        <div className={classes.subtitle}>
          {subscriptionsStatus.map((status, index) => (
            <div className={classes.status} key={index}>
              <Typography variant='subtitle1' className={classes.title} style={{ color: config[status].color }}>
                {config[status].label} - {config[status].name}
                <TooltipDescription text={config[status].description} />
              </Typography>
            </div>
          ))}
        </div>
      </Paper>
    );
  }
}
