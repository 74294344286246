import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { theme } from 'assets/theme';
import React from 'react';
import useStyles from './styles';

interface IProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const Actions: React.FC<IProps> = ({ count, onPageChange, page, rowsPerPage }) => {
  const classes = useStyles();

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.wrapper}>
      <p>
        {(page + 1) * 15 - 14} - {(page + 1) * 15 > count ? count : (page + 1) * 15} de {count}
      </p>

      <IconButton
        className={classes.backButton}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='página anterior'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        className={classes.forwardButton}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='próxima página'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
};

export default Actions;
