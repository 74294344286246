import { Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContext from 'components/contexts/DrawerContext/context';
import routes from 'components/Router/routes';
import React, { useContext } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'transparent',
    padding: '0 24px'
  },
  toolbar: {
    minHeight: 60,
    transition: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'transparent',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      minHeight: 40
    }
  },
  menuButton: {
    marginRight: 16,
    color: theme.variables.colors['title'],
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  title: {
    flexGrow: 1,
    lineHeight: '29px',
    color: theme.variables.colors['title'],
    fontSize: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  }
}));

const AppToolbar: React.FC = () => {
  const classes = useStyles();

  const { toggleDrawer } = useContext(DrawerContext);

  const routeName = routes.find(route => route.path === window.location.pathname).name;

  return (
    <header className={classes.root}>
      <Toolbar classes={{ regular: classes.toolbar }}>
        <Hidden lgUp>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant='h6' className={classes.title}>
          {routeName}
        </Typography>
      </Toolbar>
    </header>
  );
};

export default AppToolbar;
