import { Badge, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { ITableRowProps } from '../../../Shared/Table';

interface ITransactionItemsProps {
  field: ITableRowProps;
}

const useStyles = makeStyles(() => ({
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%'
  }
}));
const TransactionItemsTableRender: React.FC<ITransactionItemsProps> = ({ field }) => {
  const classes = useStyles();
  const items: object[] = field.props as object[];

  if (!items) {
    return null;
  }

  return (
    <>
      {items.length > 1 && (
        <Tooltip title='Outros itens' placement='top'>
          <Badge style={{ transform: 'translateX(-1.2rem)' }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            badgeContent={`+${items.length - 1}`}
          />
        </Tooltip>
      )}
      <div className={classes.text}>{items[0]['title']}</div>
    </>
  );
};

export default TransactionItemsTableRender;
