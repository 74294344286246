import { IndicatorPieChart } from 'components/Shared/PieChart';
import Recommendation from 'components/Shared/Recommendation';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart } from 'interfaces/indicator';
import { IOrderBumpSales } from 'interfaces/orderBump';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
}

export default class OrderBump extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.orderBumpSales)
      .pipe(
        rxjsOperators.filter((orderBumpSales: IOrderBumpSales) => !!orderBumpSales),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(orderBumpSales => {
        const orderBumpSalesKeys = Object.keys(orderBumpSales);
        const indicatorsPieChart: IIndicatorChart[] = [];

        const labels = {
          salesWithoutOrderBump: 'Sem order bump',
          salesWithOrderBump: 'Com order bump'
        };

        orderBumpSalesKeys.forEach(c => {
          if (orderBumpSales[c]) {
            indicatorsPieChart.push({
              label: labels[c],
              value: orderBumpSales[c]
            });
          }
        });

        this.setState({ indicatorsPieChart });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.orderBumpSales);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.orderBumpSales);
  }

  render() {
    const { indicatorsPieChart } = this.state;

    return (
      <IndicatorPieChart
        chartData={{
          title: 'Vendas com Order Bump',
          dataType: enDataType.percent,
          indicators: indicatorsPieChart
        }}
        description={
          'Quantidade de vendas que ofertam order bump no Checkout e a quantidade de vendas que tiveram o Order Bump adicionado a compra'
        }
        recommendation={
          <Recommendation
            title='Você sabe o que é Order Bump?'
            text='Sendo utilizado como a ferramenta de ofertas do momento, o Order Bump permite que você ofereça outro produto dentro do seu checkout antes mesmo que seu cliente conclua a compra, vendendo dois produtos de uma única vez e aumentando ainda mais o seu faturamento!'
            button='Quero Cadastrar um Order Bump'
            url='https://adminsun.eduzz.com/orderbump'
            hiddenSm
          />
        }
        insight={
          <Recommendation
            title='Você sabia?'
            text='Agora você mesmo pode cadastrar suas ofertas de Order Bump e aumentar ainda mais seu faturamento!'
            button='Quero Cadastrar um Order Bump'
            url='https://adminsun.eduzz.com/orderbump'
            hiddenSm
          />
        }
        elevation={2}
      />
    );
  }
}
