import axios from 'axios';
import { TAnswer } from 'interfaces/hotjar';
import { API_ENDPOINT } from 'settings';

export default class NpsService {
  async getSurveyLastAnswer(clientId: number, token: string) {
    const { data: lastAnswer } = await axios.get<TAnswer | null>(`/nps?clientId=${clientId}`, {
      baseURL: API_ENDPOINT,
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    return lastAnswer;
  }
}
