import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const InternalCreditCardApprovalRate = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.internalCreditCardApprovalRate);
    return () => {
      dashboardService.removeComponent(enComponent.internalCreditCardApprovalRate);
    };
  }, []);

  const [rates] = useObservable(() => {
    return socketService.listen(enComponent.internalCreditCardApprovalRate).pipe(
      rxjsOperators.filter((newRates: any) => !!newRates),
      rxjsOperators.map(newRates => {
        const performance = Math.round(((newRates.rate - newRates.performance) / newRates.performance) * 100);
        return {
          rate: newRates.rate,
          performance: isFinite(performance) ? performance : 0
        };
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Taxa de aprovação de cartão considerando performance da Eduzz',
        value: rates?.rate,
        dataType: enDataType.percent,
        icon: 'creditCardIndicatorPanel'
      }}
      oldIndicatorDataValue={0}
      description='Este percentual mostra a aceitação do cartão de crédito nas instituições financeiras considerando as recusas de cartão relacionada a Eduzz'
    >
      <MetricPerformance performance={rates?.performance || 0} />
    </IndicatorPanel>
  );
};

export default InternalCreditCardApprovalRate;
