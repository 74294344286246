import { makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import OnlineUsers from 'components/Dashboard/OnlineUsers';
import RealTimeTransactions from 'components/Dashboard/RealTimeTransactions';
import LiveReloadFeedback from 'components/Shared/LiveReloadFeedback';
import OfflineSocket from 'components/Shared/OfflineSocket';
import useInactivateSocket from 'hooks/useInactivateSocket';
import useUnavailableSocket from 'hooks/useUnavailableSocket';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  onlineUsers: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));
const Transactions: React.FC = () => {
  const classes = useStyles();

  useInactivateSocket();

  const isConnected = useUnavailableSocket();

  return (
    <>
      {!isConnected && document.hasFocus() ? (
        <Grid item xs={12}>
          <OfflineSocket />
        </Grid>
      ) : (
        <>
          <LiveReloadFeedback />
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Grid item className={classes.onlineUsers}>
                <OnlineUsers />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <RealTimeTransactions />
          </Grid>
        </>
      )}
    </>
  );
};

export default Transactions;
