export interface ISavedFilter {
  _id?: string;
  userId: string;
  name: string;
  filter: IFilterModel;
}

export interface IFilterModel {
  customerId?: number;
  productId?: number;
  productIds?: number[];
  rangeTime?: number;
  startDate?: Date;
  endDate?: Date;
  ignoreLaunches?: boolean;
  ignoreBankslip?: boolean;
  ignoreFreeContents?: boolean;
  viewAs?: enViewAs;
}

export enum enViewAs {
  producer = 'producer',
  affiliate = 'affiliate',
  coproducer = 'coproducer'
}
