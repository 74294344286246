import { IndicatorPieChart } from 'components/Shared/PieChart';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart } from 'interfaces/indicator';
import { IRecurringNewCustomers } from 'interfaces/recurringNewCustomers';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsPieChart: IIndicatorChart[];
}

export default class RecurringNewCustomers extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsPieChart: [{ label: '', value: null, color: '' }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.recurringNewCustomers)
      .pipe(
        rxjsOperators.filter((recurringNewCustomers: IRecurringNewCustomers) => !!recurringNewCustomers),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(recurringNewCustomers => {
        const customers = ['newCustomers', 'recurringCustomers'];
        const indicatorsPieChart: IIndicatorChart[] = [];

        const labels = {
          newCustomers: 'Novos clientes',
          recurringCustomers: 'Clientes recorrentes'
        };

        customers.forEach(c => {
          if (!!recurringNewCustomers[c]) {
            indicatorsPieChart.push({
              label: labels[c],
              value: recurringNewCustomers[c]
            });
          }
        });

        this.setState({ indicatorsPieChart });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.recurringNewCustomers);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.recurringNewCustomers);
  }

  render() {
    const { indicatorsPieChart } = this.state;

    return (
      <IndicatorPieChart
        chartData={{
          title: 'Percentual de Recompra',
          dataType: enDataType.percent,
          indicators: indicatorsPieChart
        }}
        description={
          'Percentual entre os clientes que compraram uma única vez e os clientes que já compraram dois ou mais de seus conteúdos dentro do período que está sendo aplicado na pesquisa'
        }
        elevation={2}
      />
    );
  }
}
