/* eslint-disable max-lines */
import { Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import cashSound from 'assets/sounds/cash-register-sound.mp3';
import boletoSound from 'assets/sounds/sound_boleto.wav';
import CardList, { ICard } from 'components/Shared/CardList';
import Spinner from 'components/Shared/Spinner';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { dateFormat } from 'formatters/date';
import useWindowSize from 'hooks/useWindowSize';
import { enComponent } from 'interfaces/socket';
import { ITransaction } from 'interfaces/transaction';
import React, { useCallback, useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import socketService from 'services/socket';
import getValidWhatsPhoneNumber from '../../../helpers/getValidWhatsPhoneNumber';
import Table, { ITableColumn, ITableRow, ITableRowProps } from '../../Shared/Table';
import OnlineUsers from '../OnlineUsers/index';
import TransactionDetails from './TransactionDetails/index';
import TransactionItemsTableRender from './TransactionItemsTableRender';

const columns: ITableColumn[] = [
  {
    field: 'date',
    headerName: 'Data',
    size: { md: 1, sm: 2, xs: 1 },
    breakContent: true
  },
  {
    field: 'customer',
    headerName: 'Comprador(a)',
    size: { md: 3, sm: 4, xs: 4 }
  },
  {
    field: 'value',
    headerName: 'Valor da Venda',
    size: { md: 2, xs: 2 },
    type: 'currency'
  },
  {
    field: 'paid',
    headerName: 'Pago',
    size: { xs: 1 },
    render: (field: ITableRowProps) => <>{field.value && <SvgIcon name='tick' />}</>
  },
  {
    field: 'items',
    headerName: 'Itens',
    size: { md: 3, xs: 3 },
    render: (field: ITableRowProps) => <TransactionItemsTableRender field={field} />
  },
  {
    field: 'utms',
    headerName: 'UTMs',
    size: { md: 1, xs: 'auto' },
    hideFieldInMobile: true
  },
  {
    field: 'whatsapp',
    headerName: '',
    size: { md: 1, xs: 'auto' },
    align: 'right',
    hideFieldInMobile: true,
    render: (field: ITableRowProps) => {
      function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.cancelable = true;

        if (event.stopPropagation) {
          event.stopPropagation();
        }
      }

      return (
        <Tooltip title='Converse com seu cliente' placement='top'>
          <a
            onClick={handleClick}
            target='_blank'
            rel='noopener noreferrer'
            href={`https://api.whatsapp.com/send?phone=${field.props['phone']}`}
          >
            <SvgIcon name={field.icon} />
          </a>
        </Tooltip>
      );
    }
  }
];

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.variables.colors['tableTextColor'],
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  description: {
    color: theme.variables.colors['lightText'],
    margin: '8px 0 32px 0'
  },
  body: {
    width: '100%'
  },
  contentHeader: {
    marginBottom: theme.spacing(3)
  },
  onlineUsers: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
const RealTimeTransactions: React.FC = () => {
  const classes = useStyles();
  const [transaction, setTransaction] = useState<ITransaction>(null);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const { width: screenWidth } = useWindowSize();

  useEffect(() => {
    dashboardService.addComponent(enComponent.transactionsTable);

    return () => {
      dashboardService.removeComponent(enComponent.transactionsTable);
    };
  }, []);

  const getPaymentMethodIcon = useCallback((paymentMethod: string) => {
    if (['bankslip', 'paypal', 'free', 'pix'].includes(paymentMethod)) {
      return `pm_${paymentMethod}`;
    }

    return 'pm_creditcard';
  }, []);

  const playSoundNotification = useCallback(
    (playCreditCardSoundNotification: boolean, playBankslipSoundNotification: boolean) => {
      const enabledSound: boolean = JSON.parse(localStorage.getItem('telescope_dashboardEnabledSoundAlert'));

      if (!enabledSound) {
        return;
      }

      const creditCardSound = new Audio(cashSound);
      const bankslipSound = new Audio(boletoSound);

      if (playCreditCardSoundNotification) {
        creditCardSound.play();
      }

      if (playBankslipSoundNotification) {
        setTimeout(() => {
          bankslipSound.play();
        }, 500);
      }
    },
    []
  );

  const getPaymentMethodTooltip = useCallback((pm: string) => {
    if (pm === 'free') {
      return 'Gratuito';
    }

    if (pm === 'bankslip') {
      return 'Boleto';
    }

    if (pm === 'pix') {
      return 'Pix';
    }

    return 'Cartão';
  }, []);

  const [transactions] = useObservable(() => {
    return socketService.listen(enComponent.transactionsTable).pipe(
      rxjsOperators.filter((transaction: ITransaction[]) => !!transaction),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(t => {
        if (isNaN(t?.length)) {
          return null;
        }
        const transactionsTable: ITableRow[] = [];
        const transactionsMobileTable: ICard[] = [];

        t.forEach(sale => {
          transactionsTable.push({
            id: { value: sale.id },
            date: { value: dateFormat(new Date(sale?.date), 'dd/MM/yy HH:mm') },
            customer: { value: sale?.name, icon: `device_${sale?.device}` },
            paid: { value: sale.paid },
            value: {
              value: sale?.price || 0,
              icon: getPaymentMethodIcon(sale?.paymentMethod),
              iconTooltip: getPaymentMethodTooltip(sale?.paymentMethod)
            },
            items: { props: sale?.items },
            utms: { value: sale?.utmSource || sale?.utmMedium || sale?.utmContent || sale?.utmCampaign },
            whatsapp: { icon: 'whatsapp', props: { phone: getValidWhatsPhoneNumber(sale?.phone) } },
            onClickRowProps: sale
          });

          transactionsMobileTable.push({
            rows: [
              {
                icon: 'calendar',
                value: dateFormat(new Date(sale?.date), 'dd/MM/yy HH:mm')
              },
              {
                icon: `device_${sale?.device}`,
                value: sale?.name
              },
              {
                icon: getPaymentMethodIcon(sale?.paymentMethod),
                value: sale?.price,
                type: 'currency',
                iconTooltip: getPaymentMethodTooltip(sale?.paymentMethod)
              }
            ],
            paid: sale?.paid,
            actionButtonProps: getValidWhatsPhoneNumber(sale?.phone),
            onClickProps: sale
          });
        });

        const playCreditCardSoundNotification = t.some(
          sale => sale.paymentMethod === 'creditcard' || sale.paymentMethod === 'paypal'
        );
        const playBankslipSoundNotification = t.some(sale => sale.paymentMethod === 'bankslip');

        playSoundNotification(playCreditCardSoundNotification, playBankslipSoundNotification);

        return { transactionsTable, transactionsMobileTable };
      })
    );
  }, []);

  const handleActionButtonClick = useCallback((data: string) => {
    window.open(`https://api.whatsapp.com/send?phone=${data}`);
  }, []);

  const handleTransactionClick = useCallback((props: ITransaction) => {
    setOpenDetails(true);
    setTransaction(props);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDetails(false);
    setTransaction(null);
  }, []);

  return (
    <>
      {isNaN(transactions?.transactionsTable.length) ? (
        <div className={classes.spinner}>
          <Spinner size={40} />
        </div>
      ) : (
        <>
          <Grid container justify='space-between' className={classes.contentHeader}>
            <Grid item>
              <Typography variant='subtitle1' className={classes.title}>
                Transações em Tempo Real
              </Typography>

              {transactions?.transactionsTable.length === 0 ? (
                <Typography variant='body2' className={classes.description}>
                  Não encontramos nenhuma transação.
                </Typography>
              ) : (
                <Typography variant='body2' className={classes.description}>
                  Últimas 200 transações
                </Typography>
              )}
            </Grid>

            <Grid item className={classes.onlineUsers}>
              <OnlineUsers />
            </Grid>
          </Grid>
          {transactions?.transactionsTable.length > 0 && screenWidth > 600 ? (
            <Table
              columns={columns}
              rows={transactions?.transactionsTable}
              onClick={handleTransactionClick}
              onClickPropName='onClickRowProps'
            />
          ) : (
            <CardList
              cards={transactions?.transactionsMobileTable}
              onClick={handleTransactionClick}
              actionButton={{
                icon: 'whatsapp',
                onClick: handleActionButtonClick
              }}
            />
          )}
        </>
      )}
      <TransactionDetails open={openDetails} transaction={transaction} onClose={handleClose} />
    </>
  );
};

export default RealTimeTransactions;
