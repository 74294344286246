import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const PendingSales = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.pendingSales);

    return () => {
      dashboardService.removeComponent(enComponent.pendingSales);
    };
  }, []);

  const [pendingSales] = useObservable<any>(() => {
    return socketService.listen(enComponent.pendingSales).pipe(
      rxjsOperators.filter(cancellations => !!cancellations),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map((c: any) => {
        return {
          value: c.value,
          performance: c.performance
        };
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Quanto tenho em vendas abertas?',
        value: pendingSales?.value,
        dataType: enDataType.currency
      }}
      oldIndicatorDataValue={0}
      description='Valor bruto que representa o montante das vendas que não tiveram o pagamento concluído, sem considerar descontos de taxas e afins'
    >
      <MetricPerformance performance={pendingSales?.performance || 0} reverse />
    </IndicatorPanel>
  );
};

export default PendingSales;
