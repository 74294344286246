import { makeStyles } from '@material-ui/core';
import { SvgIcon } from 'components/Shared/SvgIcon';
import Switch, { SwitchProps } from 'components/Shared/Switch/Switch';
import TooltipDescription from 'components/Shared/TooltipDescription';
import React from 'react';

export interface OptionProps extends SwitchProps {
  icon: string;
  name: string;
  explanation?: string;
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    background: '#fff',
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow: '0px 0px 15px 8px rgba(0, 0, 0, 0.2)'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    '& > svg': {
      width: '25px',
      height: '25px',
      fill: theme.variables.colors['lightText']
    },
    '& > svg > path': {
      fill: theme.variables.colors['lightText']
    }
  },
  activated: {
    '& > svg': {
      fill: theme.palette.secondary.main
    },
    '& > svg > path': {
      fill: theme.palette.secondary.main
    }
  }
}));

const Option: React.FC<OptionProps> = ({ icon, name, explanation, checked, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.label} ${checked ? classes.activated : ''}`}>
        <SvgIcon name={icon} />
        {name}
        {explanation && <TooltipDescription text={explanation} />}
      </div>
      <Switch name={name} checked={checked} {...rest} />
    </div>
  );
};

export default Option;
