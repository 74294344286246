import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import clubeBlackVariables from './clubeBlack/variables';
import overrides from './overrides';
import variables from './variables';

const background = {
  default: '#F9FAFB'
};

export const theme = createMuiTheme({
  palette: {
    background,
    primary: {
      light: '#ffffff',
      main: '#FFFFFF',
      dark: '#cccccc',
      contrastText: '#596375'
    },
    secondary: {
      light: '#4ec485',
      main: variables.colors['primaryPurple'],
      dark: '#011b39',
      contrastText: '#fff'
    }
  },
  variables,
  overrides
});

export const clubeBlackTheme = createMuiTheme({
  palette: {
    background,
    primary: {
      light: '#ffffff',
      main: '#FFFFFF',
      dark: '#cccccc',
      contrastText: '#596375'
    },
    secondary: {
      light: '#4ec485',
      main: clubeBlackVariables.colors['primaryPurple'],
      dark: '#011b39',
      contrastText: '#fff'
    }
  },
  variables: clubeBlackVariables,
  overrides
});
