import { Paper, Theme } from '@material-ui/core';
import TooltipDescription from 'components/Shared/TooltipDescription';
import { WithStyles } from 'decorators/withStyles';
import { enComponent } from 'interfaces/socket';
import React, { PureComponent } from 'react';
import CountUp from 'react-countup';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

export interface IState {
  onlineUsers: number;
  previousOnlineUsersValue: number;
}

export interface IProps {
  classes?: any;
}

@WithStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(4)}px`
  },
  valueContainer: {
    display: 'flex',
    width: 126,
    justifyContent: 'center'
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
    width: 110,
    textAlign: 'right',
    color: theme.variables.colors['text']
  },
  value: {
    fontSize: 32,
    fontWeight: 600,
    color: theme.variables.colors['text']
  },
  tooltipContainer: {
    padding: 4
  }
}))
export default class OnlineUsers extends PureComponent<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { onlineUsers: 0, previousOnlineUsersValue: 0 };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.onlineUsers)
      .pipe(
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe((onlineUsers: number) => {
        this.setState({
          previousOnlineUsersValue: this.state.onlineUsers,
          onlineUsers
        });
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.onlineUsers);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.onlineUsers);
  }

  formatNumber(n: number) {
    return n.toLocaleString('pt-BR');
  }

  render() {
    const { onlineUsers, previousOnlineUsersValue } = this.state;
    const { classes } = this.props;

    return (
      <Paper className={classes.root} elevation={2}>
        <div className={classes.container}>
          <label className={classes.label}>Pessoas Online no Checkout</label>
          <div className={classes.valueContainer}>
            <label className={classes.value}>
              <CountUp
                start={previousOnlineUsersValue}
                end={onlineUsers}
                duration={0.7}
                formattingFn={this.formatNumber}
              />
            </label>
          </div>
        </div>
        <div className={classes.tooltipContainer}>
          <TooltipDescription text='Quantidade de pessoas online no checkout. Este dado é atualizado em tempo real.' />
        </div>
      </Paper>
    );
  }
}
