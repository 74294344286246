import React, { PureComponent } from 'react';
import authService from 'services/auth';
import rxjsOperators from 'rxjs-operators';
import { ELITE_BELTS_ID, enRoleAction } from 'interfaces/userToken';

export default class UserReport extends PureComponent {
  componentDidMount() {
    authService
      .isAuthenticated()
      .pipe(
        rxjsOperators.filter((isAuthenticated: boolean) => !!isAuthenticated),
        rxjsOperators.switchMap(() => authService.getUser()),
        rxjsOperators.filter(user => !!user),
        rxjsOperators.combineLatest(authService.canAccess(enRoleAction.filterByProducer)),
        rxjsOperators.filter(
          ([user, hasEduzzGroupRoles]) => (!!user.belt && ELITE_BELTS_ID.includes(user.belt.id)) || hasEduzzGroupRoles
        ),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(() => {
        console.log('Show User Report');

        const script = document.createElement('script');

        script.src = 'https://sak.userreport.com/eduz1/launcher.js';
        script.async = true;
        script.id = 'userreport-launcher-script';

        document.head.appendChild(script);
      });

    authService
      .getUser()
      .pipe(
        rxjsOperators.filter(user => !user),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(() => {
        console.log('Remove User Report');

        const userReportScriptElement = document.getElementById('userreport-launcher-script');
        if (userReportScriptElement) {
          userReportScriptElement.remove();
        }

        const userReportIFrameElement = document.getElementById('crowd-shortcut');
        if (userReportIFrameElement) {
          userReportIFrameElement.remove();
        }
      });
  }

  render() {
    return <div />;
  }
}
