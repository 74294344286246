import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  snackbar: {
    position: 'absolute',
    right: '25px',
    bottom: '150px',
    height: '50px',
    minWidth: '250px',
    padding: '10px',
    borderRadius: '15px',
    zIndex: 1301,
    gap: '6px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    color: '#fff'
  },
  success: {
    backgroundColor: theme.palette.success.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  svg: {
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      fill: '#fff'
    }
  }
}));

export default useStyles;
