import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import Spinner from 'components/Shared/Spinner';
import { ISavedFilter } from 'interfaces/filter';
import React, { Fragment } from 'react';
import useStyles from './styles';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleDeleteFilter: (filterId: string) => Promise<void>;
  onSelectFilter: (savedFilter: ISavedFilter) => void;
  savedFilters: ISavedFilter[];
  isLoadingFilters: boolean;
}

const ListFilters: React.FC<IProps> = ({
  onSelectFilter,
  handleClose,
  isOpen,
  savedFilters,
  handleDeleteFilter,
  isLoadingFilters
}) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>Filtros salvos</DialogTitle>
      {isLoadingFilters ? (
        <div className={classes.loaderWrapper}>
          <Spinner size={35} />
        </div>
      ) : (
        <DialogContent>
          <List dense className={classes.list}>
            {!savedFilters?.length ? (
              <ListItem>
                <ListItemText className={classes.emptyMessage} primary='Você ainda não tem nenhum filtro salvo!' />
              </ListItem>
            ) : (
              <>
                <ListItem>
                  <Typography variant='subtitle2'>Filtros</Typography>
                </ListItem>
                <Divider component='li' className={classes.divider} />
              </>
            )}
            {savedFilters.map(savedFilter => (
              <Fragment key={savedFilter._id}>
                <Tooltip title='Aplicar filtro' placement='top'>
                  <ListItem button onClick={() => onSelectFilter(savedFilter)}>
                    <ListItemText primary={savedFilter.name} />
                    <ListItemSecondaryAction>
                      <Tooltip title='Deletar filtro' placement='top'>
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={async () => {
                            await handleDeleteFilter(savedFilter._id);
                          }}
                          className={classes.deleteButton}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Tooltip>
                <Divider component='li' className={classes.divider} />
              </Fragment>
            ))}
          </List>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ListFilters;
