import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DrawerContext from 'components/contexts/DrawerContext/context';
import routes from 'components/Router/routes';
import { SvgIcon } from 'components/Shared/SvgIcon';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  active: {
    backgroundColor: theme.variables.colors['menuActive'],
    '&:hover': {
      backgroundColor: theme.variables.colors['menuActive']
    }
  },
  logo: {
    maxWidth: 135,
    margin: 32
  },
  listText: {
    color: '#FFF',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    lineHeight: '150%'
  },
  item: {
    paddingLeft: 30,
    '& svg': {
      width: 24,
      height: 24,
      marginRight: 20
    }
  }
}));

const DrawerList: React.FC = ({ children }) => {
  const classes = useStyles();

  const history = useHistory();
  const {
    location: { pathname }
  } = history;

  const { toggleDrawer } = useContext(DrawerContext);

  const handleClickItem = (path: string) => {
    toggleDrawer();
    history.push(path);
  };

  return (
    <>
      <div className={classes.logo}>
        <SvgIcon name='logo' />
      </div>
      <List>
        {children}
        {routes.map((page, index) => (
          <ListItem
            button
            onClick={() => handleClickItem(page.path)}
            className={`${classes.item} ${pathname === page.path ? classes.active : ''}`}
            key={`drawer-option-${index}`}
          >
            <SvgIcon name={page.icon} />
            <ListItemText primary={page.name} classes={{ primary: classes.listText }} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default DrawerList;
