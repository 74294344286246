import { theme } from 'assets/theme';
import axios from 'axios';
import routes from 'components/Router/routes';
import * as rxjs from 'rxjs';
import { switchMap } from 'rxjs-operators';
import { ANALYTICS_URL, API_ENDPOINT, EDUZZ_ACCOUNTS_ENV } from 'settings';
import { EDUZZ_ACCOUNTS_PARTNER } from '../settings';
import tokenService, { TokenService } from './token';

window.location.href = ANALYTICS_URL;
export class LoginService {
  constructor(private tokenService: TokenService) {
    if (!routes.map(route => route.path).includes(window.location.pathname)) {
      window.location.pathname = '/';
    }

    const lct = window.location;

    const login$: rxjs.Observable<string> = (window as any).Eduzz.Accounts.login(EDUZZ_ACCOUNTS_PARTNER, {
      bg: `${lct.protocol}//${lct.hostname}:${lct.port}/images/telescope-bg.jpg`,
      logo: `${lct.protocol}//${lct.hostname}:${lct.port}/images/telescope-analytics-logo.png`,
      btnColor: theme.variables.colors['primaryPurple'],
      env: EDUZZ_ACCOUNTS_ENV
    });

    const urlParams = new URLSearchParams(window.location.search);

    const isSupport: boolean = urlParams.get('support') === 'true';
    const supportId: string = urlParams.get('supportId');

    const params: any = {};

    if (isSupport) {
      params.isSupport = isSupport;
    }

    if (supportId) {
      params.supportId = supportId;
    }

    login$
      .pipe(
        switchMap(async (token: string) => {
          const { data } = await axios.post<any>('/auth/login', { token, ...params }, { baseURL: API_ENDPOINT });
          return data;
        })
      )
      .subscribe(({ token }) => {
        this.tokenService.setToken(token as string);

        window.location.href = ANALYTICS_URL;
      });
  }

  public logout() {
    (window as any).Eduzz.Accounts.logout({
      env: EDUZZ_ACCOUNTS_ENV
    });

    this.tokenService.clearToken();
  }
}

const loginService = new LoginService(tokenService);
export default loginService;
