import { DeepReadonly } from 'helpers/immutable';
import { enRoleAction, IUserToken } from 'interfaces/userToken';
import * as rxjs from 'rxjs';
import rxjsOperators from 'rxjs-operators';
import loginService from './login';
import tokenService, { TokenService } from './token';

export class AuthService {
  private user$: rxjs.Observable<DeepReadonly<IUserToken>>;

  constructor(private tokenService: TokenService) {
    this.user$ = this.tokenService.getToken().pipe(
      rxjsOperators.map(token => {
        if (!token) return null;

        const user = this.tokenService.decode(token);

        if (!user) return null;
        return user;
      }),
      rxjsOperators.catchError(() => {
        return rxjs.of(null);
      }),
      rxjsOperators.shareReplay(1)
    );
  }

  public getUser(): rxjs.Observable<DeepReadonly<IUserToken>> {
    return this.user$;
  }

  public canAccess(...roles: string[]): rxjs.Observable<boolean> {
    return this.getUser().pipe(
      rxjsOperators.map(user => {
        if (!user) return false;

        if (!roles || roles.length === 0) return true;

        return roles.some(r => user.roles.includes(r));
      })
    );
  }

  public getEduzzGroupRoles(): string[] {
    return [enRoleAction.viewLoadTime, enRoleAction.viewUptime, enRoleAction.filterByProducer];
  }

  public isAuthenticated(): rxjs.Observable<boolean> {
    return this.tokenService.getToken().pipe(rxjsOperators.map(token => !!token));
  }

  public logout() {
    loginService.logout();
  }
}

const authService = new AuthService(tokenService);
export default authService;
