import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { IBankslipConversion } from 'interfaces/bankslipConversion';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { socketService } from 'services/socket';

const BankslipConversion = () => {
  const [previousBankslipConversion, setPreviousBankslipConversion] = useState(0);

  useEffect(() => {
    dashboardService.addComponent(enComponent.bankslipConversion);

    return () => {
      dashboardService.removeComponent(enComponent.bankslipConversion);
    };
  }, []);

  const [bankslipConversion] = useObservable<IBankslipConversion>(() => {
    return socketService.listen(enComponent.bankslipConversion).pipe(
      rxjsOperators.filter((conversion: IBankslipConversion) => !!conversion),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(bc => {
        const bankslipConversion: IBankslipConversion = {
          bankslipConversion: 0,
          performance: 0
        };

        setPreviousBankslipConversion(bankslipConversion.bankslipConversion);

        bankslipConversion.bankslipConversion = bc.bankslipConversion;
        bankslipConversion.performance = bc.performance;

        return bankslipConversion;
      })
    );
  }, []);

  const [disableValue] = useObservable<boolean>(() => {
    return filterService.getFilter().pipe(
      rxjsOperators.logError(),
      rxjsOperators.map(filter => filter.ignoreBankslip)
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Conversão de boletos',
        value: bankslipConversion?.bankslipConversion,
        dataType: enDataType.percent,
        icon: 'bankslipIndicatorPanel'
      }}
      oldIndicatorDataValue={previousBankslipConversion}
      description='Esse percentual mostra a taxa de conversão de boletos, baseado na quantidade de boletos gerados e boletos pagos'
      disableValue={disableValue}
    >
      <MetricPerformance performance={bankslipConversion?.performance || 0} />
    </IndicatorPanel>
  );
};

export default BankslipConversion;
