import { Paper, Theme, Typography } from '@material-ui/core';
import { WithStyles } from 'decorators/withStyles';
import getWeekDay from 'helpers/getWeekDay';
import { ISubscription } from 'interfaces/subscriptions';
import get from 'lodash/get';
import moment from 'moment';
import React, { PureComponent } from 'react';

interface IProps {
  payload?: ITooltip[];
  classes?: any;
}

interface ITooltip {
  color: string;
  fill: string;
  value: number;
  name: string;
  payload: IPayload;
  dataKey: string;
}

type IPayload = ISubscription;

@WithStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    width: 200
  },
  values: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 0',
    '& svg': {
      width: 30,
      marginRight: 12
    }
  },
  squad: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 8
  },
  label: {
    display: 'flex',
    flexDirection: 'row'
  }
}))
export default class CustomTooltip extends PureComponent<IProps, {}> {
  render() {
    const { classes } = this.props;
    const date = get(this.props, 'payload[0].payload.date', null);

    const config = {
      overdue: {
        name: 'Atrasados',
        color: '#FFCC31'
      },
      active: {
        name: 'Ativos',
        color: '#07B990'
      },
      suspend: {
        name: 'Suspensos',
        color: '#339DCD'
      },
      canceled: {
        name: 'Cancelados',
        color: '#E55039'
      },
      new: {
        name: 'Novos',
        color: '#837ED3'
      }
    };

    return (
      <Paper className={classes.root}>
        <Typography variant='body2'>
          {getWeekDay(new Date(date).getDay())}, <br />
          {moment(date).format('DD/MM/YYYY HH:mm')}
        </Typography>
        {this.props.payload
          ? this.props.payload.map((payload: ITooltip, index: number) => (
              <div className={classes.values} key={index}>
                <Typography variant='body2' style={{ color: payload.color }} className={classes.label}>
                  <span className={classes.squad} style={{ backgroundColor: payload.color }} />
                  {config[payload.dataKey].name} - {payload.value.toLocaleString('pt-BR')}
                </Typography>
              </div>
            ))
          : null}
      </Paper>
    );
  }
}
