import { Grid, makeStyles } from '@material-ui/core';
import DrawerProvider from 'components/contexts/DrawerContext';
import UserContext from 'components/contexts/UserContext/context';
import Filter from 'components/Dashboard/Filters';
import ScreenActions from 'components/Pages/Admin/Metrics/ScreenActions';
import { SHOWCASE_STEPS } from 'components/Pages/Admin/Metrics/showcaseSteps';
import { NewSalesNotifications } from 'components/Pages/Admin/NewSalesNotification';
import AnalyticsMigrationInfo from 'components/Shared/AnalyticsAlert';
import Footer from 'components/Shared/Footer';
import Hotjar from 'components/Shared/Hotjar';
import ShowcaseLoader from 'components/Shared/Showcase/Loader';
import UserReport from 'components/Shared/UserReport';
import { ShowcaseAction, ShowcaseActionType, showcaseReducer, ShowcaseState, useShowcase } from 'hooks/useShowcase';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { showToolbar } from 'services/eduzzToolbar';
import NavigationDrawer from '../NavigationDrawer';
import AppToolbar from '../Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100vw',
    height: '100vh',
    background: theme.variables.colors['background']
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '300px'
    }
  },
  page: {
    padding: theme.variables.contentPadding,
    [theme.breakpoints.up('sm')]: {
      padding: theme.variables.contentPaddingUpSm
    },
    '@media screen and (max-width: 480px)': {
      padding: 24
    }
  },
  mainWrapper: {
    padding: '0px 0 30px 0'
  },
  filterGrid: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important'
    }
  }
}));

const Showcase = lazy(() => import(/* webpackChunkName: "showcase" */ 'components/Shared/Showcase'));

function saveShowcaseToStorage() {
  localStorage.setItem('news_showcase_seen', 'true');
}

function saveStorageReducer(state: ShowcaseState, action: ShowcaseAction) {
  if (action.type === ShowcaseActionType.HANDLE_CLOSE) {
    saveShowcaseToStorage();
  }
  return showcaseReducer(state, action);
}

const hideFilter = ['/configuracoes'];

const AppWrapper: React.FC = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [disableFilter, setDisableFilter] = useState(hideFilter.includes(window.location.pathname));

  const { user } = useContext(UserContext);

  const showcaseHelperProps = useShowcase({
    reducer: saveStorageReducer
  });

  useEffect(() => {
    setDisableFilter(hideFilter.includes(pathname));
  }, [pathname]);

  useEffect(() => {
    const userViewedShowcase = localStorage.getItem('news_showcase_seen');

    if (!userViewedShowcase) {
      showcaseHelperProps.handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) showToolbar(user);
  }, [user]);

  return (
    <main className={classes.content}>
      <DrawerProvider>
        <NavigationDrawer />
        <AnalyticsMigrationInfo />
        <AppToolbar />
        <div className={classes.page}>
          <Hotjar />
          <UserReport />
          <NewSalesNotifications />
          <Grid container spacing={3} className={classes.mainWrapper}>
            <Suspense fallback={<ShowcaseLoader />}>
              {showcaseHelperProps.isOpen && (
                <Showcase title='Novidades do Telescope' steps={SHOWCASE_STEPS} {...showcaseHelperProps} />
              )}
            </Suspense>
            <Grid item xs={12}>
              <ScreenActions handleOpen={showcaseHelperProps.handleOpen} />
            </Grid>

            {disableFilter ? null : (
              <Grid item xs={12} id='filter' className={classes.filterGrid}>
                <Filter />
              </Grid>
            )}
            {children}
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </DrawerProvider>
    </main>
  );
};

export default AppWrapper;
