import { Grid, Paper, Theme } from '@material-ui/core';
import HorizontalBarChart from 'components/Shared/HorizontalBarChart';
import { IndicatorPieChart } from 'components/Shared/PieChart';
import { WithStyles } from 'decorators/withStyles';
import { enDataType } from 'interfaces/dashboard';
import { IIndicatorChart, IIndicatorHorizontalBarChart } from 'interfaces/indicator';
import { enComponent } from 'interfaces/socket';
import {
  enContentType,
  ITopSallingProducts,
  ITopSallingProductsByContents,
  ITopSallingProductsByType
} from 'interfaces/topSallingProducts';
import React, { PureComponent } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

interface IProps {
  classes?: any;
}

interface IState {
  indicatorsBarChart: IIndicatorHorizontalBarChart[];
  indicatorsPieChart: IIndicatorChart[];
}

@WithStyles((theme: Theme) => ({
  container: {
    height: '100%'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    '@media screen and (max-width: 960px)': {
      flexDirection: 'column'
    },
    height: '100%'
  },
  divider: {
    width: 1,
    borderLeft: 'solid 1px',
    color: theme.variables.colors['indicatorChartSeparator'],
    '@media screen and (max-width: 960px)': {
      width: '100%',
      height: 1,
      borderTop: 'solid 1px'
    }
  }
}))
export default class TopSallingProducts extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      indicatorsBarChart: [{ data: [] }],
      indicatorsPieChart: [{ label: '', value: null, color: '' }]
    };
  }

  componentDidMount() {
    socketService
      .listen(enComponent.topSallingProducts)
      .pipe(
        rxjsOperators.filter((topSallingProducts: ITopSallingProducts) => !!topSallingProducts),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.bindComponent(this),
        rxjsOperators.logError()
      )
      .subscribe(topSallingProducts => {
        this.setTopSallingProducts(topSallingProducts.topContents);
        this.setTopSallingByContentType(topSallingProducts.topProductsType);
      });
  }

  componentWillMount() {
    dashboardService.addComponent(enComponent.topSallingProducts);
  }

  componentWillUnmount() {
    dashboardService.removeComponent(enComponent.topSallingProducts);
  }

  setTopSallingProducts = (topSallingProducts: ITopSallingProductsByContents[]) => {
    const indicatorsBarChart: IIndicatorHorizontalBarChart[] = [{ data: [] }];

    topSallingProducts.forEach(product => {
      indicatorsBarChart[0].data.push({
        label: product.title,
        total: product.value.total,
        percentage: product.value.percentage
      });
    });

    this.setState({ indicatorsBarChart });
  };

  setTopSallingByContentType = (topSallingProductsByContentType: ITopSallingProductsByType[]) => {
    const indicators: IIndicatorChart[] = [];

    topSallingProductsByContentType.sort((a, b) => a.type - b.type);

    const labels = {
      [enContentType.DIGITAL]: 'Digital',
      [enContentType.JOB]: 'Serviço',
      [enContentType.PHYSICAL]: 'Físico',
      [enContentType.TICKET]: 'Evento',
      [enContentType.PROJECT]: 'Projeto'
    };

    Object.values(enContentType).forEach((type: string) => {
      const contentType = topSallingProductsByContentType.find(ct => ct.type === Number(type));
      if (contentType) {
        indicators.push({
          label: labels[type] || type,
          value: contentType.value.percentage
        });
      }
    });

    indicators.sort((a, b) => b.value - a.value);

    this.setState({ indicatorsPieChart: indicators });
  };

  render() {
    const { classes } = this.props;
    const { indicatorsBarChart, indicatorsPieChart } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={2}>
          <Grid item xs={12} md={12} lg={6}>
            <IndicatorPieChart
              chartData={{
                title: 'Tipos de Produto Mais Vendidos',
                dataType: enDataType.percent,
                indicators: indicatorsPieChart
              }}
              elevation={0}
            />
          </Grid>
          <div className={classes.divider} />
          <Grid item xs={12} md={12} lg={6}>
            <HorizontalBarChart
              chartData={{
                title: 'Produtos Mais Vendidos',
                dataType: enDataType.numeric,
                indicators: indicatorsBarChart
              }}
              elevation={0}
              showOptions={false}
              enableSwitch
            />
          </Grid>
        </Paper>
      </div>
    );
  }
}
