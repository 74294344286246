import {
  Dialog,
  DialogProps,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { SvgIcon } from 'components/Shared/SvgIcon';
import { dateFormat } from 'formatters/date';
import { ITransaction } from 'interfaces/transaction';
import CloseIcon from 'mdi-react/CloseIcon';
import React, { useCallback } from 'react';
import formatData from '../../../../helpers/formatData';
import getValidWhatsPhoneNumber from '../../../../helpers/getValidWhatsPhoneNumber';

interface ITransactionDetailsProps extends DialogProps {
  transaction: ITransaction;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    width: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100vh'
    }
  },
  dialogTitle: {
    fontSize: '0.875rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.variables.colors['tableTextColor'],
    marginBottom: theme.spacing(2)
  },
  label: {
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.variables.colors['tableTextColor'],
    marginBottom: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  section: {
    padding: `${theme.spacing(2)}px 0`
  },
  alignSideBySide: {
    display: 'flex',
    '& > div': {
      marginRight: 40
    }
  },
  text: {
    paddingBottom: 8,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  textIcon: {
    display: 'flex',
    paddingBottom: 8,
    '& svg': {
      width: 18,
      marginRight: theme.spacing(1),
      paddingBottom: 8
    }
  },
  closeButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    padding: 0,
    '& svg': {
      color: '#C4C4C4'
    }
  },
  link: {
    cursor: 'pointer',
    display: 'flex'
  }
}));
const TransactionDetails: React.FC<ITransactionDetailsProps> = ({ transaction, onClose, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getPaymentMethodIcon = useCallback((paymentMethod: string) => {
    if (['bankslip', 'paypal', 'free', 'pix'].includes(paymentMethod)) {
      return `pm_${paymentMethod}`;
    }

    return 'pm_creditcard';
  }, []);

  const formatPhoneNumber = useCallback((phone: string) => {
    if (!phone) {
      return phone;
    }
    return `(${phone.slice(0, 2)}) ****${phone.slice(-4)}`;
  }, []);

  const goToWhatsApp = useCallback(() => {
    window.open(`https://api.whatsapp.com/send?phone=${getValidWhatsPhoneNumber(transaction.phone)}`);
  }, [transaction]);

  return (
    <Dialog onClose={onClose} {...rest} fullScreen={fullScreen}>
      <Paper elevation={2} className={classes.container}>
        <div className={classes.closeButtonWrapper}>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon size={20} />
          </IconButton>
        </div>

        <Typography variant='subtitle1' className={classes.dialogTitle}>
          Detalhe da Transação
        </Typography>

        <section className={`${classes.section} ${classes.alignSideBySide}`}>
          <div>
            <Typography variant='body2' className={classes.label}>
              Data
            </Typography>
            <Typography className={classes.text} variant='body2'>
              {dateFormat(new Date(transaction?.date), 'dd/MM/yy HH:mm')}
            </Typography>
          </div>
          <div>
            <Typography variant='body2' className={classes.label}>
              Valor da Venda
            </Typography>
            <div className={classes.textIcon}>
              <SvgIcon name={getPaymentMethodIcon(transaction?.paymentMethod)} />
              <Typography className={classes.text} variant='body2'>
                {formatData(transaction?.price, 'currency')}
              </Typography>
            </div>
          </div>
          <div>
            <Typography variant='body2' className={classes.label}>
              Pago?
            </Typography>
            <Typography variant='body2' className={classes.text}>
              { transaction?.paid ? 'Sim' : 'Não' }
            </Typography>
          </div>
        </section>

        <Divider />

        <section className={classes.section}>
          <div>
            <Typography variant='body2' className={classes.label}>
              Comprador(a)
            </Typography>
            <div className={classes.textIcon}>
              <SvgIcon name={`device_${transaction?.device}`} />
              <Typography className={classes.text} variant='body2'>
                {transaction?.name}
              </Typography>
            </div>
            <div className={classes.textIcon}>
              <SvgIcon name={'email'} />
              <Typography className={classes.text} variant='body2'>
                {transaction?.email}
              </Typography>
            </div>
            <div className={classes.textIcon}>
              <span className={classes.link} onClick={goToWhatsApp}>
                <SvgIcon name={'whatsapp'} />
                <Typography className={classes.text} variant='body2'>
                  {formatPhoneNumber(transaction?.phone)}
                </Typography>
              </span>
            </div>
          </div>
        </section>

        <Divider />

        <section className={classes.section}>
          <div>
            <Typography variant='body2' className={classes.label}>
              Todos os itens
            </Typography>
            {transaction?.items.map((item, i) => (
              <Typography key={i} className={classes.text} variant='body2'>
                {item.title}
              </Typography>
            ))}
          </div>
        </section>

        <Divider />

        <section className={classes.section}>
          <div>
            <Typography variant='body2' className={classes.label}>
              Código afiliado
            </Typography>
            <Typography className={classes.text} variant='body2'>
              {transaction?.affiliateId}
            </Typography>
          </div>
        </section>

        <Divider />

        <section className={classes.section}>
          <div>
            <Typography variant='body2' className={classes.label}>
              UTMs
            </Typography>
            {transaction?.utmSource && (
              <Typography className={classes.text} variant='body2'>
                {transaction.utmSource}
              </Typography>
            )}
            {transaction?.utmMedium && (
              <Typography className={classes.text} variant='body2'>
                {transaction.utmMedium}
              </Typography>
            )}
            {transaction?.utmCampaign && (
              <Typography className={classes.text} variant='body2'>
                {transaction.utmCampaign}
              </Typography>
            )}
            {transaction?.utmContent && (
              <Typography className={classes.text} variant='body2'>
                {transaction.utmContent}
              </Typography>
            )}
          </div>
        </section>
      </Paper>
    </Dialog>
  );
};

export default TransactionDetails;
