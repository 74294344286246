import { Paper, Theme, Typography } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { dateFormat } from 'formatters/date';
import formatNumber from 'helpers/formatNumber';
import getWeekDay from 'helpers/getWeekDay';
import { enDataType } from 'interfaces/dashboard';
import get from 'lodash/get';
import React from 'react';

interface IProps {
  label: string;
  dataType: enDataType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1)
    },
    value: {
      textTransform: 'capitalize'
    }
  })
);

const CustomTooltip: React.FC<IProps> = props => {
  const classes = useStyles();

  function formatValue(value = 0) {
    if (props.dataType === enDataType.percent) {
      return value + '%';
    }

    if (props.dataType === enDataType.second) {
      return value + 's';
    }

    if (props.dataType === enDataType.currency) {
      return formatNumber(value, 'R$');
    }

    if (props.dataType === enDataType.numeric) {
      return value.toLocaleString('pt-BR');
    }

    return value;
  }

  const date = new Date(props.label);
  const value = get(props, 'payload[0].value', 0);
  const name = get(props, 'payload[0].name', null);

  return (
    <Paper className={classes.root}>
      <Typography variant='body2'>
        {getWeekDay(date.getDay())}, <br />
        {dateFormat(date, 'dd/MM/yyyy HH:mm')}
      </Typography>
      <Typography variant='subtitle1' className={classes.value}>
        {name}: {formatValue(value || 0)}
      </Typography>
    </Paper>
  );
};

export default CustomTooltip;
