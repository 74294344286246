export default function getWeekDay(index: number) {
  const weekDay = new Array(7);
  weekDay[0] = 'Domingo';
  weekDay[1] = 'Segunda-feira';
  weekDay[2] = 'Terça-feira';
  weekDay[3] = 'Quarta-feira';
  weekDay[4] = 'Quinta-feira';
  weekDay[5] = 'Sexta-feira';
  weekDay[6] = 'Sábado';

  return weekDay[index];
}
