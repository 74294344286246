import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  '@keyframes blink': {
    '0%': {
      opacity: 0.6,
      boxShadow: 'none'
    },
    '15%': {
      opacity: 0.7,
      boxShadow: '0px 0px 15px -7px rgba(244,67,54,0.5)'
    },
    '25%': {
      opacity: 0.9,
      boxShadow: '0px 0px 15px 5px rgba(244,67,54,0.8)'
    },
    '50%': {
      opacity: 1,
      boxShadow: '0px 0px 15px 8px rgba(244,67,54,0.8)'
    },
    '100%': {
      opacity: 0.6,
      boxShadow: 'none'
    }
  },
  dot: {
    width: '10px',
    height: '10px',
    marginInline: '15px',
    backgroundColor: '#ff1100',
    animation: '$blink .8s linear infinite',
    borderRadius: '50%'
  }
}));

const RedDot: React.FC = () => {
  const classes = useStyles();

  return <div className={classes.dot} />;
};

export default RedDot;
