export interface IRoutes {
  path: string;
  name: string;
  icon: string;
}

const routes = [
  {
    path: '/',
    name: 'Métricas',
    icon: 'metrics'
  },
  {
    path: '/transacoes',
    name: 'Transações',
    icon: 'transactions'
  },
  {
    path: '/configuracoes',
    name: 'Configurações',
    icon: 'settings'
  }
];

export default routes;
