/* eslint-disable max-lines */
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Save, Star } from '@material-ui/icons';
import axios from 'axios';
import ToastContext, { enAlertSeverity } from 'components/contexts/ToastContext/context';
import Alert from 'components/Shared/Alert';
import Switch from 'components/Shared/Switch/Switch';
import useWindowSize from 'hooks/useWindowSize';
import { enViewAs, IFilterModel, ISavedFilter } from 'interfaces/filter';
import { IProduct } from 'interfaces/product';
import { enRoleAction } from 'interfaces/userToken';
import moment, { Moment } from 'moment';
import Raven from 'raven-js';
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import authService from 'services/auth';
import filterService from 'services/filter';
import socketService from 'services/socket';
import tokenService from 'services/token';
import { API_ENDPOINT } from 'settings';
import Calendar from '../Calendar';
import List from '../Products/List';
import ListFilters from './ListFilters';
import SaveFilters from './SaveFilters';
import useStyles from './styles';

export interface IDateChangeEvent {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IProps {
  responsiveSubmit?: () => void;
}

const CancelToken = axios.CancelToken;
let cancel: () => void;

const Form: React.FC<IProps> = ({ responsiveSubmit }) => {
  const classes = useStyles();
  const { showAlert } = useContext(ToastContext);
  const { width } = useWindowSize();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isOpenedProductsList, setIsOpenedProductsList] = useState<boolean>(false);
  const [isOpenedSavedFilters, setIsOpenedSavedFilters] = useState<boolean>(false);
  const [isOpenedSaveFilterModal, setIsOpenedSaveFilterModal] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
  const [shouldClearSelectedProducts, setShouldClearSelectedProducts] = useState<boolean>(false);
  const [socketIsActive, setSocketIsActive] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [model, setModel] = useState<IFilterModel | null>(filterService.initialFilter);
  const [userToken, setUserToken] = useState<string | null>(null);

  const initialFilter = filterService.initialFilter;

  const handleChangeViewAs = async (event: ChangeEvent<{ value: enViewAs }>) => {
    setModel(prevModel => ({ ...prevModel, viewAs: event.target.value, productIds: [] }));
    setShouldClearSelectedProducts(prevState => !prevState);
    setProducts([]);
    if (event.target.value === enViewAs.coproducer && ((isAdmin && model.customerId) || !isAdmin)) {
      setIsOpenedProductsList(true);
    }
    await getProducts(model?.customerId, event.target.value);
  };

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setModel(prevModel => ({ ...prevModel, [event.target.name]: !event.target.checked }));
  };

  const toggleProductsList = (isOpen: boolean) => {
    setIsOpenedProductsList(isOpen);
  };

  const getProducts = useCallback(
    async (customerId?: number, viewAs?: enViewAs) => {
      if (!customerId || !userToken) return;
      setIsLoadingProducts(true);

      try {
        if (cancel) {
          cancel();
        }
        const { data } = await axios.get<IProduct[]>(
          `/product/${viewAs === enViewAs.producer ? '' : `${viewAs}/`}${customerId}`,
          {
            baseURL: API_ENDPOINT,
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${userToken}`
            },
            cancelToken: new CancelToken(c => {
              cancel = c;
            })
          }
        );

        setIsLoadingProducts(false);

        if (!data.length) {
          setOpenAlert(true);
          return;
        }

        setProducts(data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          showAlert('Algo deu errado!!', enAlertSeverity.error);
          Raven.captureException(error);
          console.error(error);
          setIsLoadingProducts(false);
        }
      }
    },
    [userToken, showAlert]
  );

  const handleConfirmProductsId = (ids: number[]) => {
    if (ids.length === 0 && model?.viewAs === enViewAs.coproducer) return;
    setIsOpenedProductsList(false);
    setModel(prevModel => ({ ...prevModel, productIds: ids }));
  };

  const handleCloseProductsModal = async () => {
    setIsOpenedProductsList(false);
    if (model?.viewAs === enViewAs.coproducer && !model?.productIds.length) {
      setModel(prevModel => ({ ...prevModel, viewAs: enViewAs.producer }));
      await getProducts(model.customerId, enViewAs.producer);
    }
  };

  const handleDatesChange = ({ endDate, startDate }: IDateChangeEvent) => {
    setModel(prevModel => ({
      ...prevModel,
      endDate: endDate && new Date(endDate.format('YYYY-MM-DDT23:59:59.999')),
      startDate: new Date(startDate?.format('YYYY-MM-DDT00:00:00.000'))
    }));
  };

  const handleToggleSavedFilters = (isOpen: boolean) => {
    setIsOpenedSavedFilters(isOpen);
  };

  const handleSaveFilter = async (name: string) => {
    try {
      const { data: savedFilter } = await axios.post<ISavedFilter>(
        '/filters',
        { name, filter: model },
        {
          baseURL: API_ENDPOINT,
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userToken}`
          }
        }
      );
      showAlert('Filtro salvo com sucesso!', enAlertSeverity.success);
      setSavedFilters(prevState => [...prevState, savedFilter]);
      setIsOpenedSaveFilterModal(false);
    } catch (error) {
      if (error.response) {
        showAlert('Você já possui um filtro com este nome!', enAlertSeverity.error);
      } else if (error.request) {
        showAlert('Erro interno. Por favor, tente novamente mais tarde!', enAlertSeverity.error);
      } else {
        console.log('Error', error.message);
      }
      Raven.captureException(error);
      setIsOpenedSavedFilters(false);
    }
  };

  const handleSelectSavedFilter = async (selected: ISavedFilter) => {
    if (!socketIsActive) socketService.activate();
    setModel(selected.filter);
    filterService.setFilter(selected.filter);
    if (isAdmin && selected.filter.customerId) {
      await getProducts(selected.filter.customerId, selected.filter.viewAs);
    }
    setIsOpenedSavedFilters(false);
  };

  const handleToggleSaveFilterModal = (isOpen: boolean) => {
    setIsOpenedSaveFilterModal(isOpen);
  };

  const handleClearFilter = () => {
    setModel(initialFilter);
    filterService.setFilter(initialFilter);
  };

  const handleSubmit = () => {
    if (handleShouldDisableFilterButton()) return;
    if (!socketIsActive) socketService.activate();
    filterService.setFilter(model);
    responsiveSubmit && responsiveSubmit();
  };

  const handleBlurClientIdField = async () => {
    if (!model.customerId) setModel(prevModel => ({ ...prevModel, productIds: [] }));
    if (model.productId || model.productIds.length)
      setModel(prevModel => ({ ...prevModel, productId: null, productIds: [] }));
    setProducts([]);
    setShouldClearSelectedProducts(prevState => !prevState);
    if (model.customerId && model.viewAs === enViewAs.coproducer) setIsOpenedProductsList(true);
    if (model.customerId) await getProducts(model.customerId, model.viewAs);
  };

  const getSavedFilters = useCallback(async () => {
    if (!userToken) return;
    try {
      const { data: filters } = await axios.get<ISavedFilter[]>('/filters', {
        baseURL: API_ENDPOINT,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      });

      const mappedFilters = filters.map(savedFilter => {
        if (savedFilter.filter.rangeTime) {
          savedFilter.filter.startDate = new Date(
            moment()
              .subtract(savedFilter.filter.rangeTime, 'days')
              .format('YYYY-MM-DDT00:00:00.000')
          );
          savedFilter.filter.endDate = new Date(moment().format('YYYY-MM-DDT23:59:59.999'));
          savedFilter.filter.rangeTime = 0;
        }
        if (!savedFilter.filter.productIds?.length) savedFilter.filter.productIds = [];
        return savedFilter;
      });

      setSavedFilters(mappedFilters);
      setIsLoadingFilters(false);
    } catch (error) {
      showAlert('Algo deu errado!!', enAlertSeverity.error);
      Raven.captureException(error);
      setIsLoadingFilters(false);
      console.error(error);
    }
  }, [userToken, showAlert]);

  const handleDeleteSavedFilter = async (filterId: string) => {
    const answer = await Alert.confirm({
      title: 'Deletar filtro',
      message: 'Você tem certeza que deseja deletar o filtro?',
      positiveButtonText: 'Sim',
      negativeButtonText: 'Não',
      confirmOnClickOutside: false
    });

    if (!answer) return;
    setIsLoadingFilters(true);

    try {
      await axios.delete('/filters', {
        data: { id: filterId },
        baseURL: API_ENDPOINT,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      });

      setSavedFilters(prevFilters => prevFilters.filter(filter => filter._id !== filterId));
      setIsLoadingFilters(false);

      showAlert('Filtro deletado com sucesso!', enAlertSeverity.success);
    } catch (error) {
      setIsLoadingFilters(false);
      showAlert('Algo deu errado!!', enAlertSeverity.error);
      Raven.captureException(error);
      console.error(error);
    }
  };

  const handleInputLabel = (): string => {
    if (model?.productIds.length === 0) {
      return 'Todos';
    }

    return `${model?.productIds.length} ${model?.productIds.length > 1 ? 'produtos' : 'produto'} ${
      model?.productIds.length > 1 ? 'selecionados' : 'selecionado'
    }`;
  };

  const handleChangeProductId = (event: ChangeEvent<HTMLInputElement>) => {
    setModel(prevModel => ({
      ...prevModel,
      productIds: !!event.target.value ? [Number(event.target.value)] : [],
      productId: Number(event.target.value) || null
    }));
  };

  const handleDisableSaveFilterButton = () => {
    return (!!model?.startDate && !model?.endDate) || JSON.stringify(model) === JSON.stringify(initialFilter);
  };

  const handleShouldDisableFilterButton = () => {
    return (
      (!!model?.startDate && !model?.endDate) || (model?.viewAs === enViewAs.coproducer && !model?.productIds.length)
    );
  };

  useEffect(() => {
    getSavedFilters();
  }, [getSavedFilters, userToken]);

  useEffect(() => {
    filterService.getFilter().subscribe(filter => setModel(filter));
  }, []);

  useEffect(() => {
    authService.canAccess(enRoleAction.viewUptime).subscribe(admin => setIsAdmin(admin));
  }, []);

  useEffect(() => {
    tokenService.getToken().subscribe(token => setUserToken(token));
  }, []);

  useEffect(() => {
    if (!isAdmin) getProducts(model.customerId, model.viewAs);
  }, [isAdmin, getProducts, model.customerId, model.viewAs]);

  useEffect(() => {
    socketService.socketIsActive().subscribe(isActive => setSocketIsActive(isActive));
  }, []);

  const renderSwitchesContainer = () => (
    <div className={classes.switchesContainer}>
      <div className={classes.switchWrapper}>
        <Switch
          label='Boletos'
          tooltipText='Boletos serão desconsiderados ao desativar essa opção'
          checked={!model?.ignoreBankslip}
          name='ignoreBankslip'
          handleChange={handleChangeCheckbox}
          value='ignoreBankslip'
        />
      </div>
      <div className={classes.switchWrapper}>
        <Switch
          label='Lançamentos'
          tooltipText='Conteúdos marcados como lançamentos são desconsiderados ao desativar essa opção'
          checked={!model?.ignoreLaunches}
          name='ignoreLaunches'
          handleChange={handleChangeCheckbox}
          value='ignoreLaunches'
        />
      </div>
      <div className={classes.switchWrapper}>
        {(!model?.productIds || model?.productIds.length === 0) && (
          <Switch
            label='Gratuitos'
            tooltipText='Conteúdos com tipo de pagamento gratuito são desconsiderados ao desativar essa opção'
            checked={!model?.ignoreFreeContents}
            name='ignoreFreeContents'
            handleChange={handleChangeCheckbox}
            value='ignoreFreeContents'
          />
        )}
      </div>
    </div>
  );

  return (
    <form className={classes.form}>
      <div className={classes.header}>
        <h2 className={classes.title}>Filtros de Pesquisa</h2>
        {width > 599 && renderSwitchesContainer()}
      </div>
      <div className={classes.fieldsWrapper}>
        <FormControl className={classes.field}>
          <InputLabel shrink={true} htmlFor='view-as'>
            Ver métricas como
          </InputLabel>
          <Select
            name='view-as'
            disableUnderline
            value={model?.viewAs || enViewAs.producer}
            onChange={handleChangeViewAs}
          >
            <MenuItem value={enViewAs.producer}>Produtor</MenuItem>
            <MenuItem value={enViewAs.affiliate}>Afiliado</MenuItem>
            <MenuItem value={enViewAs.coproducer}>Coprodutor</MenuItem>
          </Select>
        </FormControl>
        {isAdmin && (
          <FormControl className={classes.field}>
            <InputLabel shrink={true} htmlFor='client-id'>
              Cód. do Cliente
            </InputLabel>
            <input
              name='client-id'
              value={model?.customerId ?? ''}
              onChange={e =>
                setModel(prevModel => ({ ...prevModel, customerId: e.target.value ? Number(e.target.value) : null }))
              }
              className={`${classes.openProducts} ${classes.inputContainer}`}
              type='number'
              onBlur={handleBlurClientIdField}
            />
          </FormControl>
        )}
        <FormControl className={classes.field}>
          <InputLabel shrink={true} htmlFor='products'>
            Produto(s)
          </InputLabel>
          {isAdmin && !model?.customerId ? (
            <input
              name='products-id'
              className={`${classes.openProducts} ${classes.inputContainer}`}
              type='number'
              value={model?.productId || ''}
              onChange={handleChangeProductId}
            />
          ) : (
            <button
              className={`${classes.openProducts} ${classes.inputContainer}`}
              onClick={e => {
                e.preventDefault();
                toggleProductsList(true);
              }}
            >
              {handleInputLabel()}
            </button>
          )}
        </FormControl>
        <List
          open={isOpenedProductsList}
          isLoading={isLoadingProducts}
          products={products || []}
          handleConfirm={handleConfirmProductsId}
          initialSelectedProducts={model?.productIds || []}
          shouldClear={shouldClearSelectedProducts}
          handleClose={handleCloseProductsModal}
          coproducer={model?.viewAs === enViewAs.coproducer}
        />
        <Calendar
          handleDatesChange={handleDatesChange}
          endDate={model?.endDate ? moment(model?.endDate) : null}
          startDate={moment(model?.startDate)}
        />
      </div>
      {width <= 599 && renderSwitchesContainer()}
      <div className={classes.buttonsWrapper}>
        <div className={classes.saveFilters}>
          <Button
            className={`${classes.clearButton} ${classes.button} ${
              classes.saveFilterButton
            } ${handleDisableSaveFilterButton() && classes.buttonDisabled}`}
            startIcon={<Save className={classes.buttonIcon} />}
            onClick={() => handleToggleSaveFilterModal(true)}
            disabled={handleDisableSaveFilterButton()}
          >
            Salvar filtro
          </Button>
          <SaveFilters
            closeDialog={() => handleToggleSaveFilterModal(false)}
            isOpen={isOpenedSaveFilterModal}
            onSaveFilter={handleSaveFilter}
          />
          {savedFilters.length ? (
            <Button
              className={classes.listFiltersButton}
              startIcon={<Star className={classes.buttonIcon} />}
              onClick={() => handleToggleSavedFilters(true)}
            >
              Filtros salvos
            </Button>
          ) : null}
          <ListFilters
            isLoadingFilters={isLoadingFilters}
            isOpen={isOpenedSavedFilters}
            handleDeleteFilter={handleDeleteSavedFilter}
            savedFilters={savedFilters}
            handleClose={() => handleToggleSavedFilters(false)}
            onSelectFilter={handleSelectSavedFilter}
          />
        </div>
        <div className={classes.actions}>
          <Button onClick={handleClearFilter} className={`${classes.clearFilterButton} ${classes.button}`}>
            Limpar
          </Button>
          <Button
            onClick={handleSubmit}
            className={`${classes.button} ${handleShouldDisableFilterButton() ? classes.buttonDisabled : ''}`}
          >
            Filtrar
          </Button>
        </div>
      </div>
      <Alert
        opened={openAlert}
        title={'Ops!'}
        message={`Nós não encontramos nenhum conteúdo para o produtor ${model?.customerId}`}
        onClose={() => setOpenAlert(false)}
      />
    </form>
  );
};

export default Form;
