import { Paper, Table as MuiTable, TableBody, TableContainer, Typography } from '@material-ui/core';
import { enCountries, ITableData } from 'interfaces/regionality';
import React, { useCallback, useState } from 'react';
import Actions from './Actions';
import Row from './Row';
import useStyles from './styles';

interface IProps {
  title: string;
  data: ITableData[];
  countryWithStates?: enCountries[];
  onClickCountry?: (country: enCountries) => void;
}

const Body: React.FC<IProps> = ({ title, data, countryWithStates, onClickCountry }) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(0);
  const [oldPage, setOldPage] = useState<number>(0);

  const rowsPerPage = 15;

  const hasStates = useCallback((countryName: enCountries) => countryWithStates.includes(countryName), [
    countryWithStates
  ]);

  const rows: ITableData[] = data
    .sort((one, another) => Number(one.total) + Number(another.total))
    .map((item, index) => ({
      name: `${index + 1}. ${item.name}`,
      total: `${item.total} ${item.total > 1 ? 'vendas' : 'venda'}`,
      percentage: `${item.percentage}%`
    }))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleClickCountry = useCallback(
    (name: string) => {
      if (hasStates(name as enCountries)) {
        onClickCountry(name as enCountries);
      }
    },
    [onClickCountry, hasStates]
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(prevPage => {
      setOldPage(prevPage);
      return newPage;
    });
  };

  return (
    <>
      <Typography variant='h6' className={classes.tableTitle}>
        {title}
      </Typography>
      <TableContainer component={Paper} elevation={0} className={classes.table}>
        <MuiTable style={{ overflow: 'hidden' }}>
          <TableBody key={page} className={oldPage > page ? classes.next : classes.previous}>
            {rows.map(row => (
              <Row data={row} key={row.name} hasStates={hasStates} arr={rows} onClickCountry={handleClickCountry} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!!data.length && (
        <Actions count={data.length} onPageChange={handleChangePage} page={page} rowsPerPage={rowsPerPage} />
      )}
    </>
  );
};

export default Body;
