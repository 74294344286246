import { Paper } from '@material-ui/core';
import InsufficientData from 'components/Shared/InsufficientData';
import { enCountries, IGeolocationData, ISalesByRegionality, ITableData } from 'interfaces/regionality';
import { enComponent } from 'interfaces/socket';
import Raven from 'raven-js';
import React, { useEffect, useState } from 'react';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import socketService from 'services/socket';

import MapSection from './Map';
import useStyles from './styles';
import TableSection from './Table';

const Regionality: React.FC = () => {
  const classes = useStyles();
  const [salesByRegionality, setSalesByRegionality] = useState<ISalesByRegionality[]>([]);
  const [salesByCountry, setSalesByCountry] = useState<ITableData[]>([]);
  const [salesByStates, setSalesByStates] = useState<IGeolocationData['salesByState']>({});
  const [countryWithStates, setCountryWithStates] = useState<enCountries[]>([]);

  useEffect(() => {
    dashboardService.addComponent(enComponent.regionality);

    return () => {
      dashboardService.removeComponent(enComponent.regionality);
    };
  }, []);

  useEffect(() => {
    socketService
      .listen(enComponent.regionality)
      .pipe(
        rxjsOperators.filter((geolocationData: IGeolocationData) => !!geolocationData),
        rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        rxjsOperators.logError()
      )
      .subscribe(geolocationData => {
        Object.keys(geolocationData.salesByState).map(key => {
          if (key !== 'total') {
            const countryName: enCountries = enCountries[key];
            setCountryWithStates(prevState => {
              if (!prevState.includes(countryName)) {
                return [...prevState, countryName];
              }
              return prevState;
            });
            setSalesByStates(prevState =>
              Object.assign(prevState, { [countryName as string]: geolocationData.salesByState[key] })
            );
          }
        });
        setSalesByCountry(
          geolocationData.salesByCountry.map(country => {
            if (!enCountries[country.countryKey])
              Raven.captureException(new Error(`País ${country.countryKey} não definido no enum enCountries`));
            return {
              name: enCountries[country.countryKey] || country.countryKey,
              total: country.total,
              percentage: country.percentage
            };
          })
        );
        setSalesByRegionality(geolocationData.salesByRegionality);
      });
  }, []);

  return (
    <div className={classes.container}>
      <Paper className={classes.root} elevation={2}>
        <div className={classes.mapContainer}>
          <MapSection sales={salesByRegionality} />
        </div>
        <div className={classes.tableContainer}>
          {!!salesByCountry.length ? (
            <TableSection
              countryWithStates={countryWithStates}
              salesByCountry={salesByCountry}
              salesByStates={salesByStates}
            />
          ) : (
            <InsufficientData />
          )}
        </div>
      </Paper>
    </div>
  );
};

export default Regionality;
