import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { roundNumber } from 'helpers/formatNumber';
import { IRefunds } from 'interfaces/cancellations';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const CancellationRate = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.cancellations);

    return () => {
      dashboardService.removeComponent(enComponent.cancellations);
    };
  }, []);

  const [refunds] = useObservable<{rate: number, variation: number}>(() => {
    return socketService.listen(enComponent.cancellations).pipe(
      rxjsOperators.filter((cancellations: IRefunds) => !!cancellations),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map((refunds: IRefunds) => {
        const variation = roundNumber((refunds.rate - refunds.performanceRate)/refunds.performanceRate * 100, 2);
        return {
          rate: refunds.rate,
          variation
        };
      })
    );
  }, []);

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Qual é a minha taxa de reembolso?',
        value: refunds?.rate,
        dataType: enDataType.percent
      }}
      oldIndicatorDataValue={0}
      description='Percentual de compras que tiveram o processo de reembolso concluído'
    >
      <MetricPerformance performance={isFinite(refunds?.variation) ? refunds?.variation : 0} reverse />
    </IndicatorPanel>
  );
};

export default CancellationRate;
