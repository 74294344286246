import EduzzAppsToolbar from '@eduzz/apps-toolbar';

const toolbar = EduzzAppsToolbar({
  application: 'telescope',
  show: true
});

export async function showToolbar(user: any) {
  (await toolbar).setUser({
    id: user.id,
    email: user.email,
    name: user.name,
    isClubeBlack: user.isClubeBlack
  });
}

export async function hideToolbar() {
  (await toolbar).hide();
}
