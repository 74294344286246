import React from 'react';
import { AlertTitle } from '@material-ui/lab';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ErrorRounded from '@material-ui/icons/ErrorRounded';

const useStyles = makeStyles(() => ({
  alert: {
    maxWidth: '100%',
    backgroundColor: '#CEEBFB',
    padding: '12px 14px',
    color: '#000',
    display: 'flex',
    gap: '12px',
    borderRadius: '4px',
    margin: '12px 24px',

    '& > svg': {
      fill: '#0699E5',
      transform: 'rotate(180deg) translateY(2px)'
    },

    '@media screen and (max-width: 960px)': {
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  link: {
    color: '#0699E5'
  },
  text: {
    margin: 0
  }
}));

const AnalyticsMigrationInfo: React.FC = () => {
  const classes = useStyles();

  return (
    <Slide direction='down' in>
      <div className={classes.alert}>
        <ErrorRounded />
        <div className={classes.bodyWrapper}>
          <AlertTitle style={{ color: '#000', fontSize: '16px' }}>
            <strong>Em breve, os dados apresentados aqui no Telescope estarão disponíveis no Analytics!</strong>
          </AlertTitle>
          <p className={classes.text}>
            Dentro de 15 dias, as métricas de conversão que você já conhecia através do Telescope serão desativadas,
            passando a estarem disponíveis na opção Analytics, dentro do próprio MyEduzz. <br /> Saiba mais acessando a
            nossa{' '}
            <a className={classes.link} target='blank' href='https://ajuda.eduzz.com/hc/pt-br/articles/4402736834075'>
              FAQ
            </a>
            .
          </p>
        </div>
      </div>
    </Slide>
  );
};

export default AnalyticsMigrationInfo;
