import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { theme } from 'assets/theme';

type Props = {
  size: number;
  color?: string;
};

const Spinner = ({ size, color }: Props) => {
  return (
    <CircularProgress
      style={{
        alignSelf: 'center',
        overflow: 'hidden !important',
        color: color || theme.variables.colors['primaryPurple']
      }}
      size={size}
    />
  );
};

export default Spinner;
