import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
  recommendation?: React.ReactNode;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 20,
      '& svg': {
        width: '100%',
        margin: '0 0 16px 0'
      }
    }
  })
);

const InsufficientData: React.FC<IProps> = ({ recommendation: insight }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SvgIcon name='insufficientData' />
      {!insight && (
        <Typography variant='body2' align='center'>
          Os dados desta seção são insuficientes para gerarmos seus insights
        </Typography>
      )}

      {insight ? insight : null}
    </div>
  );
};

export default InsufficientData;
