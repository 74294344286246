import { makeStyles, Theme } from '@material-ui/core';

const useCardListStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: 'calc(100% - 60px)'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 0',
    '& svg': {
      height: 20,
      marginRight: 8,
      fill: theme.variables.colors['text']
    }
  },
  actionButton: {
    width: 60,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: '1px solid #ECEFF1',
    '& svg': {
      height: 24
    }
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  currency: {
    color: theme.variables.colors['positive']
  }
}));

export default useCardListStyles;
