import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2)
  },
  title: {
    color: theme.variables.colors['primaryPurple']
  },
  funnelLabel: {
    color: theme.variables.colors['primaryPurple'],
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem'
    }
  },
  rangeTime: {
    color: theme.variables.colors['text']
  },
  funnel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    width: '100%',
    maxWidth: 700
  },
  trapezoid: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
    '&#funnel-attempts': {
      width: '85%'
    },
    '&#funnel-sales': {
      width: '70%'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1)
    }
  },
  corner: {
    width: 100,
    backgroundColor: theme.variables.colors['primaryPurple'],
    '&#left': {
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      transform: 'skew(25deg) translateX(50%)'
    },
    '&#right': {
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      transform: 'skew(-25deg) translateX(-50%)'
    }
  },
  center: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 300,
    minHeight: 90,
    backgroundColor: theme.variables.colors['primaryPurple'],
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'calc(100% - 100px)',
      minHeight: 55
    }
  },
  trapezoidLabel: {
    fontSize: '2rem',
    fontWeight: 600,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  }
}));

export default useStyles;
