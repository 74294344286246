import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';
import TooltipDescription from '../TooltipDescription';

interface ITitleCardSimple {
  id?: string;
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  help?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing()
  },

  header: {
    width: '100%'
  },

  subtitle: {
    color: theme.variables.colors['rangeTime'],
    fontSize: '0.75rem'
  },

  help: {
    paddingLeft: 8
  }
}));

const TitleCardSimple: React.FC<ITitleCardSimple> = memo(({ title, help, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant='subtitle1'>{title}</Typography>

        {subtitle && (
          <Typography variant='body2' className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
      </div>

      {help && (
        <div className={classes.help}>
          <TooltipDescription text={help} />
        </div>
      )}
    </div>
  );
});

export default TitleCardSimple;
