import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';

export type ILegend = {
  prefix?: string;
  label: string;
  value: string | number;
  type?: 'percentage' | 'number';
};

interface IChartLegend {
  data: ILegend[];
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },

  legend: ({ colors }: { colors: number }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0',

    '& .value': {
      fontSize: '1.5rem',
      fontWeight: 600,
      paddingLeft: 12,

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem'
      },

      ...new Array(colors).fill(' ').reduce((acc, _, index) => {
        const color = index + 1;

        return {
          ...acc,
          [`&.--color-${color}`]: {
            color: theme.variables.colors[`bar${color}`]
          }
        };
      }, {})
    }
  })
}));

const ChartLegend: React.FC<IChartLegend> = memo(({ data }) => {
  const classes = useStyles({ colors: data.length || 8 });

  return (
    <div className={classes.root}>
      {data.map((legend, index) => (
        <div className={classes.legend} key={`legend-${index}`}>
          <div className='label'>
            <Typography variant='body1'>
              {legend.prefix && (
                <>
                  <b>{legend.prefix}</b> -
                </>
              )}{' '}
              {legend.label}
            </Typography>
          </div>

          <div className={`value --color-${index + 1}`}>
            {legend.value}
            {legend.type === 'percentage' && '%'}
          </div>
        </div>
      ))}
    </div>
  );
});

export default ChartLegend;
