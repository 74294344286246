import { ShowcaseStep } from 'components/Shared/Showcase';
import React from 'react';

export const SHOWCASE_STEPS: ShowcaseStep[] = [
  {
    description:
      'O Telescope está de cara nova, com design acessivel, intuitivo e  novas funcionalidades para facilitar o seu dia a dia. Confira agora mesmo!',
    image: {
      src: '/images/tour-start.jpg',
      alt: 'O Telescope está de cara nova'
    }
  },
  {
    description: (
      <>
        <strong>Faturamento:</strong> Agora você pode ver seu faturamento bruto
        e faturamento com Order Bump, ambos com percentual de queda e
        crescimento.
      </>
    ),
    image: {
      src: '/images/tour-faturamento.png',
      alt: 'Faturamento'
    }
  },
  {
    description: (
      <>
        <strong>Parcelamento sem limites:</strong> Agora ficou muito mais fácil de você saber quanto há para receber
        de parcelamento sem limites!
      </>
    ),
    image: {
      src: '/images/tour-psl.png',
      alt: 'Parcelamento sem limites'
    }
  },
  {
    description: (
      <>
        <strong>Vendas abertas:</strong> Nessa versão também é possível ver
        o valor total em vendas abertas.
      </>
    ),
    image: {
      src: '/images/tour-vendas-abertas.png',
      alt: 'Vendas abertas'
    }
  },
  {
    description: (
      <>
        <strong>Conversão de boletos:</strong> Já imaginou saber qual sua
        conversão de boleto de forma simplificada? Agora você pode!
      </>
    ),
    image: {
      src: '/images/tour-conversao-boletos.png',
      alt: 'Conversão de boletos'
    }
  },
  {
    description: (
      <>
        <strong>Reembolsos:</strong> Saber qual sua taxa de reembolsos
        também é importante, né? Por isso, também preparamos essa informação
        pra você!
      </>
    ),
    image: {
      src: '/images/tour-reembolsos.png',
      alt: 'Reembolsos'
    }
  },
  {
    description: (
      <>
        <strong>Recusas de cartão:</strong> Agora é possível saber quais os
        principais motivos de recusa em compras com cartão de crédito.
      </>
    ),
    image: {
      src: '/images/tour-recusas-cartao.png',
      alt: 'Recusas de cartão'
    }
  },
  {
    description: (
      <>
        <strong>Formas de pagamento (Pix):</strong> Agora ficou ainda mais
        fácil saber qual seu percentual de vendas por Pix.
      </>
    ),
    image: {
      src: '/images/tour-formas-pagto-pix.png',
      alt: 'Formas de pagamento (Pix)'
    }
  },
  {
    description: (
      <>
        <strong>Transações:</strong> Para saber mais detalhes de uma transação, agora é só seleciona-lá e uma modal com
        detalhes será mostrado.
      </>
    ),
    image: {
      src: '/images/tour-transactions.jpg',
      alt: 'Transações e detalhes de transação'
    }
  },
  {
    description:
      'Que legal! Agora que você já está por dentro de todas as novidades, é só aproveitar!',
    image: {
      src: '/images/tour-start.jpg',
      alt: 'O Telescope está de cara nova'
    }
  }
];
