import { Theme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      marginLeft: 8,
      '& svg': {
        fill: theme.variables.colors['tooltipIconFill'],
        width: 20
      }
    }
  })
);

const TooltipDescription: React.FC<IProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Tooltip placement='bottom-end' title={text} enterTouchDelay={100}>
      <span className={classes.icon}>
        <SvgIcon name='tooltip' />
      </span>
    </Tooltip>
  );
};

export default TooltipDescription;
