import { Paper, Tooltip, Typography } from '@material-ui/core';
import { SvgIcon } from 'components/Shared/SvgIcon';
import React, { useCallback } from 'react';
import formatData from '../../../helpers/formatData';
import useCardListStyles from './styles';

export interface ICardListProps {
  cards: ICard[];
  onClick?: (props: any) => void;
  actionButton?: {
    icon: string;
    onClick: (data: any) => void;
  };
}

export interface ICard {
  rows: ICardListRow[];
  paid: boolean;
  actionButtonProps?: any;
  onClickProps?: any;
}

export interface ICardListRow {
  title?: string;
  icon?: string;
  iconTooltip?: string;
  value: string | number;
  type?: 'numeric' | 'percentage' | 'currency' | 'text';
}

const CardList: React.FC<ICardListProps> = ({ cards, actionButton, onClick }) => {
  const classes = useCardListStyles();

  const handleActionButtonClick = useCallback(
    (props: any) => () => {
      actionButton.onClick(props);
    },
    [actionButton]
  );

  const handleClick = useCallback(
    row => () => {
      if (!onClick) {
        return;
      }

      onClick(row);
    },
    [onClick]
  );

  if (!cards) {
    return null;
  }

  return (
    <>
      {cards.map((card, x) => (
        <Paper elevation={2} className={classes.container} key={x}>
          <div className={classes.content}>
            {card.rows.map((row, i) => (
              <div className={classes.row} key={i} onClick={handleClick(card.onClickProps)}>
                {row.icon && (
                  <Tooltip title={row?.iconTooltip} placement='top'>
                    <span>
                      <SvgIcon name={row.icon} />
                    </span>
                  </Tooltip>
                )}

                <Typography
                  className={`${classes.text} ${classes.row} ${row?.type === 'currency' ? classes.currency : ''}`}
                  variant='body2'
                >
                  {formatData(row.value, row?.type)}
                  { row?.type === 'currency' && card?.paid && <SvgIcon name='tick' /> }
                </Typography>
              </div>
            ))}
          </div>

          {actionButton && (
            <div className={classes.actionButton} onClick={handleActionButtonClick(card.actionButtonProps)}>
              <SvgIcon name={actionButton.icon} />
            </div>
          )}
        </Paper>
      ))}
    </>
  );
};

export default CardList;
