import React from 'react';

const linkStyle = { textDecoration: 'none', color: '#999' };

const Footer: React.FC = () => (
  <div style={{ textAlign: 'center' }}>
    <a style={linkStyle} rel='noopener noreferrer' target='_blank' href='https://www.eduzz.com/politica-de-privacidade'>
      Política de privacidade
    </a>
    <span style={{ color: '#CCC', display: 'inline-block', margin: '0 5px' }}>&bull;</span>
    <a style={linkStyle} rel='noopener noreferrer' target='_blank' href='https://www.eduzz.com/termos-e-condicoes'>
      Termos e condições
    </a>
  </div>
);

export default Footer;
