import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%'
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      height: '100%',
      [theme.breakpoints.up('xs')]: {
        minHeight: 215
      }
    },
    tooltipContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    title: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      '@media screen and (max-width: 1280px)': {
        fontSize: '14px'
      }
    },
    valueGrid: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column'
    },
    value: {
      fontSize: '2rem',
      display: 'flex',
      fontWeight: 600,
      flexDirection: 'column',
      textAlign: 'left',
      '@media screen and (max-width: 1595px)': {
        maxWidth: 200
      },
      '@media screen and (max-width: 600px)': {
        fontSize: '1.5rem'
      }
    },
    icon: {
      position: 'absolute',
      height: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      overflow: 'hidden',
      maxWidth: '70%',
      right: 0,
      bottom: 0,
      marginBottom: 10,
      '& svg': {
        marginRight: -5,
        width: '55%',
        position: 'relative',
        top: 5,
        '@media screen and (max-width: 1595px)': {
          width: '55%'
        }
      }
    },
    indicators: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      height: 'calc(100% - 50px)',
      padding: '12px 0 0'
    },
    indicatorsWithChildren: {
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100% - 50px)'
      }
    },
    iconGrid: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    rangeTime: {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      color: theme.variables.colors['rangeTime'],
      marginTop: 12
    },
    currency: {
      fontSize: '1.5rem',
      color: theme.variables.colors['lightText']
    }
  })
);

export default useStyles;
