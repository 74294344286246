import { enViewAs } from 'interfaces/filter';
import { enCountries, IGeolocationData, ITableData } from 'interfaces/regionality';
import { enRoleAction } from 'interfaces/userToken';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import authService from 'services/auth';
import filterService from 'services/filter';
import tokenService from 'services/token';
import Body from './Body';
import Header from './Header';

export type TFilter = {
  from?: string;
  to?: string;
  viewAs: enViewAs;
  productIds?: number[];
};
export type TCustomerData = {
  token: string;
  id: number;
};
interface IProps {
  salesByCountry: ITableData[];
  salesByStates: IGeolocationData['salesByState'];
  countryWithStates: enCountries[];
}

const TableSection: React.FC<IProps> = ({ salesByCountry, countryWithStates, salesByStates }) => {
  const [selectedCountry, setSelectedCountry] = useState<enCountries | null>(null);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [customerData, setCustomerData] = useState<TCustomerData>({
    token: null,
    id: null
  });
  const [filter, setFilter] = useState<TFilter>({
    from: moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    viewAs: enViewAs.producer
  });

  useEffect(() => {
    authService.canAccess(enRoleAction.viewUptime).subscribe(isAdmin => setIsAdmin(isAdmin));
  }, []);

  useEffect(() => {
    filterService.getFilter().subscribe(filter => {
      const { rangeTime, startDate, endDate, customerId, viewAs, productIds } = filter;

      if (isAdmin) {
        setCustomerData(prevState => ({ ...prevState, id: customerId }));
      }

      if (rangeTime) {
        setFilter({
          from: moment()
            .subtract(rangeTime, 'days')
            .format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
          viewAs: enViewAs[viewAs],
          productIds
        });

        return;
      }

      if (startDate && endDate) {
        setFilter({
          from: moment(startDate).format('YYYY-MM-DD'),
          to: moment(endDate).format('YYYY-MM-DD'),
          viewAs: enViewAs[viewAs],
          productIds
        });
      }
    });
  }, [isAdmin]);

  useEffect(() => {
    tokenService.getToken().subscribe(token => {
      setCustomerData({
        id: !isAdmin && tokenService.decode(token).id,
        token
      });
    });
  }, [isAdmin]);

  const handleClickCountry = useCallback((country: enCountries) => {
    setSelectedCountry(country);
  }, []);

  const handleClickGoBack = () => {
    setSelectedCountry(null);
  };

  return (
    <>
      <Header
        customer={customerData}
        filter={filter}
        isAdmin={isAdmin}
        onClickGoBack={handleClickGoBack}
        hasSelectedCountry={!!selectedCountry}
      />
      {!!selectedCountry ? (
        <Fragment key={selectedCountry}>
          <Body data={salesByStates[selectedCountry]} countryWithStates={countryWithStates} title='Estados' />
        </Fragment>
      ) : (
        <Body
          countryWithStates={countryWithStates}
          onClickCountry={handleClickCountry}
          data={salesByCountry}
          title='Países'
        />
      )}
    </>
  );
};

export default TableSection;
