import { Grid, makeStyles, Paper } from '@material-ui/core';
import BlockedData from 'components/Shared/BlockedData';
import IndicatorPanel from 'components/Shared/IndicatorPanel';
import MetricPerformance from 'components/Shared/MetricPerformance';
import { enDataType } from 'interfaces/dashboard';
import { enViewAs, IFilterModel } from 'interfaces/filter';
import { IBilling } from 'interfaces/grossBilling';
import { enComponent } from 'interfaces/socket';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import filterService from 'services/filter';
import { socketService } from 'services/socket';

const useStyles = makeStyles(() => ({
  blockedData: {
    height: '100%'
  }
}));

const OrderBumpBilling = () => {
  const classes = useStyles();
  const [previousOrderBumpBilling, setPreviousOrderBumpBilling] = useState(0);

  useEffect(() => {
    dashboardService.addComponent(enComponent.orderBumpBilling);

    return () => {
      dashboardService.removeComponent(enComponent.orderBumpBilling);
    };
  }, []);

  const [viewAsAffiliate] = useObservable<boolean>(() => {
    return filterService.getFilter().pipe(
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map((filter: IFilterModel) => filter.viewAs === enViewAs.affiliate)
    );
  }, []);

  const [orderBumpBilling] = useObservable<IBilling>(() => {
    return socketService.listen(enComponent.orderBumpBilling).pipe(
      rxjsOperators.filter((billing: IBilling) => !!billing),
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map(obb => {
        const orderBumpBilling: IBilling = {
          billing: 0,
          performance: 0
        };

        setPreviousOrderBumpBilling(orderBumpBilling.billing);

        orderBumpBilling.billing = obb.billing;
        orderBumpBilling.performance = obb.performance;

        return orderBumpBilling;
      })
    );
  }, []);

  if (viewAsAffiliate) {
    return (
      <Paper className={classes.blockedData} elevation={2}>
        <Grid item xs={12}>
          <BlockedData />
        </Grid>
      </Paper>
    );
  }

  return (
    <IndicatorPanel
      indicatorData={{
        title: 'Qual é o meu faturamento de Order Bump?',
        value: orderBumpBilling?.billing,
        dataType: enDataType.currency
      }}
      oldIndicatorDataValue={previousOrderBumpBilling}
      description='Valor total bruto de faturamento de Order Bump, sem contabilizar descontos de taxas e afins'
    >
      <MetricPerformance performance={orderBumpBilling?.performance || 0} />
    </IndicatorPanel>
  );
};

export default OrderBumpBilling;
