import IndicatorPanel from 'components/Shared/IndicatorPanel';
import { enDataType } from 'interfaces/dashboard';
import { enComponent } from 'interfaces/socket';
import React, { useEffect } from 'react';
import { useObservable } from 'react-use-observable';
import rxjsOperators from 'rxjs-operators';
import dashboardService from 'services/dashboard';
import { socketService } from 'services/socket';

const ChargebackRate = () => {
  useEffect(() => {
    dashboardService.addComponent(enComponent.chargebackRates);
    return () => {
      dashboardService.removeComponent(enComponent.chargebackRates);
    };
  }, []);

  const [chargebackRate] = useObservable<number|null>(() => {
    return socketService.listen(enComponent.chargebackRates).pipe(
      rxjsOperators.distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      rxjsOperators.map((rate: number) => rate)
    );
  }, []);

  return (
    <IndicatorPanel
      hideTime={true}
      indicatorData={{
        title: 'Qual é o meu percentual de chargeback atual?',
        value: chargebackRate,
        dataType: enDataType.percent
      }}
      oldIndicatorDataValue={0}
      description='Percentual do cancelamento de compras através da operadora'
    >
    </IndicatorPanel>
  );
};

export default ChargebackRate;
