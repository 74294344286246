/* eslint-disable camelcase */
export enum HotjarEvents {
  TRANSFER_REQUEST_MADE = 'transfer_request_made'
}

export type TAnswer = {
  answered: boolean;
  answered_at?: string;
  days_count?: number;
};
