export enum enCountries {
  AD = 'Andorra',
  AE = 'Emirados Árabes Unidos',
  AF = 'Afeganistão',
  AG = 'Antígua e Barbuda',
  AI = 'Anguila',
  AL = 'Albânia',
  AM = 'Armênia',
  AO = 'Angola',
  AQ = 'Antártica',
  AR = 'Argentina',
  AS = 'Samoa Americana',
  AT = 'Austria',
  AU = 'Austrália',
  AW = 'Aruba',
  AX = 'Ilhas Åland',
  AZ = 'Azerbaijão',
  BA = 'Bósnia e Hezergovina',
  BB = 'Barbados',
  BD = 'Bangladesh',
  BE = 'Bélgica',
  BF = 'Burquina Fasso',
  BG = 'Bulgária',
  BH = 'Barein',
  BI = 'Burúndi',
  BJ = 'Benin',
  BL = 'São Bartolomeu',
  BM = 'Bermuda',
  BN = 'Brunei',
  BO = 'Bolívia',
  BQ = 'Caribe Neerlandês',
  BR = 'Brasil',
  BS = 'Bahamas',
  BT = 'Butão',
  BV = 'Ilha Bouvet',
  BW = 'Botsuana',
  BY = 'Bielorrússia',
  BZ = 'Belize',
  CA = 'Canadá',
  CH = 'Suíça',
  CL = 'Chile',
  CN = 'China',
  CM = 'Camarões',
  CO = 'Colombia',
  CR = 'Costa Rica',
  CU = 'Cuba',
  CV = 'Cabo Verde',
  CY = 'Chipre',
  CZ = 'República Checa',
  DE = 'Alemanha',
  DO = 'República Dominicana',
  DK = 'Dinamarca',
  DZ = 'Argélia',
  EC = 'Equador',
  EE = 'Estônia',
  EG = 'Egito',
  ES = 'Espanha',
  FI = 'Finlândia',
  FJ = 'Fiji',
  FR = 'França',
  GB = 'Reino Unido',
  GE = 'Geórgia',
  GF = 'Guiana Francesa',
  GH = 'Gana',
  GI = 'Gibraltar',
  GP = 'Guadeloupe',
  GR = 'Grécia',
  GT = 'Guatemala',
  GW = 'Guiné-Bissau',
  GY = 'Guiana',
  HK = 'Hong Kong',
  HR = 'Croácia',
  HN = 'Honduras',
  HT = 'Haiti',
  HU = 'Hungária',
  ID = 'Indonésia',
  IE = 'Irlanda',
  IL = 'Israel',
  IN = 'Índia',
  IS = 'Islândia',
  IT = 'Itália',
  JE = 'Jersey',
  JM = 'Jamaica',
  JO = 'Jordânia',
  JP = 'Japão',
  KH = 'Camboja',
  KR = 'Coréia do Sul',
  KW = 'Kuwait',
  KY = 'Ilhas Cayman',
  LB = 'Líbano',
  LT = 'Lituânia',
  LU = 'Luxemburgo',
  LV = 'Latvia',
  MA = 'Malta',
  ME = 'Montenegro',
  MO = 'Macau',
  MQ = 'Martinique',
  MT = 'Malta',
  MX = 'México',
  MY = 'Malásia',
  MZ = 'Moçambique',
  NG = 'Nigéria',
  NI = 'Nicarágua',
  NL = 'Holanda',
  NO = 'Noruega',
  NZ = 'Nova Zelândia',
  OM = 'Omã',
  PA = 'Panamá',
  PE = 'Peru',
  PF = 'Polinésia Francesa',
  PH = 'Filipinas',
  PL = 'Polônia',
  PS = 'Palestina',
  PT = 'Portugal',
  PY = 'Paraguai',
  QA = 'Catar',
  RO = 'Romênia',
  RS = 'Sérvia',
  RU = 'Rússia',
  SA = 'Arábia Saudita',
  SE = 'Suécia',
  SG = 'Singapura',
  SI = 'Eslovénia',
  SK = 'Eslováquia',
  SN = 'Senegal',
  SR = 'Suriname',
  ST = 'São Tomé e Príncipe',
  SV = 'Salvador',
  TH = 'Tailândia',
  TR = 'Turquia',
  TW = 'Taiwan',
  UA = 'Ucrânia',
  unknown = 'Outros',
  US = 'Estados Unidos',
  UY = 'Uruguai',
  VE = 'Venezuela',
  VN = 'Vietnã',
  ZA = 'África do Sul',
  ZM = 'Zâmbia',
  ZW = 'Zimbabue'
}

export interface ISalesByRegionality {
  coordinates: number[];
  total: number;
}
export interface ITableData {
  name: string;
  total: number | string;
  percentage: number | string;
}

export interface ISalesByCountry {
  countryKey: string;
  total: number;
  percentage: number;
}

export interface IGeolocationData {
  salesByRegionality: ISalesByRegionality[];
  salesByCountry: ISalesByCountry[];
  salesByState: Record<string, ITableData[]>;
}
