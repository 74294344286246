import { Theme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { SvgIcon } from 'components/Shared/SvgIcon';
import React from 'react';

interface IProps {
  classes?: any;
  hasTabs?: boolean;
  action: {
    icon: string;
    tooltip?: string;
    onClick: () => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 40,
      right: 20,
      zIndex: 1100,
      '@media screen and (max-width: 360px)': {
        bottom: 20
      }
    },
    withTabs: {
      top: 75
    },
    fab: {
      background: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: '#FABB0A'
      },
      '@media screen and (max-width: 480px)': {
        width: 46,
        height: 46
      }
    },
    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: 25,
        fill: '#FFF'
      }
    }
  })
);

const FabButton: React.FC<IProps> = ({ action, hasTabs }) => {
  const classes = useStyles();

  if (action) {
    return (
      <div className={classes.root + ' header-app ' + (hasTabs ? classes.withTabs : '')}>
        {action.tooltip && (
          <Tooltip title={action.tooltip}>
            <Fab onClick={action.onClick} className={classes.fab}>
              <span className={classes.icon}>
                <SvgIcon name={action.icon} />
              </span>
            </Fab>
          </Tooltip>
        )}

        {!action.tooltip && (
          <Fab onClick={action.onClick} className={classes.fab}>
            <span className={classes.icon}>
              <SvgIcon name={action.icon} />
            </span>
          </Fab>
        )}
      </div>
    );
  }

  return null;
};

export default FabButton;
